/* Also edit EditRow css */

.detailRow {
    display: flex;
    margin-bottom: 1.5rem;
    white-space: pre-wrap;
}

.underline {
    border-bottom: 1px solid var(--light-border-color);
}

.detailRow label {
    margin: 0 2rem 0 0;
    max-width: 15%;
    flex-grow: 1;
}

/* Match the margins and styling used used in edit rows - for mixing row types
    (note using that stylesheet directly doesn't work as expected) */
.editDetailRow {
    display: flex;
    margin-bottom: 1.5rem;
    white-space: pre-wrap;
    max-width: 800px;
}

.editDetailRow label {
    margin: 0 2rem 0 0;
    max-width: 31.25%;
    flex-grow: 1;
}

/* Detail row assumes full width, when creating e.g. summary page in v8 with small fixed width the label/value
    ratio looks bad, this enforces a standard for summaries/fixed widths */
.fixedWidth {
    width: 800px;
    max-width: 100%;
}

.fixedWidth label {
    max-width: 200px !important;
    min-width: 200px !important;
}

.fixedWidth span {
    display: inline-block; 
    max-width: 400px;
    word-wrap: break-word; 
    overflow-wrap: break-word; 
    white-space: normal;
}

.wideLabel label {
    max-width: 300px !important;
    min-width: 300px !important;
}

/* Fill width with smaller screen sizes */
@media screen and (max-width: 700px) {
    .detailRow {
        flex-direction: column;
    }
    .detailRow label {
        max-width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .labelTop {
        flex-direction: column !important;
    }
    
    .labelTop label {
        max-width: 100% !important;
        min-width: auto !important;
        margin-bottom: 10px !important;
    }
}

