@keyframes delayoverflow {
    from {overflow: hidden;}
    to {overflow: visible;}
}

.accountViewContainer {
    background: #FFF;

    .filtersHolder {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        & > * {
            margin-right: 10px !important; // important because it's important :)
        }

        .searchFilter {
            width: 300px;
        }
    }

    h3 {
        display: inline-block;
        font-weight: 600;
        color: var(--ink300);
    }
}

.reviewTransactionsSection {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    align-items: center;
}

.accountTitle {
    margin-top: 40px;
    margin-bottom: 20px;
}

.filtersContainer {
    max-height: 0px;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    background: var(--light-background-color);
    padding: 0px 10px;
    // border-radius: 5px;
    border: 0px solid var(--light-border-color);

    &.show {
        max-height: 300px;
        animation: delayoverflow 0.25s ease-in-out;
        overflow: visible;
        // box-shadow: 0 0.15rem 0.15rem rgba(10,10,10,0.15);
        padding: 10px;
        border: 1px solid var(--light-border-color);
        margin-bottom: 15px;
    }

    .filtersGroup {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-bottom: 10px;

        .filterCheckboxList {

            min-width: 200px;
            max-width: 400px;
            width: 30%;
            margin-right: 1%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .topLabel {
                width: 31.25%;
                line-height:30px;
                text-align: left;
            }

            .cbLabel {
                cursor: pointer;
                input {
                    margin-right: 10px;
                }
            }
        }

        .filter {
            min-width: 200px;
            max-width: 400px;
            width: 30%;
            margin-right: 1%;

            label {
                margin: 0 2rem 0 0;
                width: 31.25%;
                line-height:40px;
                text-align: left;
            }            
        }
    }
}

.matchRow {
    background-color: var(--unmatched-row-color) !important;

    &:hover td {
        background-color: var(--unmatched-row-color-hover) !important;
    }
}

.splitTransactionTopRow {
    background-color: var(--split-transaction-border) !important;
    color: var(--split-transaction-color);

    &:hover td {
        background-color: var(--split-transaction-border) !important;
    }
}

.splitTotalTopRowCell {
    text-align: right;
    font-style: italic;
}

.splitRow {
    td.leftBorderInset {
        position: relative;
        div {
            border-left: 3px solid var(--split-transaction-border);
            height: calc(100% + 2px);
            width: 3px;
            position: absolute;
            top: -1px;
            left: 0;
        }
    }

    td.rightBorderInset {
        position: relative;
        div {
            border-right: 3px solid var(--split-transaction-border);
            height: calc(100% + 2px);
            width: 3px;
            position: absolute;
            top: -1px;
            right: 0;
        }
    }
}

.lastSplitRow {
    background-color: var(--split-transaction-border) !important;

    td {
        padding: 1.5px !important;

        &:hover {
            background-color: var(--split-transaction-border) !important;
        }
    }
}

.satrSplitTransactionTopRow > td:hover {
    cursor: default;
}

td.satrSplitTransactionTopTd {
    padding-top: 0.75em !important;
    padding-bottom: 0.75em !important;
    text-align: left;
}

td.icons {
    text-align: center;
    border-left: 1px solid var(--grey400);
    cursor: pointer !important;
}

.lastSplitRow > td.satrLastSplitRowTd {
    padding: 1.5px !important;
}

.linkText{
    color: var(--ink200);
    text-decoration-line: underline;
    cursor: pointer;
}

.consentExpiry{
    padding-left: 20px;
    padding-right: 20px;
}


.adminOnly::after {
    content: "";
    background-color: var(--admin-only-color);
    border-radius: 50%;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 10px;
}


.SATRMultiItemTransaction{
    margin-left: 12px;
}

.deleteTransactionIcon:hover{
    svg {
        color: var(--danger-icon-color);
    }
}

.repeatPaymentIcon {
    color: var(--light-grey-icon-color);
    cursor: pointer;
}

.repeatPaymentIcon:hover{
    svg {
        color: var(--grey-icon-color);
    }
}
