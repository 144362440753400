.icon-small {
	width: 16px;
	height: 16px;
}

.icon-medium {
	width: 24px;
	height: 24px;
}

.icon-large {
	width: 32px;
	height: 32px;
}
