.dropdownBtn {
    display: inline-block;
    width: auto;

    button {
        display: inline-block;
        text-align: center;
        line-height: 1;
        cursor: pointer;
        -webkit-appearance: none;
        transition: all 0.25s ease-out;
        transition: visibility 0s;
        vertical-align: middle;
        margin: 0.25rem 0.25rem 0.25rem 0.25rem;
        font-size:  0.95rem;
        // font-weight: 200;
        letter-spacing: 0.3px;
        color:white;
        margin-right: 7px;
        margin-left: 0px;     
    }

    svg {
        margin-right: 0.5em;
    }

    /* Fallback value is bad but modal portals don't have access to our custom vars */
    /* Needs further investigation - DD */
    /* Not ideal, but this can by surrounding the modal content w/ a brand wrapper - JW  */
    &.change button   {   background-color: var(--button-change-color); }
    &.positive button {   background-color: var(--button-primary); }
    &.primary button  {   background-color: var(--button-primary);}
    &.secondary button  {   background-color: var(--button-secondary); color:black}
    &.success button  {   background-color: var(--button-primary);}
    &.danger button  {   background-color: var(--button-danger);}
    &.warning button  {   background-color: var(--button-secondary); color:black}
    &.info button  {   background-color: var(--button-secondary);}
    &.dark button  {   background-color: var(--button-secondary);}
    &.light button  {   background-color: var(--button-other); color: black}
    &.recordPayment button { background-color: var(--button-primary); }
    &.paymentInactive button { background-color: var(--button-secondary); color: #fff !important; }

    &.adminOnly button, &.adminOnly:hover button, &.adminOnly:focus button, &.adminOnly:active button, &.adminOnly:focus-within button {
        border-right: 4px solid var(--admin-only-color);
    }
    

}