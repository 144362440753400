span.tag {
    margin: 0;
    /* padding: 0.25rem 0.5rem; Undecided what looks better*/
    padding: 0.25rem 0.5rem;
    align-items: center;
    border-radius: 0.125rem;
}

span.tag.small {
    font-size: 0.875rem;
}

span > svg {
    margin-left: 0.15rem;
}