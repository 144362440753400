#queries .empty {
    color: var(--grey-no-content-text-color);
    margin-bottom: 20px;
}
#queriesToolbar {
    max-width: 1192px;
}

#queries .noteIcon {
    font-size: 24px;
    color: var(--grey-icon-color);
    margin-right: 20px;
}

.SelectFilter {
	margin-right: 5px;
	outline-color: none;
	width: 200px;
}

.SelectFilter :global(.SelectFilter__control) {
	border-color: var(--slate-blue-border-color);
	padding: 1.5px;
	cursor: pointer;
}

.SelectFilter :global(.SelectFilter__menu) {
	max-width: 220px;
	border: 1px solid var(--entity-border-color) !important;
}

@media screen and (max-width: 768px) {
	.SelectFilter {		
		margin: 2px 0;
	}
	@media screen and (min-width: 768px) and (max-width: 900px) {
		.SelectFilter {
			min-width: 140px;
			padding-right: 4px !important;
			margin: 0px;
		}
	}
	.SelectFilter :global(.SelectFilter__control) {
		border-color: var(--slate-blue-border-color);
		padding: 1.5px;
	}
}
.SelectFilter :global(.SelectFilter__control--is-focused) {
	border-color: var(--sidebar-selected-background-color) !important;
}


