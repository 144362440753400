.parentItem a:focus, .parentItem a:active , .childItem a:focus,.childItem a:active{
    /* background-color: var(--sidebar-items-active-color) !important; */
    border-color:none!important;
    color: #000;
    font-weight: 800;
}
.adminOnly{
    border-right: 2px solid red !important;
}
.childItem a{
    padding: 0.3rem 1rem !important;
    font-size: 13px !important;
    white-space: nowrap;
    border:none !important;
    /* border-bottom: 0.2px solid rgb(230, 229, 229) !important; */
}
.documentSidebar{
    /* background-color: var(--sidebar-bg-color); */
    background-color: var(--grey100);
    min-width: 230px;;
    border-radius: var(--input-field-radius) var(--input-field-radius) 0 0;
}
.sidebarWrapper{
    min-height: calc(100vh - 135px);
    background-color: var(--grey100);
    margin-top:1rem;
    border-radius: var(--input-field-radius) var(--input-field-radius) 0 0;
    border-radius: var(--v8-entity-border-radius)
}
.childItem a:hover , .parentItem a:hover{
    background-color: rgb(213, 216, 219) !important;
    /* border-bottom: 0.2px solid rgb(230, 229, 229) !important; */
}


.childItem a.active{
    color: #000!important;
}

.parentItemClickable{
    /* background-color: var(--sidebar-parent-bg-color) !important; */
    /* border-top: 0.2px solid rgb(230, 229, 229) !important; */
    /* border-bottom: 0.2px solid rgb(230, 229, 229) !important; */
    background-color: var(--grey100) !important;
    font-weight: 400;
}

.parentItemNonClickable{
    pointer-events: none;
    font-weight: 400;
    /* border-top: 0.2px solid rgb(230, 229, 229) !important; */
    /* border-bottom: 0.2px solid rgb(230, 229, 229) !important; */
    background-color: var(--grey200) !important;

}
.activeLink {
    /* background-color: var(--sidebar-items-active-color) !important; */
    border-color:none!important;
    color: #000;
    font-weight: 800;
}