.selectList {
  width: 150px;
  margin: 0px 10px;
}

#form p {
  line-height: 40px;
}

#uploader {
  width: 100%;
  box-shadow: none;
  margin-top: 15px;
}
