
.titleoption {
    font-weight:bold;
}

.printOnlyTableHeader, .printFooter {
    display: none;
}

.datePickerContainer > * {
    display: inline-block !important;
}

.datePickerCustom {
    display: block;
}
.controlHolder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
}

.controlHolder > * {
    margin-bottom: 10px;
}

.controlHolderV8 > * {
    margin-bottom: 30px;
}

.saveBtnBtm {
    margin-bottom: .5rem;
}

.cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cardContainer > * {
    margin: 10px;
}

.cardContainer > *:nth-child(3n-2) {
    margin-left: 0px
}

.reportContainer {
    height: 100%;
}

.reportContainer p {
    font-size: 13.3px;
    color: var(--topbar-background-color);
    font-style: italic;
}

.reportContainer > .reportSideBar {
    border-right: 1px solid #000;
    padding-right: 14px;
}

.tabHeading {
    padding: 7px 14px;
    border-bottom: 1px solid var(--light-border-color);
    margin-bottom: 0px;
}

.backIcon {
    width: 100% !important;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    justify-content: flex-end;
    display: flex;
    padding-right: 10px;
}

.tabsSideList {
    flex-grow: 0;
    flex-shrink: 0;
    width: 200px;
    background-color: var(--light-background-color);
    border-right: 1px solid var(--light-border-color);
}

.btnMargin {
    margin-bottom: 5px;
}

.reportCard {
    cursor: pointer;
}

.reportCard:hover div {
    text-decoration: underline;
}

/* .datePickerCustom:first-child {
    margin-right: 10px;
    
} */
.drilldownTitleHover {
    padding: 10px;
    font-size: 1.9rem;
}

.drilldownTitleHover:hover {
    cursor: pointer;
    background: var(--steel200);
    border-radius: var(--entity-border-radius);
}

.headingLvl3 {
    font-size: 14px !important;
}

.finalTotalContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pageNumberContainer {
    /* align-items: flex-end; */
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
}

.drilldownMainSectionTotal {
    padding: 10px;
    font-size: 1.2rem;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    margin-bottom: 30px;
}

.drilldownTableInset {
    margin-left: 25px;
}

.floatRight { float: right; }

.invoice {
    border-radius: var(--entity-border-radius);
    box-shadow: var(--entity-box-shadow);
    background: #FFF;
    margin-bottom: 20px;
    padding: 20px 20px;
}

.invoice:hover {
    background-color: #eee;
    cursor: pointer;
}

.endOfDDSection {
    padding: 10px;
}

.iconMoveHover {
    cursor: pointer;
    margin-left: 95%;
}

.iconMoveHover svg {
    margin-left: 1px;
    transition: all 0.2s ease-in-out;
}

.iconMoveHover:hover svg {
    margin-left: 2px;
}

.heading2 {
    background: var(--steel200);
    color: var(--black);
    font-size: 1.2rem !important;
    border-radius: var(--entity-border-radius);
    padding-bottom: 0.5em;
    padding: 10px;
}
.heading2:hover {
    background: var(--steel300);
}

.level2margin {
    margin-bottom: 40px;
}

/* Reduces the padding and margin to condense Drilldown into smaller area (more info on the screen) */
/* Hori padding/marign remains the same */
.headingCondense {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}

.filterBtn {
    height: 41px;
    margin-bottom: 10px;
    margin-top: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
}

.filterBtn > svg {
    margin-right: 0;
}

.tableCategoryHeader tr:first-of-type {
    background-color: rgb(225, 225, 225);
    color: var(--table-header);
}

.tableCategoryHeader tr:last-of-type > th {
    font-weight: 600;
}

.filterWrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

@media only print
{

    @page { 
        size: auto; 
        margin: 0mm;
    }

    .printWrapper table {
        border: 0px;
        display: block;
    }

    .printWrapper th, .printWrapper td {
        color: black !important;
        border: 0px !important;
    }

    .printWrapper th > span {
        text-decoration: underline 2px;
    }

    .printWrapper td {
        padding: 0px 10px !important;
    }

    .noprint {
        display: none !important;
    }

    .printOnlyTableHeader {
        display: table-row;
    }
    
    .tablespace td {
        height: 100px;
    }

    .printFooter {
        display: table-footer-group;
        position: fixed;
        bottom: 10px;
        left: 30px;
    }

    .printFooter:after {
        counter-increment: page;
    }
    
    .printOnlyTableHeader > * {
        border: 0px !important;
    }

    .paddingPrint {
        padding-bottom: 100px;
    }

    @page {
        @bottom-right {
          content: counter(page) " of " counter(pages);
        }
    }

}