@import "Dashboard.module.scss";

.reminderCard {
  border-radius: var(--v8-entity-border-radius) !important;
  border-width: 1px !important;
  background-color: var(--steel50) !important;
}

.overdue {
  border-color: var(--grey200) !important;
}

.duesoon {
  border-color: var(--grey200) !important;
}

.upcoming {
  border-color: var(--grey200) !important;
}

.referral {
  border-radius: var(--v8-entity-border-radius) !important;
  background-color: var(--grey200) !important;
}

.referral h4::before {
  all: unset
}

.referralBoxProv {
  border-radius: var(--v8-entity-border-radius) !important;
}