
.blankStateCard {
	min-height: 23em;
	overflow: hidden;
	border-radius: 8px;
	border-color: var(--steel200);
	margin: 0;
	max-width: 1220px;
}

.cardBody, .cardBody:global(.card-body) {
	padding: 0;
	column-gap: 1.563em;
	h2 {
		margin-bottom: 10px;
		font-weight: 700;
		line-height: normal;
		font-style: normal;
	}
	p,
	small {
		color: var(--ink200);
	}

	p{
		font-size: 1rem;
	}

	small {
		display: block;
		font-size: 0.8rem;
	}
}

.cardBodyContent {
    padding: 6em 2.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    height: 100%;
}

.cardImage {
    width: 50%;
    height: auto;
	align-self: stretch;
    background-size: cover;
    background-position: center center;

	img {
		object-fit: cover;
	}
}

.button {
	margin: 2em 0;
}


.loading  {
	animation: glow 1.5s ease-in-out infinite;
	background: #eee;
	color: transparent;
	cursor: progress;
	align-self: stretch;
	height: auto;
	width: 50%;
}

.loadingButton{
    width: 30%;
    height: 2rem;
    border-radius: 1rem;
	animation: glow 1.5s ease-in-out infinite;
    background: #eee;
    color: transparent;
    cursor: progress; 
}

.warningMessage {
	margin-top: 30px;
	background-color: var(--light-background-color);
	border-radius: 5px;
	padding: 10px;
}

.warningMessage > div {
	margin-bottom: 5px;
}

.warningMessage > p {
	color: black !important; 
}

.warningMessage > a {
	display: inline-block;
	margin-top: 5px;
}

.cardImageMobile {
	align-self: center;
	width: 100%;
}

.cardBodyContentMobile {
	padding: 2.5rem;
}