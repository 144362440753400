.blockButton {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.blockButtonMarginBottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.searchResult {
    border: 1px solid var(--entity-border-color);
    padding: 10px
}

.searchResult:hover {
    background: var(--entity-border-color);
    cursor: pointer;
}

.searchResult:last-child {
    border-radius: 0px 0px 5px 5px;
}

.searchResult p {
    margin-bottom: 0px;
}

.dateTxt {
    color: var(--grey-no-content-text-color)
}

.rcpModal {
    display: flex;    
}

.rcpModal .lhs {
    width: 40%;
}

.rcpModal .rhs {
    width: 60%;
    padding: 0 20px 20px 20px; 
    text-align: left;
}

.rcpImgCard {
    margin-bottom: 30px;
}

.deleteBtn {
    display: none;
}

@media only screen and (max-width: 768px) {
    .rcpModal {
        flex-direction: column;
    }

    .rcpModal .lhs {
        width: 100%;
        max-height: 200px;
        margin-bottom: 20px;
    }

    .rcpModal .rhs {
        width: 100%;        
    }

    /* .rcpImgCard:last-child {
        margin-bottom: 100px
    } */
    .rcpImgContainer {
        margin-bottom: 30px;
    }

    .deleteBtn {
        position: absolute;
        background: var(--danger);
        display: block;
        top: 10px;
        right: 10px;
        padding: 5px;
        color: white;
        cursor: pointer;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--entity-border-radius);
    }

}

.altText{
    display: block;
    align-content: center;
    height: 100%;
}