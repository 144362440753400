.summaryEntry {
    background-color: #fff;
    display: flex;
    column-gap: 32px;
    align-items: stretch;
    margin-bottom: 16px;
    font-size: 1.15rem;
}

.summaryEntryInner {
    padding: 20px 24px;
    display: flex;
    column-gap: 32px;
    align-items: center;
    flex-grow: 1;
}

.categories {
    font-size: 0.8rem;
    color: var(--ink300);
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        text-transform: uppercase;
        font-size: 0.85rem;
        font-weight: 600;
    }
    b {
        font-size: 2.35rem;
        line-height: 2.5rem;
    }
    
}

.summaryData small {
    font-size: 1rem;
}

.summaryData small:first-of-type {
    color: var(--ink100)
}

.summaryData small:last-of-type {
    color: var(--ink300)
}

//To ensure equal button heights we're doing some clever trickery by using a 1px gap with a background color to simulate a separating border
.actionButtons {
    display: flex;
    flex-direction: column;
    background-color: var(--steel100);
    transition: 0.25s ease-out;
    gap: 1px;
}

.actionButtons > span {
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    box-sizing: border-box;
    transition: 0.25s ease-out;
    flex-grow: 1;
    border: 1px solid #fff;
    border-left-color: var(--steel100);
}

.actionButtons > span > svg {
    margin-left: 0;
}

//Border trickery
.actionButtons:hover {
    cursor: pointer;
    background-color: var(--light-border-color);
    border-color: var(--light-border-color);
}

.actionButtons > span:hover {
    border-color: var(--light-border-color);
    background-color: #FCFCFC;
}

.actionButtons > span:not(:only-child):first-of-type {
    border-bottom: none;
}

.actionButtons > span:not(:only-child):last-of-type {
    border-top: none;
}