#defaultLayout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    color: var(--black);
}
#emptyLayout {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

#header {
    padding: 0 14px;
    border-bottom: 1px solid var(--light-border-color);
    min-height: 53px;

    h1 {
        font-weight: normal;
        font-size: 25px;
        margin: 14px 0;
        line-height: 1;
    }

    &Mobile {
        @extend #header;
        h1 {
            font-size: 20px;
            margin: 16px 0;
        }
    }
}

#headerNoTitle{
    min-height: none;
}

#toolbar {
    padding: 14px 0;
}

#containerWrapper {
    flex-grow: 1;
    display: flex;
    position: relative;
}

#containerWrapper.greyBackground  {
    background-color: var(--light-background-color);
}

#container {
    padding:14px;
    margin-left:0;    
    flex-grow: 1;
}
#container.noPadding {
    padding:0;
}

#container.useFullWidth {
    max-width: 100%;
}

#container.useMobileFooter {
    padding-bottom: 120px;
}

// #containerWrapper h2 {
//     font-size:18px;
//     font-weight: normal;
//     line-height: 1;
//     border-bottom: 1px solid var(--entity-border-color);
//     padding-bottom: 0.4em;
//     margin-bottom: 1em;
// }

#backIcon {
    color: #444;
    cursor: pointer;
    margin-right: 15px;
}

