.stepperContainer {
    /* Size variable used by children below... */
    --size: 3rem;
}

.stepperContainer.ia {
    --stepcolor: var(--topbar-background-color)
}

.stepperContainer.pv {
    --stepcolor: var(--account-color-5)
}

.stepperWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* Rmv the default padding from the start... */
    padding-inline-start: 0;
    /* Size + 50% of margin */
    height: calc(var(--size) + 3.5rem);
    align-items: flex-end;
}

.stepperWrapper li {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: calc(var(--size)/2)
}

.stepperWrapper li:before {
    content: "";
    display: block;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border: 4px solid var(--step-inactive-color);
    margin: 0 auto 0;
}

/* Invisible but need to get last-child p at the same height */
.stepperWrapper li:last-child:after {
    content: "";
    position: relative;
    top: calc(var(--size) / 2);
    width: 0;
    height: 4px;
    order: -1;
}

.stepperWrapper li:not(:last-child):after {
    content: "";
    position: relative;
    /* size / 2 (radius) + 2px (half height of line) */
    top: calc((var(--size) / 2) + 2px);
    /* full width - diameter of circle + lhs offset */
    width: calc(100% - var(--size) - 0.2rem);
    /* half + radius + lhs offset */
    left: calc(50% + calc(var(--size) / 2 + 0.3rem));
    height: 4px;
    background-color: var(--step-inactive-color);
    order: -1;
}

.stepperWrapper li .desc {
    margin-top: -7rem;
    align-self: center;
    width: 70%;
    height: var(--size);
    color: var(--step-inactive-color);
    text-align: center;
    display: flex;
    align-content: flex-end;
    flex-direction: column;
    justify-content: flex-end;
}

.complete p {
    color: var(--step-active-color);
}

.stepperWrapper li .stepperContent {
    margin-top: 0.6rem;
}

.stepperWrapper li .stepperContent > * {
    padding: 0px;
    margin: 0px;
}

.stepperWrapperVertical {
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.stepperWrapperVertical li {
    position: relative;
    display: flex;
    gap: 1rem;
    padding-bottom: 2rem;
    flex: 1 1 0px;
}

.stepperWrapperVertical li:before {
    content: "";
    flex: 0 0 var(--size);
    height: var(--size);
    border-radius: 50%;
    border: 4px solid var(--step-inactive-color);
    width: var(--size);
}

.stepperWrapperVertical li:not(:last-child):after {
    content: "";
    position: absolute;
    left: 0;
    top: var(--size);
    bottom: var(--size);
    transform: translateX(calc(calc(var(--size)/2) - 2px));
    right: 2px;
    width: 4px;
    background-color: var(--step-inactive-color);
    height: calc(100% - var(--size));
}

.stepperWrapperVertical li .desc {
    justify-self: middle;
    color: var(--step-inactive-color);
    display: flex;
    margin-top: 0.75rem;
}

.stepperWrapperVertical .active .desc,
.stepperWrapper .active .desc {
    color: var(--step-active-color);
    font-weight: bold;
}

.stepperWrapperVertical .complete .desc,
.stepperWrapper .complete .desc {
    color: var(--step-active-color);
}

.stepperWrapperVertical li > :last-child {
    position: absolute;
    width: calc(var(--size)/2);
    height: calc(var(--size)/2);
    display: flex;
    align-items: center;
    justify-content: center;
    left: calc(calc(var(--size)/2) - 10px);
    top: calc(calc(var(--size)/2) - 13px);
}

.stepperWrapper li.complete:before,
.stepperWrapperVertical li.complete:before {
    background-color: var(--step-active-color);
    border: 4px solid var(--step-active-color);
}

.stepperWrapper li.complete:not(:last-child):after,
.stepperWrapperVertical li.complete:not(:last-child):after {
    background-color: var(--step-active-color);
}

.stepperWrapper li.active:before,
.stepperWrapperVertical li.active:before {
    border: 4px solid var(--step-active-color);
}

.stepperContainerVertical {
    display: flex;
    justify-content: center;
    width: 100%;
}

.stepperWrapper li.disabled:before,
.stepperWrapperVertical li.disabled:before {
    border: 4px solid var(--grey-no-content-text-color);
}

.stepperWrapperVertical .disabled .desc,
.stepperWrapper .disabled .desc {
    color: var(--grey-no-content-text-color);
}

.stepperWrapper li.disabled:before,
.stepperWrapperVertical li.disabled:before  {
    background-color: transparent;
}

.stepperWrapper li.hideCircle:before,
.stepperWrapperVertical li.hideCircle:before {
    border: 4px solid transparent;
    background: transparent;
}