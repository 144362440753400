#consultationRecord h5.reportHeading {
  font-weight: 600;;
  margin-bottom: 10px;
  text-decoration: underline;
}

#consultationRecord div.reportText {
  margin-bottom: 20px;
  white-space: pre-wrap;
}

#consultationRecord div.recommendation {
  padding: 20px;
  margin-top: 15px;
  border-radius: 5px;
  background-color: #F2F2F2;
}

#consultationRecord h5.expandingHeading {
  font-weight: 600;;
  display: inline-block;
}

#consultationRecord span {
  cursor: pointer;
}

#consultationRecord .header {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px; 
}

#consultationRecord .expanderIcon {
  position: relative;
  top: 1px;
  font-size: 20px;
  display: inline-block;
  margin-right: 10px;
}

#consultationRecord div.whatElseBox {
  padding-left: 29px;  /* aligns with expander heading + icon */
  margin-top: 10px;
}

#consultationRecord img.circle {
  width: 100px;
  border-radius: 50%;
  margin: 20px 0 10px 0;
}

#consultationRecord span.circle {
  height: 100px;
  width: 100px;
  background-color: #DDD;
  border-radius: 50%;
  display: inline-block;
  margin: 20px 0 10px 0;
}

.multiLineText p {
  margin-bottom: 0;
}

.subCard {
  margin-top: 15px;
  padding: 5px;
}

.subCardContent {
  display: inline-block;
  width: 730px;
}

.googleIcon {
  width: 80px;
  height: 80px;
  margin-top: 10px;
}

.provestorIcon {
  width: 55px;
  height: 55px;
  margin-top: 10px;
}
