@import "InfoBanner.module.scss";

.infoBanner {
  border-radius: var(--v8-entity-border-radius);
  padding: 20px;
  color: var(--black);
}

.info {
  // border: 1px solid var(--grey300);
  border: none;
  color: var(--black);
  background: var(--grey100);
}

.warning {
  border-left: 11px solid var(--ui-warning);
  // border: none;
  color: var(--black);
  background: var(--grey100);
}

.danger {
  // border: 2px solid var(--danger);
  border-left: 11px solid var(--ui-negative);
  color: var(--black);
  background: var(--grey100);
}

.positive {
  // border: 2px solid var(--success-green-color);
  border-left: 11px solid var(--success-green-color);
  color: var(--black);
  background: var(--grey100);
}

.whiteBg {
  background-color: (#fff);
}