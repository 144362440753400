#companySelect {
    position: fixed;
    z-index: 10000;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background-color: #eee;
}

#inner {
    width: 300px;
    margin: 200px auto 0 auto;
}

.logo {
    display: block;
    max-width:200px;
    margin: 0 auto 50px auto;
}

.errorMessage {
  text-align: center;
  color:#a00;
  line-height: 1.1em;
  font-size: 16px;
  margin-top:20px;
}

@keyframes glow {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  

.loading span {
    animation: glow 1.5s ease-in-out infinite;
    background: #eee;
    color: transparent;
    cursor: progress;
    display: inline-block;
}
 

.loading .line {
    animation: glow 1.5s ease-in-out infinite;
    background: #eee;
    color: transparent;
    cursor: progress;
    display: inline-block;
    height:16px;
    margin: 0;
    width:100%;
}

.logout {
  display: block;
  margin-top: 2em;
  text-align: center;
  font-style: 10px;
}