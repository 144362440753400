
.invoiceDropdownButton > button {
    width: 100%;
    text-align: left;
    background: transparent;
    color: #212529;
    border: 0px;
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    border-radius: 0px;
}

.invoiceDropdownButton > button:hover {
    color: #16181b;
}
.invoiceDropdownNoItem a{
    padding:0 1.5rem !important;
}

.invoiceDropdownParentButton > button {
    text-align: left;
    color: #fefefe;
    margin:4px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.invoiceDropdownParentButton > div{
        display: table!important;
}
.invoiceDropdownButton{
    border-radius: var(--entity-border-radius) !important;
}

.invoiceDropdownButton button {
    padding: 10px;
}
.invoiceDropdownParentButton {
    position: inherit!important;
}