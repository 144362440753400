#basicFormLayout {
    display: flex;
    flex-direction: column;
    padding: 35px 35px 14px 35px;
    background: var(--steel100);
    min-height: 100vh;
}

#basicFormLayout h1 {
    font-weight: 600;
}

#basicFormLayout a {
    cursor: pointer;
    color: var(--muted-text);
    text-decoration: underline;
    font-weight: 600;
}

.simpleTable {
    background: white;
    border: 1px solid var(--grey200);
    margin-top: 20px;
    margin-bottom: 40px !important;

    thead {
        color: var(--muted-text);
        font-weight: 600px;
    }

    tbody {
        tr:nth-child(even) {
            background-color: var(--steel50);
        }

        tr:hover {
            cursor: pointer;
            background-color: var(--steel150);
        }
    }

    td {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
}

.positiveValue {
    // color: var(--feature-green);
    font-weight: 700;
}

.margin{
    margin: 30px 0 !important;
}

.generalInfo{
    border-radius: 0px;
    height: 150px !important;
}

.allDone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
}

.allDone img {
    width: 80px;
    margin-bottom: 30px;
}

.allDone p {
    font-size: 24px;
}

.boldLabel {
    font-weight: 500;
}