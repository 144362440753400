
/* Filter */

table.filter {
    width:100%;
    border-spacing: 0;
    border: 1px solid #ddd;
    box-shadow: 1px 1px 3px rgb(50 50 50 / 10%);
    border-radius: 3px;
    border-bottom: none;
    margin-bottom: 0;
}


table.filter td {
    user-select: none;
    background-color: #fff;
    text-align: center;
    color: #999;
    font-weight: 400;
    text-transform: uppercase;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    line-height: 1em;
    height: 3em;
}

table.filter td.selected {
    color: var(--filter-selected);
    border-bottom: 2px solid #2a4969;
    padding-top: 1px;
}

table.filter td.empty {
    color: #ddd;
}

table.filter td.warning {
    color: var(--warning);
}


table.filter select {
    margin-bottom: 0;
    text-align: center;
    border: none;
    font-size: 15px;
    font-weight: 400;
    color: var(--filter-selected);
}

table.filter select:focus {
    outline: 0;
}

table.filter .yearDropDown {
    padding:0;
    display: inline;
    margin:0 1em;
    text-transform: none;
}

table.filter .yearDropDownButton {
    background: transparent !important;
    border:none !important;
    padding:0;
    color: var(--filter-selected) !important;
    display: inline;
}

.dropdownItem {
    line-height: 1.5em;
}

.yearSelectorArrow {
    color: var(--filter-selected);
    cursor: pointer;
    display: inline-block;
    position: relative;
    top: 2px;
}

.yearSelectorArrow.empty {
    color: #ddd;
    cursor: auto;
}

.suggestionContainer {
    position: absolute;
    background: white;
    width: 300px;
    border: 1px solid var(--light-border-color);
    z-index: 999;
}

.suggestionContainer div {
    padding: 5px;
    border-bottom: 1px solid var(--light-border-color);
}

.suggestionContainer div:hover {
    background: var(--light-border-color);   
}

.expandedRowTop {
    border-bottom: 0px !important;
    background: white !important;
}

.expandedRowBtm {
    border-top: 0px !important;
    background: white !important;
}