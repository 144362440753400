//Help bar
.helpBar {
    position: fixed;
    bottom: -233px;
    left: 0;
    width: 100%;
    background-color: var(--topbar-background-color);
    padding: 0.5rem;
    padding-bottom: 0;
    color: rgb(10,10,10);
    height: 233px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.helpBarOpen {
    bottom: 0;
}

//Lifted from _help.scss
.helpBarButton {
    height: 80.5px;
    background-color: var(--salmon-dark);
    padding: 19.25px 1rem;
    text-align: center;
    line-height: 21px;
    color: white;
    width: 250px;
    display: block;
    margin: 1rem 0.5rem;
}

.helpBarButton2 {
    background-color: var(--yellow-dark);
}
.helpBarButton3 {
    background-color: var(--teal-dark);
}
.helpBarButton4 {
    background-color: var(--green-dark);
}

.helpBarButton:hover {
    color: white;
    text-decoration: none;
}

.helpButtonGroup {
    display: inline-block;
}

.helpButtonGroup:first-of-type {
    margin-left: 0.5rem;
}

.helpTabs {
    width: 223px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--topbar-background-color);
    color: #fff;
    font-size: 1.1rem;
}

.helpTab:hover {
    background-color: var(--topbar-background-color);
}

.helpTab.selected {
    background-color: var(--sidebar-background-color);
    border-bottom: none;
}

.helpTabContent {
    padding: 0;
    background-color: var(--sidebar-background-color);
}

.helpSearch {
    margin: 1rem 0.5rem;
}

.closeHelp:hover {
    cursor: pointer;
}