.alert {
    position: fixed !important;
    right: 10%;
    left: 10%;
    bottom: 0px;
    width: 80%;

    svg {
        margin-right: 10px;
    }

    &.success {
        background: var(--alert-success-bg);
        border: 1px solid var(--success-green-color);
        color:  var(--success-green-color);
    }

    &.warning {
        border: 1px solid var(--warning-border-color);
        background: var(--warning-background-color);
        color: var(--warning-text-color);
    }

    &.info {
        border: 1px solid var(--alert-info-border);
        background: var(--alert-info-bg);
        color: var(--alert-info-text);
    }
}

.inline {
    position: relative !important;
    left: 0;
    right: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}
