.cardWrapper {
    margin-bottom: 21px;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid var(--entity-border-color);
    border-radius: var(--entity-border-radius);
    box-shadow: var(--entity-box-shadow);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardWrapper.height-1 {
    height:115px;
}
.cardWrapper.height-2 {
    height:231px;
}
.cardWrapper.height-3 {
    height:357px;
}
.cardWrapper.height-4 {
    height:120px;
}

.cardWrapper .label {
    color: #1c535a;
    line-height: 1;
    padding-bottom: 5px;
    margin-bottom: 8px;
    flex-grow:0;
    flex-shrink: 0;
    text-align: center;
}

.cardWrapper .controls {
    flex-grow:0;
    flex-shrink: 0;
    height:24px;
}

.cardWrapper .value {
    flex-grow:1;
    flex-shrink: 1;
    align-items: center;
}

.cardWrapperGraph {
    align-items: normal !important;
}

.cardWrapper .helpText {
    font-size: 12px;
    line-height: 1;
    text-align: right;
    color: #aaa;
    padding-top: 5px;
    height: 1em;
    flex-grow:0;
    flex-shrink: 0;
    text-align: center;
}

.simpleKpiValue, .pieChartTotal  {
    font-size: 30px;
    font-weight: 600;
    text-align: right;
    line-height: 1;
    color: var(--black);    
    height: 1em;
}


.pieChartTip {
    padding: 5px;
    background-color: white;
    font-size:14px;
    font-weight: normal;
    text-align: left;
    border: 1px solid var(--entity-border-color);
    border-radius: var(--entity-border-radius);
    box-shadow: var(--entity-box-shadow);     
}

.smallKpiValues{
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4rem;
    text-align: center;
}
 