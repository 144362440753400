#PropertyCard {
  color: var(--ink300);
  background-color: var(--steel50);
  border-radius: var(--v8-card-radius);
  margin-bottom: 30px;
}

#PropertyCard img {
    aspect-ratio: 1 / 1;
    object-fit: cover;
    height: 250px;
    border-radius: var(--v8-card-radius) var(--v8-card-radius) 0 0;
    border-bottom: 1px solid var(--disabled-placeholder-color);
}

#PropertyCard h3 {
    font-weight: 600;
}

#PropertyCard h5 {
    display: inline-block;
    font-weight: 600;
}

#PropertyCard div {
    margin: 4px 0;
}

#PropertyCard .value {
    margin-top: 24px;
    margin-bottom: 14px;
}

#PropertyCard .viewButton {
    margin-top: 12px;
    width: 100%;
}
