.inputStyle label{
	margin: 0 2rem 0 0;
	line-height:40px;
	text-align: left;
	width: 100%;
	white-space: nowrap;
}
.inputStyle input, .inputStyle textarea {
	padding: .57rem;
	height: 40.91px;
	font-size: medium;
}

.dangerLine{
	border: 1.2px solid red !important;
}

.inputStyle .suffix {
	padding: .57rem;
	height: 40.91px;
	font-size: medium;
}
.errorLine input {
	border-color:var(--danger);
}

/* Chrome, Safari, Edge, Opera */
.hideArrow > input::-webkit-outer-spin-button,
.hideArrow > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hideArrow > input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
  -webkit-appearance: textfield;
}


.formikSelectStyle :global(.formikSelectStyle__control) {
	max-width: 100%;
	border-color: var(--slate-blue-border-color);
	height: calc(1rem + 1.28rem + 2px);
	min-height:40.91px;
	cursor: pointer;
	margin: 0;
}

.formikSelectStyle :global(.formikSelectStyle__control--is-focused) {
   border-color:var(--entity-border-color)!important;
}

.formikSelectStyle :global(.formikSelectStyle__control--is-disabled) {
    background-color: var(--white-hover); 
}

@media screen and (max-width: 768px) {
	.formikSelectStyle :global(.formikSelectStyle__control) {
		max-width: 100%;
	}
}

.formikSelectStyle :global(.formikSelectStyle__input) input {
	width: 100%!important;
	margin: -0.71rem;
	padding: .57rem;
}
.formikSelectStyle :global(.formikSelectStyle__menu) {
	max-width: 15rem;
    border: 1px solid var(--entity-border-color)!important;
}
.formikSelectStyle  :global(.formikSelectStyle__placeholder) {
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.inputDateStyle {
	max-width: 100%;
}
@media screen and (max-width: 768px) {
   .inputDateStyle {
      max-width: 100%;
   }
}
@media screen and (max-width: 768px) {
	.formikSelectStyle :global(.formikSelectStyle__menu) {
		max-width: 100%;
	}
}
@media screen and (max-width: 768px) {
	.inputDateStyle {
		width: 100%;
	}
}

@media screen and (max-width: 1024px) {
	.wrapLabel{
		white-space: normal !important;
		line-height: 20px !important;
		margin-bottom: 10px !important;
	}
}

.small {
	border-radius: 0px;
}
.inputCheckboxStyle input{
	height: auto ;
}
.inputCheckboxStyle input{
	height: auto ;
}

.inputCheckboxStyle input:disabled {
	opacity: 0.5;
}
.formikSelectStyle :global(.formikSelectStyle__menu){
	max-width:100% !important;
	text-align: start;
}

.errorMessageNumberField{
	display: block;
	width: 100%;
	margin-top: 3px;
}

.helpMessage{
	margin-bottom: 20px;
}

.boldText {
	font-weight: 500;
}