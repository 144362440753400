.bankAccountCard, .bankAccountCard:global(.card) {
    background-color: white;
    width: 100%;
    border-color: #CACED2;

    small {
        margin-bottom: 8px;
        color: #585F67;
        color: black;
    }

    p, ul, b {
        color: #353F4A;
    }
    border-radius: 17px;
}

.cardContent {
    padding: 28px 17px;
}


.imageWrapper {
    margin-right: 16px;
    border-radius: 50%;
    width: 5.5em;
    height: 5.5em;
    overflow: hidden;
    border: 2px solid #DEE2E7;
    padding: 10px;
    img {
        object-fit: cover;
        object-position: center;
    }
}

.cardHeader, .cardHeader:global(.card-header) {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 14px;
    border-bottom: none;
   
    p, h6 {
        margin-bottom: 0;
        color: #000;
    }

}

.cardBody,.cardBody:global(.card-body) {
    background-color: white;
    padding: 14px 0;
    display: flex;
    column-gap: 0.5em;
    border-bottom: 1px solid #CACED2;
    border-top: 1px solid #CACED2;

    div {
        flex: 1;
        flex-basis: max-content;
        padding: 8px 14px;
        display: flex;
        flex-direction: column;
        background-color: #F2F4F6;
        border-radius: 7px;
    }

}

.cardFooter, .cardFooter:global(.card-footer) {
    background-color: white;
    border-top: none;
    display: flex;
    padding: 14px 0 0;
    column-gap: 2.5em;
    ul {
        padding: 8px 14px;
        flex: 1;
        margin-bottom: 0;
        li {
            margin-left: 25px;
        }
    }
}

.recommended{
    border-radius: 0px 0px 7px 7px !important;

}
