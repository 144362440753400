@import "DashboardCards.module.scss";

.cardWrapper {
    background-color: var(--steel100) !important;
    border-radius: var(--v8-entity-border-radius) !important;
    border: none;
    box-shadow: none;
}

.cardWrapperV75LightBg {
  border: 1px solid var(--grey200) !important;
  background-color: var(--steel50) !important;
}

.cardWrapper .label {
    color: var(--black);
    margin-bottom: 10px;
}

.cardWrapper .controls {
    flex-grow:0;
    flex-shrink: 0;
    height:24px;
}

.cardWrapper .helpText {
    color: #777;
}