

#mobileWrapper {
    display: flex;
    flex-direction: column;
    height:100%;
    /* Hide scroll bar so there isn't 2, see notes below */
    overflow: hidden; 
}

/* Kind of hacky but we set max-height to 100vh and allow scroll, when side menu open we allow scroll */
/* If we just set max-height on open we get taken back up to the top :) */
.contentSidebarClosed {
    max-height: 100vh;
    overflow-y: auto !important;
}

.contentSidebarOpen {
    overflow-y:hidden;
}

#alertBar {
    flex-grow: 0;
    flex-shrink: 0;
}

.error {
    display: flex;
    align-items: center;
    background-color: #a00;
    color: #fff;
    padding: 0 1rem;
    font-size: 12px;
    height: 25px;
    position: fixed;
    width:100%;
    z-index: 3000;
}

.error a {
    color: #fff;
    text-decoration: underline;
}
.error a:hover {
    color: #fff;
    text-decoration: underline;
}

#topBar {
    flex-grow: 0;
    flex-shrink: 0;
    z-index: 1000;
    position: sticky;
    align-self: flex-start;
    top: 0;
    width: 100%;
    z-index: 1001;
}

#topBar.hasError {
    margin-top: 0px;
}

#contentWrapper {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
}

#sideBar {
    flex-grow: 0;
    flex-shrink: 0;
    background-color: var(--sidebar-background-color);
    transition: width .3s ease;
    position: fixed;
    top: 46px;
    bottom: 0;
    z-index: 1001;
    overflow-y:scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &Mobile {
        @extend #sideBar;
        background-color: white;
    }
}

/* hide scroll bar Chrome, Safari, Opera */
#sideBar::-webkit-scrollbar {
    display: none;
}

#content {
    flex-grow: 1;
    flex-shrink: 1;
    overflow:hidden;    /* to enable responsive tables, overflow in elements etc */
}

.contentOverlay {
    /* filter: blur(1px) brightness(50%); */
    background: rgba(0,0,0,0.7);
    transition: opacity 0.3s ease-in-out;
    /* No content should have high z-index that this... */
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;    
}

.contentOverlayActive {
    visibility: visible;
    opacity: 1;
}

.mobileWrapperAlert {
    position: fixed;
    left: 5%;
    bottom: 10px;
    width: 90%;
    margin: auto;
    z-index: 997;
}
.pwaIcon{
    width: 50px;
    height: 50px;
    margin: 5px;
    border-radius: 2px;
    cursor: pointer;
}
.pwaButtonWrapper{
    display: flex;
    margin: 8px;
}
.pwaCard{
    //remove border radius at bottom
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.pwaWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    z-index: 999;
  }
  
  .pwaWrapper.show {
    transform: translateY(0);
    animation-name: slide-up;
  }
  
  .pwaWrapper.hide {
    transform: translateY(100%);
    animation-name: slide-down;
  }
  
  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0%);
    }
  }
  
  @keyframes slide-down {
    from {
      transform: translateY(0%);
    }
    to {
      transform: translateY(100%);
    }
  }
  #sideBarInstallButton {
    color: white;
    background: var(--primary);
    text-align: center;
    padding: 10px;
    width: 100%;
    cursor: pointer;
}