#propertyHeader {
    background-color: var(--entity-header-background-color);
    border-bottom: 1px solid var(--light-border-color);
    display: flex;
    color:white;
    box-shadow: var(--entity-header-box-shadow);
}

#propertyHeader h1 {
    font-weight: normal;
    font-size: 25px;
    margin: 14px 14px 0 0;
    line-height: 1;
}

#propertyHeader .image {
    margin: 14px;
    flex-grow: 0;
    flex-shrink: 0;
}

#propertyHeader .image img {
    border: 1px solid var(--entity-border-color);
    border-radius: var(--entity-border-radius);
    width: 100px;
    height: 100px;
}

#propertyHeader .content {
    padding: 14px 14px 14px 0;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
}

#propertyHeader .content .headerButtons {
    margin-top: 5px;
}

.propertyCard .cardTitle {
    margin-bottom:0;
}

.propertyCard .cardTitle a {
    color: var(--black);
}

.propertyCard .cardTitle a:hover {
    text-decoration: none;
}

.propertyCard small {
    margin-top: 1em;
    display: flex;
}

.lettableUnit {
    margin-bottom: 50px;
}

.lettableUnitHeader {
    display: flex;
    align-items: flex-end;
    margin-top:2em;
    margin-bottom:0.5em;
    font-size: 1.2em;
    vertical-align: bottom;
}

.lettableUnitHeader:first-of-type {
    margin-top: 0;
}

.lettableUnitHeaderSingle {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5em;
    align-items: flex-end;
}

/* TODO: This needs to be a commons css class - DD */
.adminDropdown {
    border-right: 4px solid var(--admin-only-color);
}

.certificateDropzone {
    background-color: rgba(10, 10, 10, 0.03);
    border: 2px dashed #DDD;
    padding:30px 14px;
    text-align: center;
    border-radius: 3px;
    margin-bottom: 24px;
}

.certificateCard {
    height: 100%;
}

.outer {
    position: relative;
}

.buttons {
    /* position: absolute; */
    text-align: center;
    font-size: 12px;
    width:100%;
    color:white;
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;
}

.buttons.hideButtons {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
}

.outer:hover .buttons.hideButtons {
    opacity: 1;
}

.buttonFirstUpload {
    background-color: var(--success);
    flex-grow: 1;
}

.buttonReplace {
    background-color: var(--success);
    flex-grow: 1;
}

.buttonDelete {
    background-color: var(--danger);
    flex-grow: 1;
}

table td.customEditTableTd, table .valuationEditTableRow td {
    border-bottom: none;
}

.propertyCertCardImg {
    height: 200px;
    object-fit: cover;
    display: flex;
    align-items: center;
}

.alignItemsBottom {
    display: flex;
    align-items: flex-end;
}

.imageLoadFailed {
    display: none;
}

.imageNotFound {
    display: none;
}

.imageLoadFailed + .imageNotFound {
    display: block;
    margin: 0.5rem 0;
}

div.propertyValuationLockIcon {
    text-align: right;
    margin-right: 11px; /* half cog menu width */
}



.detailRow {
    display: flex;
    margin-bottom: 1.5rem;
    white-space: pre-wrap;
}

.detailRow label {
    margin: 0 2rem 0 0;
    max-width: 15%;
    flex-grow: 1;
}

.propertyCardImage {
    aspect-ratio: 1 / 1;
    object-fit: cover;
    height: 250px;
}

.valuationCreateForm{
    width: 240px !important;
    padding: 0px !important;
    margin-top: 40px !important;
    margin-bottom: 0px !important;
}

.valuationCreateForm+small{
    display: inline-block;
    margin-top: 8px;
}

.formikFields{
    padding: 0px !important;
    
    input{
        max-width: 100px;
    }

    span{
        display: block;
    }
}

.createFormButton{
    margin-top: 40px;
}


.subtitle{
	margin-bottom: 2.3rem;
}
.subtitle p{
	margin-top: 0.75rem;
	margin-bottom: 0;
	color: var(--ink200);
	white-space: pre-line;
}

.lockIcon{
    
    svg{
        margin-right: 10px;
    }
    
}

.linkText{
    color: var(--ink-200);
    text-decoration-line: underline;
    cursor: pointer;
}

.nextArrow {
    margin: 0 !important;
}

.subheading{
    color: var(--ink300);
    font-family: Outfit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.75rem */
    margin-bottom: 24px;
}

.splitPropertyButton{
    margin-left: 5px;
}

.formikFieldsDate{
    padding: 0px !important;

    input{
        width: auto;
    }
    
}

.noImageContainer{
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: var(--light-background-color);
    border-bottom: 1px solid var(--entity-border-color);
}

.noImageContainerBackground{
    background: none;
}

.imageContainer{
    display: flex;
    justify-content: center;
}

.imageContainerCursor{
    cursor: pointer;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--image-hover);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s;
    border-radius: var(--v8-entity-border-radius);
    flex-direction: column;
}

.noImageContainer:hover .overlay {
    opacity: 1;
}

.imageContainer:hover .overlay {
    opacity: 1;
}

.iconContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    gap: 15px;
}

.iconWithLabel {
    display: flex;
    gap: 10px; 
    align-items: flex-end; 
    cursor: pointer;
}

.fontIcon {
    color: var(--white);
    width: 30px; 
}

.iconLabel {
    color: var(--white);
    padding-left: 5px; 
    font-size: 1rem;
}

.iconWithLabel:hover .iconLabel{
    text-decoration: underline;
}