.defaultIcon {
    font-size: 21px;
    color: var(--primary);
    margin: 0;
    padding: 0;
    cursor: pointer;
    margin-right: 5px;
}

/* FontAwesome image icon looks smaller than other icons, made larger for balance - DD */
.imageIcon {
  font-size: 24px;
}

/* Upload icon slightly muted to give focus to actual attachments, but not enough to look disabled - DD */
.greyedIcon {
  color: #777;
}

.greyedIcon:hover {
  color: black;
}

.checkIcon {
  color: var(--button-primary);
}

.warningIcon {
  color: var(--warning);
}

.warningIconSubtle {
  color: var(--muted-icon-color);
}

.crossIcon, .exclamationIcon {
  color: var(--danger);
}

.noRightMargin {
  margin-right: 0;
}