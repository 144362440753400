/* Lots of magic numbers here - but it's OK, we're pixel-matching the legacy layout */
$topBarHeight: 46px;

#topBar {
    background-color:var(--topbar-background-color);
    height: $topBarHeight; 
    line-height: 1em;
    display: flex;
    color: var(--topbar-color);
    align-items: center;
    padding-left:10px;
    max-width: 100vw;

    &Mobile {
        @extend #topBar;
        background: var(--sidebar-mobile-background-color);
    }
}

#topBar ::selection {
    color: none;
    background: none;
}

.toggleIcon  {
    --icon-fill-color: var(--topbar-color);
    height:18px;
    width:18px;
    margin-left: 4px;
    margin-right:13px;
    position: relative;
    top: 2px;
    cursor: pointer;
    flex-shrink: 0;

    &Mobile {
        @extend .toggleIcon;
        --icon-fill-color: white;
    }
}

.topBarLogo {
    max-height:100%;
    display: flex;
    margin-right: auto;
    height: 21px;
    position: relative;
    top: 1px;
}

.userMenuLabel {
    padding: 0;
    font-size: 13px;
    font-weight: 300;
    color: var(--topbar-color);
    position: relative;
    right: -4px;
    top: 2px;
    margin-right: 7px;
}

.gravatar {
    border-radius: 15px;
    margin-right:7px;
}

.searchBox {
    padding: 0 20px;
    width: 100%;
    margin-bottom: 7px;
    border-right: 4px solid var(--admin-only-color);
    white-space: nowrap;
}

.searchBox input {
    border: 1px solid #cacaca;
}

.dropDownItem {
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    font-size: 13px;
    font-weight: 300;
    text-decoration: none;
    color: black;

    &Mobile {
        @extend .dropDownItem;
        font-size: 16px;
        padding: 10px;
    }
}

.dropDownIcon {
    margin-right: 0.5rem;
    height: 12px;
    width: 12px;
}

div.dimOverlay {
    transition: 0.3s;
    background-color: black;
    opacity: 0;
    position: fixed;
    left: 0;
    top: $topBarHeight;
    right: 0;
    bottom: 0;
    visibility: hidden;
}

div.dimOverlayVisible {
    opacity: 0.6;
    visibility: visible;
}

.topBarDropdown {
  background: none !important;
  border: none;
  width: 100%;
  text-align: right;
}

.topBarDropdown:hover, .topBarDropdown:active, .topBarDropdown:focus {
    background: none !important;
}

:global(.show.dropdown) .topBarDropdown {
    background: none !important;
}

:global(.btn-primary:not(:disabled):not(.disabled):active).topBarDropdown {
    background: none !important;
}

.topBarDropdownLabel {
    position: relative;
    right: -4px;
    top: 3px;
    margin-right: 7px;
}

.mobileDropdownMenu {
    animation: slideInTopMenu 0.5s;
    width: 100%;
}

.mobileDropdownMenuItem {
    padding: 10px;
}

.topbarLink {
    color: var(--topbar-color);
}

.topbarLink:hover {
    text-decoration: none;
    color: var(--topbar-color);
}


// animations
@keyframes slideInTopMenu {
  0% { opacity: 0; transform: translateY(0); }
  33% { opacity: 0; }
  100% { opacity: 1; transform: translateY(1); }
}

.helpIcon {
    margin-right: 1rem;
    font-weight: bold;
    border-radius: 50%;
    border: 1px solid #c6cfda;
    width: 24px;
    height: 24px;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.helpIcon:hover {
    cursor: pointer;
}

.helpIconSelected {
    color: var(--topbar-background-color);
    background-color: #fff;
}

.dropDownMenuV8 {
    box-shadow: var(--entity-box-shadow-elevated);
}

.dropDownMenuV8Search{
    transform: translate(214px, calc(-95% + 10px)) !important; 
    max-height: 50vh;
    overflow-y: auto;
}

.profileLetter {
    font-size: 1em;
    width: 29px;
    height: 29px;
    line-height: 29px;
    text-align: center;
    border-radius: 50%;
    background-color: var(--sidebar-selected-background-color);
    vertical-align: middle;
    color: #FEFEFE;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 7px;
}