.dropzone {
    background-color: rgba(10, 10, 10, 0.03);
    border: 2px dashed var(--dropzone-border-color);
    padding:30px 14px;
    text-align: center;
    border-radius: 3px;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropzoneV75 {
    border-radius: var(--v8-entity-border-radius) !important;
}

.dropzone svg {
    color: var(--light-grey-icon-color);
}

.dropzoneText {
    text-align: left;
    margin-left: 3rem;
    max-width: 600px;
}

.csLineTableBody tr td {
    vertical-align: middle;
}

.amountCell {
    max-width: 200px;
}

.amountCell input {
    min-width: 90px;
    max-width: 120px;
    text-align: right;
}

.dateCell input {
    max-width: 150px;
}

.noBottomCellBorder td {
    border-bottom: none !important;
}

.reducedInputPadding input {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}

.amountReadCell {
    text-align: right !important;
    width: 200px;
}

.noPreview {
    display: flex;
    flex-direction: column;
}

.noPreview img {
    max-height: 20vh;
}

.dropDown{
    max-height: none;
}

@media screen and (max-height: 900px) {
    .dropDown {
      max-height: 400px;
      overflow-y: scroll;
    }
  }