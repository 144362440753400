.pillTabContainer {
  margin-bottom: 40px;
}

.pillTabs {
  border: none !important;
  display: inline-block !important;
}

.pillTabs :global(.nav-link) {
  border: none !important;
  padding: 9px 16px !important;
  border-radius: 25px !important;
  background-color: var(--grey100) !important;
  color: var(--ink200) !important;
  display: inline-block !important;
  margin: 0px 15px 15px 0px !important;
}

.pillTabs :global(.nav-link):hover{
  background-color: var(--grey200) !important;
}

.pillTabs :global(.nav-link.active) {
  background-color: var(--steel600) !important;
  color: white !important;
}

.pillTabs :global(.nav-link.active):hover {
  background-color: var(--steel700) !important;
}
.pillTabs :global(.nav-link.disabled) {
  opacity: 0.5;
}

.pillTabs :global(.admin)::after {
  content: "";
  background-color: var(--admin-only-color);
  border-radius: 50%;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 10px;
}