.outer {
    position: relative;
}

.buttons {
    position: absolute;
    text-align: center;
    height:18px;
    font-size: 11px;
    bottom:0;
    width:100%;
    color:white;
    display: flex;
    cursor: pointer;
}

.buttons.hideButtons {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
}

.outer:hover .buttons.hideButtons {
    opacity: 1;
}

.buttonFirstUpload {
    background-color: var(--success);
    flex-grow: 1;
}

.buttonReplace {
    background-color: var(--success);
    flex-grow: 1;
}

.buttonDelete {
    background-color: var(--danger);
    flex-grow: 1;
}
