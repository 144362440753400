

#desktopWrapper {
    display: flex;
    flex-direction: column;
    height:100%;
}

#alertBar {
    flex-grow: 0;
    flex-shrink: 0;
}

.error {
    display: flex;
    align-items: center;
    background-color: #a00;
    color: #fff;
    padding: 0 1rem;
    font-size: 12px;
    height: 25px;
    position: fixed;
    width:100%;
    z-index: 3000;
}

.error a {
    color: #fff;
    text-decoration: underline;
}
.error a:hover {
    color: #fff;
    text-decoration: underline;
}

#topBar {
    flex-grow: 0;
    flex-shrink: 0;
    z-index: 1000;
    position: sticky;
    align-self: flex-start;
    top: 0;
    width: 100%;
}

#topBar.hasError {
    margin-top: 25px;
}

#contentWrapper {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
}

#contentWrapperV8 {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
}

#sideBar, #sideBarV8 {
    flex-grow: 0;
    flex-shrink: 0;
    background-color: var(--sidebar-background-color);
}

#sideBarV8 {
    display: flex;
    height: 100vh;
}

#content {
    flex-grow: 1;
    flex-shrink: 1;
    overflow:hidden;  /* to enable responsive tables, overflow in elements etc */    
}


#contentV8 {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-x:hidden;  /* to enable responsive tables, overflow in elements etc */   
    overflow-y: auto; 
}