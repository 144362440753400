
.personCard {
	padding: 16px;
	border: 1px solid var(--steel300);
	background-color: var(--steel50);
	min-height: 104px;
	display: flex;
	column-gap: 10px;
    border-radius: 17px;
    cursor: pointer;
	align-items: flex-start;
	width: 244px;
	p {
		font-weight: 500;
	}
	span {
		align-self: flex-start;
        margin-bottom: 0;
	}
	height: 152px; // match height of the add new person card
}

.newCard {
	width: 175px !important;
	border: 1px solid var(--steel300);
	background-color: var(--steel50);
	cursor: default;
}

.avatar {
	font-weight: 600;
	color: var(--white);
	border-radius: 50%;
	height: 40px;
	min-width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
    text-transform: uppercase;
	background-color: var(--button-secondary) !important;
}

.activeAvatar{
	background-color: var(--button-primary) !important;
}

.active {
	border: 1.5px solid var(--steel500);
	background-color:var(--steel100);
	p {
		font-weight: 600;
	}
}

.loading  {
	animation: glow 1.5s ease-in-out infinite;
	background: var(--loading-bg-color) !important;
	color: transparent;
	cursor: progress;
}

.addNewButton{
	width: 100%;
	text-align: center;
	
	span{
		width: 100%;
	}
}
