#splittingSummary {
    margin-bottom: 25px;
}

.heading {
    font-weight: 800;
    font-size: 1.15rem;
    color: var(--ink300);
}

.summaryItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;
    padding: 1.5rem;
    background-color: var(--white);
}

.summaryItem > div:first-of-type {
    margin-right: 2.5rem;
}

.summaryItem > div > h3 {
    font-size: 1.25rem;
}

.summaryItemNumber {
    color: var(--ink300);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.summaryItemNumber > p {
    margin: 0;
    font-weight: 800;
    color: var(--ink300)
}

.summaryItemNumber > p:last-of-type {
    font-size: 1.75rem;
}

.summaryDetails {
    flex-grow: 1;
}

.summaryDescription {
    color: var(--ink100);
    margin-bottom: 0.5rem;
}

.summaryContract {
    color: var(--ink200);
    margin-bottom: 0;
}

.summaryAmount {
    font-weight: 600;
    font-size: 1.15rem;
    margin-left: 2.5rem;
}

.addItem {
    border: 2px dashed #DDD;
    padding:30px 14px;
    font-size: 1.15rem;
}

.addItem:hover {
    cursor: pointer;
}

.summaryFooter {
    display: flex;
    margin-top: 1rem;
    font-size: 1.15rem;
}

.summaryFooter > div:first-of-type {
    flex-grow: 1;
}

.amountTotals > div {
    background-color: var(--white);
    margin-bottom: 0.75rem;
    padding: 1rem;
    display: flex;
}

.amountTotals > div:last-of-type {
    margin-bottom: 0;
}

.amountTotals > div > p {
    margin-bottom: 0;
}

.amountTotals > div > p:first-of-type {
    flex-grow: 1;
    margin-right: 5rem;
}

.amountTotals > div > p:last-of-type {
    font-weight: 700;
}

.amountTotals > .writeOff {
    margin: 1rem 1rem 0 1rem;
    font-size: 1.15rem;
    align-items: center;
    background-color: rgba(0,0,0,0);
    padding: 0;
}

.writeOff > input {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
}