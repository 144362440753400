.pageOnboarding {
    max-width: 800px;
}

.pageOnboarding h3 {
    margin-bottom: 1em;
}

.pageOnboarding h4 {
    margin-top: 1em;
    margin-bottom: 0.5em;
}