.wrapper {
    display: flex;
    flex-direction: row;
    height:100%;
}

.tabs {
    flex-grow: 0;
    flex-shrink: 0;
    width: 200px;
    background-color: var(--steel50);
    height:100%;
    border-right: 1px solid var(--light-border-color);
}

.content {
    padding:14px;
    flex-grow: 1;
    overflow-x: auto;
}

.tab {
    display: block;
    width: 100%;
    padding: 1.25rem 1.5rem;
    border: none;
    line-height: 1;
    color: var(--tab-color);
    border-bottom: 1px solid var(--light-border-color);
    background-color: transparent;
    text-align: left;
}

.tab:hover {
    background-color: #fff;
}

.label {
    background: var(--steel200);
    padding: 0.8rem 1.5rem;
    cursor:default !important;
    font-style: italic;
}

.label:hover {
    background-color: var(--steel200);
}

.selected {
    background-color: #fff;
    border-right: 1px solid #fff;
    width: calc(100% + 1px); /*Tried to use margin-right: -1px but to no avail*/
}

.disabled, .disabled:hover {
    /* color: #bcbdc0;
    background-color: #f2f2f2; */
    background-color: transparent;
    opacity: 0.4;
}

.admin {
    border-right: 3px solid var(--admin-only-color);
}