.KVRichText {
  display: inline-block;
}

.KVRichText a {
  color: var(--link-blue-color);
  cursor: pointer;
  font-style: italic;
  text-decoration: underline;
}

.KVRichText a:visited {
  color: var(--link-blue-color);
}
