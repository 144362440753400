.editorRow {
    display: flex;
    text-align: left;
    margin-bottom: 1rem;
    /* max-width: 600px; */
}

.editorField {
    height: 100%;
    width: 220px;
    /* flex-grow: 1; */
    margin-right: 2rem;
}

.editorField > div {
    margin: 0;
}

.editorRow.description > div:first-of-type {
    width: calc(440px + 2rem); /*Double width and 2rem to account for margins of other inputs*/
}

.modalFooter {
    border-top: 1px solid #dee2e6; /*Copied from modal css*/
    margin: 0;
    padding-top: 0.75rem;
}

@media (min-width: 1300px) {
    .editorField {
        width: 280px;
    }

    .editorRow.description > div:first-of-type {
        width: calc(560px + 2rem); /*Double width and 2rem to account for margins of other inputs*/
    }
}
