.filesLayout{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: 1rem 0;
}
.fileDetailsCard::before{
    content: '';
    position: absolute;
    border-radius: 3px;
    width: 300px;
    height: 240px;

}
.fileDetailsCard{
    box-shadow: 1px 1px 3px rgb(50 50 50 / 10%);
    border-radius: 3px;
    background-color: #ffffff;
    width: 300px;
    height: 240px;
    margin: 0 0px 16px 16px;
    float: left;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    border: 1px solid var(--grey200);
}
.clientAccessCard{
    border-right:5px solid #5bb75b !important;
}
.fileDetailsBody{
    height: 100%;
    width: 100%;
    position: relative;
}
.iconWrapper{
    width: 35%;
    height: 100%;
    background-color: var(--steel100) !important;
    border-radius: 3px 0 0 3px;
    overflow: hidden;
    border-left: 1px solid rgb(230, 246, 246);
    border-top: 1px solid rgb(230, 246, 246);
}
.iconWrapperText{
    display: block;
    width: 64px;
    position: absolute;
    top: 90px;
    text-align: center;
    font-size: 12px;
    line-height: 1.2em;
}

.detailsWrapper{
    width: 65%;
    height: 100%;
    padding: 1rem 1rem;
}
.detailsTitle{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 0.5rem 1rem;
    border-bottom: 2px solid rgb(213, 216, 217);
    white-space: nowrap;
}
.fileType{
    font-size: 10px;
    color: #000;
    padding: 0.5rem 0rem;
    max-height: 60px;
    max-width: 160px;
    /* overflow: overlay; */
    /* overflow-x: hidden; */
    border-bottom: 1px solid #fefefe;
}
.fileType i{
    font-size: 12px;
    margin-right: 6px;
}
.fileType span{
    font-size: 10px;
    margin-right: 0.5px;
}
.fileSource{
    font-size: 11px;
    color: #fff;
    text-transform: uppercase;
}

.adminAccess  span{
    background-color: var(--admin-only-color) !important;
    padding: 2px 6px;
    border-radius: 3px;
}
.clientAccess  span{
    background-color: var(--client-access-color) !important;
    padding: 2px 6px;
    border-radius: 3px;
}
.downloadButtonGroup{
    position: absolute;
    bottom: 24%;
    z-index: 3;
}
.downloadButtonGroup svg{
    margin-right: 0 !important;
}
.actionButtonGroup{
    position: absolute;
    margin-top: 2px;
}
.fileName{
    font-size: 14px;
    color: #000;
    font-weight: 600;
    line-height: 1.2;
}
.labelsText{
    text-overflow: ellipsis !important;
    width: 160px;
    display:inline-block;
    overflow: hidden;
    white-space: nowrap;
}

/* === V7.5 === */
.fileDetailsCardV75 {
    box-shadow: none;
    border-radius: 12px;
}