#loadingFullPage {
    position: fixed;
    z-index: 10000;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background-color: #eee;
}

#inner {
    text-align: center;
    width: 300px;
    margin: 200px auto 0 auto;
    color: #888;
}

.logo {
    max-width:200px;
    margin-bottom:10px;
}

.errorMessage {
  color:#a00;
  line-height: 1.1em;
  font-size: 16px;
  margin-top:20px;
}

/* https://projects.lukehaas.me/css-loaders/ */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #AAA;
  font-size: 10px;
  margin: 0px auto 30px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -2.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 2.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 1.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 1.5em 0 0;
  }
}
