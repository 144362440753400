.referral {
    margin-top: 20px;
    background-color: var(--referral-box-background) !important;
    padding: 1rem;
    color: #00394b;
    margin-bottom: 20px;
}

.referral h4::before {
    content: '';
    position: absolute;
    margin-top: -1px;
    margin-right: -1px;
    top: 0;
    right: 0;
    border-top: 80px solid var(--rainforest-green);
    border-left: 80px solid var(--referral-box-background);
    width: 0;
    border-top-right-radius: 5px;
    z-index: 900;
}

.planeImage {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    z-index: 901;
    color: white;
}

.referralBoxProv {
    background-color: white;
    width: 50%;
    font-size: 16px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.referralBoxInni {
    background-color: white;
    max-width: 85%;
    flex-grow: 1;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.referralBoxInniLink {
    color: var(--inni-red);
    font-weight: bold;
    font-size: 24px;
    font-family: 'Inter',"Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
}

.inniRef {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    margin: 1rem -1rem;
    width: 100%;
    flex-wrap: wrap;
}

.reminderCard {
    margin-bottom: 20px;
    width: 100%;
}

.overdue {
    border-top: 3px solid var(--reminder-overdue) !important;
}

.duesoon {
    border-top: 3px solid var(--reminder-due-soon) !important;
}

.upcoming {
    border-top: 3px solid var(--reminder-upcoming) !important;
}

.dashboardTab {
    border: 0px !important;
    color: var(--var-tab-color) !important;
    font-size: 1.1rem !important;
    padding: 15px !important;
}

.selected {
    border-bottom: 2px solid var(--var-tab-color) !important;
    /* color: red !important; */
    border-radius: 6px 0px 0px 0px !important;
}

.dashboardCardBody {
    padding: 5px !important;
}

.referralCardBody {
    margin-right: 20px;
}

.referralTitleText {
    font-family: 'Inter',Arial,Helvetica,sans-serif; 
    font-weight: bold;
    font-size: 1.2857142857rem;
}

.cardAdmin {
    border-right: 2.5px solid var(--admin-only-color) !important;
}

.reminderTableRow td, .reminderLoading tr td {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.reminderTableRow td:first-child {
    padding-right: 0.25rem;
    vertical-align:middle;
}

.reminderLoading tr {
    border-bottom: 0.5px solid #eee;
}

.btnContainer {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.btnContainer a, .btnContainer button {
    z-index: 999;
}

/* btm margin for first btn */
.btnContainer button:first-child {
    margin-bottom: 5px
}

.dashboardTablePadding {
    padding: .5rem 1rem;
}

.dashboardTableRow {
    border-bottom: 0.5px solid #eee;
}

.dashboardTableRow:last-child {
    border-bottom: 0px;
}

.dashboardCardBody p {
    margin-bottom: 5px;
    line-height: 1.6;
}

/* Pie chart stuff */
.pieContainer {
    padding: 12px;
    cursor: pointer;
}

.pieContainerMobile {
    height: 100px;
    min-width: 100px;
    width: 30%;
}

.breakdownPieContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: row;
    flex-wrap: wrap;
}

.breakdownPie {
    /* display: 'flex', height: '100px', flexDirection: 'column', width: '150px', cursor: 'pointer', alignItems: 'center' */
    display: flex;
    flex-direction: column;
    width: 150px;
    cursor: pointer;
    align-items: center;
}

.statusIcon {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

div.incomeExpensesContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

div.incomeExpensesItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 10px 25px 20px 25px;
    text-align: center;
}

div.incomeExpensesTooltip {
    background: white;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(50, 50, 50, 0.1);
}

div.legendIcon {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 7px;
}

div.legend {
    text-align: left;
    font-size: 11px;
}
