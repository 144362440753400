.reportTitle{
    padding: 10px;
    font-size: 1.9rem !important;
}

.reportItemParent {
    text-align: left;
    display: flex;
    margin-bottom: 0.25rem;
}

.level-0{
    background: var(--steel200);
    border-radius: var(--entity-border-radius);
    color: var(--black);
    font-size: 1.2rem !important;
    padding: 10px;
}

.level-1 {
    padding: 10px;
    font-size: 14px !important;
}

.level-2 {
    font-size: 14px !important;
    padding: 10px 10px 10px 2rem;
}

.level-3 {
    padding: 10px 10px 10px 3rem;
    font-size: 14px !important;
}

.floatRight { float: right; }
.flexGrow {flex-grow: 1;}

.selectFYYear{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;

    label{
        display: inline-block;
    }
}


.SelectFilter {
	margin-left: 5px;
	outline-color: none;
	width: 200px;
}

.SelectFilter :global(.SelectFilter__control) {
	border-color: var(--slate-blue-border-color);
	padding: 1.5px;
	cursor: pointer;
}

.SelectFilter :global(.SelectFilter__menu) {
	max-width: 220px;
	border: 1px solid var(--entity-border-color) !important;
}
