#brandWrapper {
    --button-secondary: #6E7780;
    --button-secondary-hover: #5E6770;
    --button-other: transparent;
    --button-danger: #dc3c3c;
    --button-danger-hover: #CC2C2C;

    --admin-only-color: #ef0e73;
    --admin-only-alert-bg: #fee7f1;
    --admin-only-danger-color: orange;
    --client-access-color: #5bb75b;
    --rainforest-green: #007463;

    --entity-border-color: #DDD;
    --entity-border-color-darker: #D4D4D4;  // Used on grey backgrounds
    --entity-border-radius: 3px;

    --entity-box-shadow: 1px 1px 3px rgba(50, 50, 50, 0.1);  // Default shadow
    --entity-box-shadow-elevated: 0px 3px 6px rgba(0, 0, 0, 0.08);  // Highlighted items as opposed to default shadow
    --entity-box-shadow-focused: 0px 2px 6px rgba(0, 0, 0, 0.25);  // Overlays that need to attract focus above whats below it

    --entity-inset-box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
    --entity-inset-box-shadow-top-only: inset 0px 11px 2px -10px rgb(10 10 10 / 10%);  // Rare occasion a field will be made up of adjoining elements

    --light-background-color: #F5F5F5;
    --light-border-color: #E7E7E7;
    --light-sub-border-color: #EEE;  // Used in conjunction with --light-border-color

    --slate-blue-border-color: #CED4DA;

    --dark-background-color: #EAEAEA;

    --var-tab-color: #595478;

    --grey-no-content-text-color: #BBB;

    --text-dark-color: #333;

    --feature-blue: #1F7BCC;
    --feature-green: #4BA74B;

    --bubble-green: #5CA337;

    --progress-bar: #FFD25E;
    --progress-bar-outline: #CFB23E;
    --text-light-color:#999;

    --card-title-color:#00394b;

    --dark-border-color:#999;

    --displaycard-text:#444;
    --displaycard-child-text:#AAA;

    --error-text: #DD0000;

    --account-color-1:  #071D49;
    --account-color-2:  #E53342;
    --account-color-3:  #FFC400;
    --account-color-4:  #440099;
    --account-color-5:  #00828C;  // provestor green
    --account-color-6:  #5CB8B2;
    --account-color-7:  #A53B8B;
    --account-color-8:  #9FAEE5;
    --account-color-9:  #46CFF5;
    --account-color-10: #758E9A;
    --account-color-11: #595478; // provestor dark purple

    // Reserved colours 10x green 20x red
    --account-color-101: #5FCE5B;
    --account-color-102: #007463;
    --account-color-201: #C05151;
    --account-color-202: #A6192E;

    // Provestor colours
    --provestor-green: #00828C;
    --provestor-dark-purple: #595478;

    // Icon colours
    --light-grey-icon-color: #777;
    --grey-icon-color: #555;
    --orange-icon-color: #EF8900;
    --green-icon-color: #58BF62;
    --muted-icon-color: #DDD;
    --danger-icon-color: #D00;

    // Info banners
    --info-border-color: #595478;
    --info-text-color: #433F5A;
    --info-background-color: #EEEDF2;

    --warning-border-color: #faebad;
    --warning-text-color: #917508;
    --warning-background-color: #fef9e7;

    --alert-success-border: #6b8d53;
    --alert-success-bg: #dff0d8;

    // From bootstrap alerts
    --alert-info-border: #bde4ea;
    --alert-info-bg: #d0ecf0;
    --alert-info-text: #115964;

    --link-blue-color: #0082D0;
    --text-feature-color-blue: #0082D0;
    --text-dark-color: #333;
    --success-green-color: #58BF62;
    --disabled-placeholder-color: #E5E5E5;

    // live cash colours
    --livecash-positive: #7ED321;
    --livecash-positive-disabled: #C1EE91;
    --livecash-negative: #D0021B;
    --livecash-negative-disabled: #F0BEC4;
    --livecash-available: #4A90E2;
    --livecash-remainderfill: #E1E5E6;

    // dashboard colours
    --reminder-overdue: #d00;
    --reminder-due-soon: #f1c40f;
    --reminder-upcoming: #5bb75b;
    --record-payment-btn: #39628d;

    // datagrid
    --data-grid-evenrow: #FCFCFC;
    --filter-selected: #39628d;

    // unmatched colour
    --unmatched-row-color: #fef9e7;
    --unmatched-row-color-hover: #fdf3cf;
    --table-header: #39628d;

    // mobile sidebar
    --sidebar-mobile-border-color: #DDD;
    --sidebar-mobile-selected-color: #EEE;

    //Helpbar colours
    --green-dark: #4D994D;    
    --yellow-dark: #F39C12;
    --salmon-dark: #C0392B;
    --teal-dark: #16A085;

    // Account Selector
    --as-green-bg: #81D181;
    --as-green-text: #2E4F2E;
    --as-green-border: #81D181;
    --as-green-darker: #74B777;

    --as-amber-bg: #F9CD60;
    --as-amber-text: #5D4519;
    --as-amber-border: #F9CD60;
    --as-amber-darker: #eabe50;

    --as-red-bg: #E55640;
    --as-red-text: #380319;
    --as-red-border: #E55640;
    --as-red-darker: #C53620;

    // scroll bars
    --scroll-bar-thumb: #ddd;
    --scroll-bar-thumb-hover: #bbb;
    --scroll-bar-track: #f1f1f1;

    // Split
    --split-transaction-border: #cdd9e4;
    --split-transaction-color: #123b5d;
    //InvoiceTimeLine colours
    --invoice-created: #00828C;
    --invoice-payment-due:#ef4747;
    --invoice-paid-full:#5BB75B;
    --invoice-paid-part: #E67E22;
    --invoice-received-full: #5BB75B;
    --invoice-received-part:#E67E22;
    --invoice-vaton-return:#00828C;
    --invoice-rti-complete: #5BB75B;
    --invoice-rti-failed: #DD0000;
    --invoice-pending: #E67E22;
    --invoice-paye-on-return: #00828C;
    --invoice-timeline-background-color:#f4f4f4;
    //TimeLine colours
    --timeline-background-color:#f4f4f4;
    --timeline-text-color:#546979;
    --timeline-description-color:#777;
    
    //font sizes
    --font-small: 0.75rem;
    --font-size-title: 25px;
    --font-weight-title: 300px;
    // Big green button (usual green is too light at scale)
    --big-positive-button-color:#00828C;
    --big-positive-button-color-hover: #00727C;
    --big-positive-button-side-color: #00727C;
    --big-positive-button-side-color-hover: #00626C;

    // Select colours
    --select-hover: #DEEBFF;
    --select-selected: #2684FF;

    //files sidebar
    --sidebar-bg-color:#f0f0f0;
    --sidebar-parent-bg-color:#ddd;
    --sidebar-items-border-color:#ccc;
    --sidebar-items-active-color:#dfe0e3;

    --dropzone-border-color: #ddd;

    --container-width: 1220px;

    --muted-text: #6C757D;
    --disabled-text: #8C959D;

    // === V8 ===
    // project colors
    --tertiary: #E8EFFD;
    --black: #160F29;
    --white: #FEFEFE;

    --white-hover: #e9ecef;

    --grey2: #E6E9EF;
    --grey3: #C5C7D0;
    --grey4: #8A8B94;

    --grey75: #F4F2FA;
    --grey100: #F3F4F5;
    --grey200: #E0E2E4;
    --grey300: #CACED2;
    --grey400: #A9AFB5;
    --grey500: #777F89;
    --grey600: #6E7780;
    --grey-black: #323338;

    // typography colors
    --ink75: #C5C1C7;
    --ink100: #A9AFB5;
    --ink200: #585F67;
    --ink300: #353F4A;

    --steel50: #FBFBFC;
    --steel100: #F2F4F6;
    --steel150: #F0F2F5;
    --steel200: #DEE2E7;
    --steel300: #C8CFD7;
    --steel500: #8091A4;
    --steel600: #64778C;
    --steel700: #4C5B6B;
    --steel900: #353F4A;

    --incomingTxnColor: #26974a; //Placeholder

    // ui colors
    --ui-link: #0E7EF8;
    --ui-negative: #EB2733;
    --ui-positive: #3AE971;
    // --ui-warning: #FDE965;
    --ui-warning: orange;

    --v8-entity-border-radius: 10px;
    --v8-white-box-radius: 5px;

    --input-field-radius: 3px;

    // Note: button-grey-outline is a bootstrap value, update with caution
    --button-grey-outline: rgba(0,0,0,0.2);
    --button-grey-outline-hover: rgba(0,0,0,0.4);
    --button-dark-grey-outline: rgba(0,0,0,0.5);

    --v8-unmatched-row-color: #ECFFEC;
    --card-Header : #dff3fa;
    --image-hover : rgba(0, 0, 0, 0.7);

    // Fixed height to ensure height remains the same with or without buttons
    --layout-title-height: 42px; 
    --margin-below-title: 15px;
    --margin-below-sub-title: 10px;

    //Loading placeholder
    --loading-bg-color: #DDD;

    --v8-card-radius: 15px;
}
