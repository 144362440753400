.welcomeOuterLayout{
    display: flex;
    justify-content: center;
    width:100%;
    max-width: 1440px;
    min-width: 500px;
}

.welcomeInnerLayout{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.welcomeLayout{
    display: flex;
    flex-direction: column;
    max-width: 820px;
    min-width: 500px;
    justify-content: center;
    gap:16px
}

.welcomeButtonLayout{
    display: flex;
    flex-direction: column;
    padding: 40px 0px 10px 0px;
    gap: 60px;
}

.startButton{
    width: 159px;
}
.outerLayout{
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1440px;
}

.innerLayout{
    display: flex;
    flex-direction: column;
    max-width: 820px;
    min-width: 500px;
}

.bankAccounts{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 23px;
}

.allBanks{
    display: flex;
    margin-top: 40px;
    margin-bottom: 47px ;
    flex-direction: column;
}

.H2Title{
    color: var(--ink-300, #353F4A);
    font-family: Outfit;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.SectionTitle{
    color: var(--ink-300, #353F4A);
    font-family: Outfit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.75rem */
    margin: 0;
}
.dividerTitle {
    color: var(--ink-300, #353F4A);
    font-family: Outfit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.75rem */
    margin-top: 15px;
    margin-bottom: 20px;
}

.pBold{
    color: var(--ink-300, #353F4A);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    margin:0px
}


.bankRecommended{
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 7px 7px 0px 0px;
    background: var(--steel-500, #8091A4);
    color: var(--project-colours-white, #FEFEFE)!important;
}

.bankSubtitle{
    color: #000 !important;
}

.welcomeText{
    color: var(--ink-200, #585F67) !important;
}
.normal{
    color: var(--ink-300, #353F4A);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.4rem */
    margin:0px
}

.pSmall{
    color: var(--ink-200, #585F67);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.linkText{
    color: var(--ink-200, #585F67);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;

}

.searchBar{
    width:356px;
}


.noResult{
    width:100%;
    text-align: center;
}

