.selectListFilter {
    display: inline-block;
    margin-right: 0.5rem;
    min-width: 300px;
    margin-bottom: 1rem;
}

.cardColumn {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
}

.cardColumn.right {
    align-items: flex-end;
}

.infoLineSingle {
    margin-bottom: 2rem;
}

.multiInfoLine {
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
}

.infoLineSingle p, .multiInfoLine p {
    margin-bottom: 0;
}

.contentContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.timeLine {
	border: 1px solid var(--entity-border-color) ;
	box-shadow: 1px 1px 3px rgb(50 50 50 / 10%);
	border-radius: var(--entity-border-radius) !important;
	background-color: var(--timeline-background-color) !important;
    max-width: 300px;
}

.timeLineV8 {
    border-radius: var(--v8-entity-border-radius) !important;
    background-color: var(--steel100) !important;
    border: none;
    box-shadow: none;
}

.dividendVoucherCard {
    width: 900px;
}

.dividendVoucherCardV8 {
    border-radius: var(--v8-entity-border-radius) !important;
}

.emailInputHeaderRow:not(:last-of-type) .emailHeaderInputLabel, 
.emailInputHeaderRow:not(:last-of-type) .emailHeaderInput:not(:focus) {
    border-bottom: none;
}

/*Since we're removing border bottom only when not focused, this is to avoid the focused input border appaearing against the next input border*/
.emailInputHeaderRow:focus-within + .emailInputHeaderRow .emailHeaderInput {
    border-top: none;
}

.emailHeaderInputLabel {
    width: 100px;
    font-weight: bold;
    text-align: start;
    border-bottom: 1px solid var(--slate-blue-border-color);
    border-right: none;
}

.attachmentName {
    max-width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reportItemParent {
    text-align: left;
    display: flex;
    margin-bottom: 0.25rem;
    padding: 0.5rem;
}

.reportItemParent label {
    margin-bottom: 0;
    flex-grow: 1;
}

.reportItemParent p {
    display: inline-block;
    margin-bottom: 0;
}

.reportItemParent.level-0 {
    border-bottom: 1px solid #ccc;
    padding: 1rem 0.5rem;
}

.reportItemParent.level-0 p {
    font-weight: bold;
}

.reportItemParent.level-1 {
    border-bottom: 1px solid #eee; 
    padding-left: 1rem;
}

.reportItemParent.level-3 + .reportItemParent.level-1 {
    border-top: 1px solid #EEE;
}

.newDividendDetail {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CCC;
    padding: 1rem 0;
}

.newDividendDetail:first-of-type {
    padding: 0 0 1rem 0;
}

.newDividendDetail > div:first-of-type {
    flex-grow: 1;
}

.newDividendDetail label {
    font-weight: bold;
}

.newDividendDetail p {
    margin-bottom: 0;
}

.profit svg {
    margin-right: 0.5rem;
    cursor: pointer;
}

.taxChart {
    width: 200px;
}

.band {
    text-align: left;
    font-size: 11px;
    color: #AAA;
    display: inline-block;
}

.bar {
    height: 15px;
    border: 1px solid;
}

.bar span {
    height: 100%;
    display: inline-block;
}

/*Tax free*/
.tax-free {
    width: 15%;
}
.tax-free .bar {
    border-color: var(--invoice-paid-full);
    border-right:none;
}
.tax-free .bar .current {
    background-color: var(--invoice-paid-full);
}

/*Basic*/
.basic-rate {
    width: 30%;
}
.basic-rate .bar {
    border-color: var(--invoice-pending);
    border-right: none;
}
.basic-rate .bar .current {
    background-color: var(--invoice-pending);
}

/*Higher*/
.higher-rate {
    width: 55%;
}
.higher-rate .bar {
    border-color: var(--invoice-rti-failed);
}
.higher-rate .bar .current {
    background-color: var(--invoice-rti-failed);
}

.newDividendMeta {
    text-align: right;
    max-width: 250px;
}

/* V8 */
div.amountAvailable {
    vertical-align: top;
    border: 1px solid var(--steel200);
    border-radius: 10px;
    background: white;
    width: 400px;
    padding: 20px;
    display: inline-block;
    margin-right: 10px;
    height: 250px;
}

div.amountAvailable h3 {
    display: inline-block;
    background: var(--grey2);
    padding: 10px 15px;
    border-radius: 5px;
    vertical-align: center;
}
