#noCompany {
    background-color: var(--light-background-color);
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.onboardingProcess {
    min-width: 100%;
    max-width: 1000px;
    min-height: 650px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px;
    margin: 5vh 5vh 20vh 5vh;
    color: #333;
}

.onboardingProcess img {
    margin-top: 5%;
}

.onboardingProcess div {
    text-align: center;
}

.onboardingProcess div h5 {
    width: 80%;
    margin: 0px auto 5px;
    font-weight: normal;
}

.onboardingProcess > p {
    color: var(--button-positive-color)
}

.activeStepText {
    color: var(--button-positive-color);
    width: 80%;
}

.onboardingProcess > div:last-child {
    color: var(--grey-icon-color);
    margin-bottom: 10px;
    width: 95%;
}

.onboardingProcess > div:last-child p {
    margin: 0;
}
.onboardingProcess > div:last-child span {
    margin-left: 7px;
}

.contactDetail {
    margin-right: 20px;
    display: inline-block;
}

.incorpFormContainer {
    margin-top: 20px;
}

.incorpFormContainer section {
    margin-top: 50px;
}


.incorpFormBtns {
    display: flex;
    justify-content: center;
}

.incorpFormBtns button {
    margin-bottom: 20px;
    margin-right: 8px !important;
}

.incorpFormBtns button:last-child {
    margin-right: 0px;
}

.label {
    margin: 0 2rem 0 0;
    max-width: 15%;
    flex-grow: 1;
    margin-bottom: 1.5rem;
}

.selectListCustom {
    width: 100%;
    border-radius: 0;
    border-color: var(--slate-blue-border-color);
}

.selectListCustom :global(.selectList__control) {
    border-radius: 0;
    border-color: var(--slate-blue-border-color);
}

.input {
    padding: .5714285714rem;
}

.alert {
    z-index: 999;
    position: fixed !important;
    left: 5%;
    bottom: 10px;
    width: 90%;
}

.cardBtn {
    margin-right: 0px !important;
}

.docTags {
    margin-bottom: 15px;
}

.docCardHeader {
    width: 100%;
    display: flex;
    justify-content: center;
}

.docCardHeader img, .docCardHeader div {
    height: 200px;
    max-width: 100%;
    object-fit: contain;
}

.docCardHeader div {
    display: flex;
    align-items: center; 
    
}

.docCardHeader div > * {
    height: 75px;
}

.docCardButtons {
    display: flex;
    flex-wrap: wrap;
}

.viewImage {
    max-height: 500px;
}

.noPreview {
    display: flex;
    flex-direction: column;
}

.noPreview img {
    max-height: 20vh;
}

@media only screen and (max-width: 768px) {
    .onboardingProcess {
        margin: 2.5vh;
    }

    .incorpFormBtns button {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 700px) {
    
    .onboardingProcess {
        height: 100%;
        margin: 0;
        overflow: visible;
        padding: 5px;
        /* justify-content: flex-start; */
        min-height: calc(100vh - 100px);
    }

    .onboardingProcess .formTxtContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 20%;
    }

    .onboardingProcess h1 {
        font-size: 2em;
        text-align: left;
        width: 80%;
    }

    .onboardingProcess h5 {
        font-size: 1em;
        width: 100%;
    }

    .onboardingProcess img {
        margin-bottom: 5%;
        margin-top: 50px;
    }

    .onboardingProcess div h1 {
        margin-bottom: 0px;
    }

    .onboardingProcess div h5 {
        width: 80%;
        margin-bottom: 20px;
        margin-top: 20px;
        text-align: left;
    }

    .contactDetail {
        display: block;
    }

    .onboardingProcess > div:last-child p {
        margin-bottom: 10px;
    }
    
    .onboardingProcess > div:last-child {
        width: 80%;
    }
}