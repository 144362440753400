#sideBar,
#sideBarV8 {
    --v8-base-font-size: 1.15rem;
    color: var(--sidebar-color);
    --icon-fill-color: var(--sidebar-color);
    width: 240px;
    /*fit-content; does not work with transition animation*/
    /*Magic number to hide text!*/
    /* transition: width .3s ease; */
    flex-grow: 0;
    flex-shrink: 0;
    background-color: var(--sidebar-background-color);
    transition: margin 0.3s ease;

    &Mobile {
        @extend #sideBar;
        width: 245px;
        background-color: white;
        color: var(--sidebar-mobile-icon-fill-color);
        --icon-fill-color: var(--sidebar-mobile-icon-fill-color);
    }
}

#sideBarV8.standaloneWizard {
    color: var(--black);
    background-color: white;
    width: 270px;
    border-right: 1px solid var(--entity-border-color);

    .sideBarLogo {
        width: 12rem;
        margin: 2.4rem 1.5rem 3rem 2.4rem;
    }
    
    .items {
        a {
            color: var(--black);
            text-decoration: none;
            font-weight: 500;
        }

        p {
            cursor: pointer;
            margin-left: 10px;
            margin-bottom: 18px;
        }

        .disabled {
            color: var(--disabled-text);
            pointer-events: none !important;
        }

        .active {
            font-weight: 800 !important;
        }

        span {
            font-size: 1.25rem !important;
        }
    }

    .logout {
        cursor: pointer;
    }
    .logout:hover {
        text-decoration: underline;
    }
}


#sideBar ::selection {
    color: none;
    background: none;
}

.sideBarLink,
.sideBarLabel {
    padding: 10px 6px 10px 14px;
    // font-size: 13.3px;
    font-size: 1rem;
    line-height: 24px;
    cursor: pointer;
    /* white-space: nowrap; */
    display: block;
    color: var(--sidebar-color);
    /* text-overflow: ellipsis; */
    overflow: hidden;

    &Mobile {
        @extend .sideBarLink;
        color: var(--sidebar-mobile-icon-fill-color);
        // font-size: 16px;
        font-size: var(--v8-base-font-size);
    }
}

.sideBarLinkNewTag::after {
    background-color: #4bab4b;
    color: #fff;
    border-radius: 2px;
    padding: 2px 4px;
    font-size: 12px;
    content: "New";
    margin-left: 8px;
}

.sideBarLink:hover {
    background-color: var(--sidebar-selected-background-color);
    color: var(--sidebar-color);
    text-decoration: none;
}

.sideBarLinkMobile:hover {
    background-color: var(--sidebar-mobile-selected-color);
    color: var(--sidebar-mobile-icon-fill-color);
    text-decoration: none;
}

.sideBarLink.selected {
    background-color: var(--sidebar-selected-background-color);
}

.sideBarLink.selectedMobile {
    background-color: var(--sidebar-mobile-selected-color);
}

.sideBarLink.adminOnly {
    border-right: 4px solid var(--admin-only-color);
}

.icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 14px;

    &Mobile {
        @extend .icon;
        position: relative;
        top: -2px;
    }
}

.icon svg {
    width: 18px;
    height: 18px;
}

.sideBarLink.child {
    padding: 6px 6px 6px 46px;
    border-bottom: none;
    font-size: 0.95rem;
}

.sideBarLabel {
    padding: 12px 0 6px 46px;
    border-bottom: none;
    // font-size: 12px;
    font-size: 1rem;
    cursor: auto;
}


#sideBar.sideBarCollapsed {
    /*Need to use nesting to get around ID priority*/
    width: 45px;
    /*Magic number? Just however wide we want it to be*/
}

#sideBar.sideBarCollapsed.mobile {
    width: 240px;
    /* sidebar width */
    margin-left: -240px;
}

#sideBar.sideBarCollapsed .sideBarItem .disclose {
    display: none;
}

.sideBarItem .disclose {
    display: block;
    float: right;
}

.sideBarItem .disclose svg {
    width: 8px;
    height: 8px;
    margin-right: 9px;
    transition: transform .3s ease;
}

.sideBarItem.collapsed .disclose svg {
    transform: rotate(-90deg);
    transition: transform .3s ease;
}

/**************/

.sideBarItem {
    overflow: hidden;
}

.sideBarItem .children {
    display: block;
}

.sideBarCollapsed .sideBarItem .children {
    display: none;
    overflow: hidden;
}

.sideBarItem.collapsed .children {
    display: none;
    overflow: hidden;
}

.sideBarCollapsed .sideBarItem:hover {
    position: relative;
    width: 256px;
    overflow: visible;
    z-index: 1000;
}

.sideBarCollapsed .sideBarItem:hover>.sideBarLink {
    background-color: var(--sidebar-collapse-hover-background-color);
}

.sideBarCollapsed .sideBarItem:hover>.sideBarLink .icon {
    margin-right: 20px;
}

.sideBarCollapsed .sideBarItem:hover .children {
    display: block;
    overflow: visible;
    position: absolute;
    left: 46px;
    width: 210px;
}

.sideBarCollapsed .sideBarItem:hover .children .sideBarLink,
.sideBarCollapsed .sideBarItem:hover .children .sideBarLabel {
    background-color: var(--sidebar-background-color);
    padding-left: 14px;
}

.sideBarCollapsed .sideBarLink,
.sideBarCollapsed .sideBarLabel {
    white-space: nowrap;
}

.sideBarCollapsed .sideBarLink.child {
    white-space: normal;
}

#sideBarFeedbackButton {
    color: white;
    background: #BBB;
    text-align: center;
    padding: 10px;
    width: 100%;
    cursor: pointer;
}

#sideBarButtons {
    bottom: 0;
    position: absolute;
    width: 100%;
}

//--V8 styling rules--
#sideBarV8 {
    font-size: var(--v8-base-font-size);
    width: 240px;
    // padding: 2.4rem 1.5rem 1.5rem 1.5rem;
    padding: 0;
    --icon-fill-color: #FEFEFE;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: #FEFEFE;
}

#sideBarV8 .sideBarItem {
    margin-bottom: 0.5rem;
}

#sideBarV8 .sideBarLink {
    display: flex;
    color: inherit;
    border-bottom: none;
    font-size: var(--v8-base-font-size);
    padding: 0.625rem;
    font-weight: 400;
}

#sideBarV8 .sideBarLink.child {
    padding-left: 3rem;
    font-size: 0.95rem;
}

#sideBarV8 .sideBarLink.selected {
    font-weight: 600;
}

#sideBarV8 .sideBarLink.selected, #sideBarV8 .sideBarLink:hover {
    border-radius: 0.15rem;
}

#sideBarV8 .sideBarLink .disclose {
    flex-grow: 1;
    text-align: right;
}

#sideBarV8 .sidebarGroup {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 1rem;
    padding: 0 1.5rem;
}

#sideBarV8 .icon {
    position: relative;
    top: -0.75px;
}

#sideBarV8 .profile {
    padding: 0 2rem;
}

#sideBarV8 .accountDropdownContainer {
    padding-left: 2rem;
}

#sideBarV8 .children {
    border-bottom: none;
}

#sideBarV8 .sideBarItem .disclose {
    margin-left: 1rem;
}

#sideBarV8 .sideBarItem .disclose svg {
    position: relative;
    top: -1px;
}

.profileContainer {
    padding-bottom: 1.5rem;
}

.profileContainer hr {
    margin: 1rem 1.5rem;
}

.profile span {
    font-size: var(--v8-base-font-size);
}

#sideBarV8 .usersName {
    font-weight: 600;
    margin: 0.25rem 0;
}

.usersName a {
    color: inherit;
}

.accountDropdown {
    background: none !important; //Important because we need to override the "selected" background
    border: none;
    padding: 0;
    font-weight: 400;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: var(--v8-base-font-size);
}

.accountDropdown span {
    display: inline-flex;
    align-items: center;
    margin-right: 0.25rem;
    padding: 0.625rem 0.75rem 0.625rem 0.625rem;
}

.accountDropdown::after {
    margin-top: 0.25rem;
}

#sideBarV8 .companyName {
    font-size: 1rem;
    margin: 0;
}

#sideBarV8 .profileImage {
    border-radius: 100%;
}

.sideBarLogo {
    width: 8.58rem;
    margin: 2.4rem 1.5rem 2rem 1.5rem;
}

.profileLetter {
    font-size: 1em;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 50%;
    background-color: var(--sidebar-selected-background-color);
    vertical-align: middle;
    color: #FEFEFE;
    font-weight: 600;
    margin-bottom: 0.5rem;
}