#summary {
  background: white;
  display: inline-block;
  border: 1px solid var(--entity-border-color);
  padding: 20px 20px 20px 25px;
  border-radius: var(--v8-white-box-radius);
  max-width: 70%;
}

.fullScreen{
  width: 100% !important;
  max-width: 100% !important;
}

.closeBtn {
  display: inline-flex;
  border: none;
  background: none;
  padding: 5px;
  cursor: pointer;
  float: right;
}
