
@keyframes glow {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}


.loading  {
  animation: glow 1.5s ease-in-out infinite;
  background: #eee;
  color: transparent;
  cursor: progress;
  display: inline-block;
  height:1em;
  margin: 0;
}

.loadingDark {
    animation: glow 1.5s ease-in-out infinite;
    background: #ddd;
    color: transparent;
    cursor: progress;
    display: inline-block;
    height:1em;
    margin: 0;
}

.pie {
  width: 100%;
  height: 100%;
}

.pie * {
  animation: glow 2s ease-in-out infinite;
}

.loadingSelector {
  animation: glow 1.5s ease-in-out infinite;
  background: #eee;
  color: transparent;
  cursor: progress;
  display: inline-block;
  height:1em;
  margin: 0;
  width: 90%;
  margin-top: 1em;
  margin-left: 7px;
}