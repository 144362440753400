.successHeader {
    background: var(--card-Header) !important;
}

.failedHeader {
    background: var(--as-red-bg) !important;
    color: white;
}

.loadingButton{
    width: 144px;
    height: 44px;
    border-radius: 999999px;
    
}

.loading{
    animation: glow 1.5s ease-in-out infinite;
    background: #eee;
    color: transparent;
    cursor: progress;
}