.attachments {
	width: 100%;
	max-width: 750px;
	b {
		color: var(--ink300);
	}
	p {
		color: var(--ink200);
	}

	span {
		color: var(--ink100);
	}
}

// (better on grey backgrounds)
.darkenedMode {
	b {
		color: var(--black);
	}

	.dropZone {
		border-color: var(--black);
	}
}

.fullWidth {
	width: 100%;
	max-width: none;
}

.dropZone {
	padding: 3.75em 0;
	display: flex;
	width: 100%;
	justify-content: center;
	border: 1px dashed var(--grey400);
	cursor: pointer;
}

.dropZone svg {
	height: 32px;
}

.dropZone > div {
	align-items: center;
}

.attachmentsText {
	margin-left: 10px;
	margin-bottom: 0;
}

.attachedFiles {
	margin: 1rem 0;
}

.attachedFiles > div {
	margin: 0 0.5rem;
	margin-bottom: 0.5rem;
	border-radius: 0.5rem;
	display: inline-block;
	border: 1px solid var(--grey400);
	padding: 0.75rem;
}

.attachedFiles > div > span {
	color: #000;
}

.attachedFiles > div:first-of-type {
	margin-left: 0;
}

.attachedFiles > div:last-of-type {
	margin-right: 0;
}

.removeAttachment {
	margin-left: 0.25rem;
}

.removeAttachment:hover {
	cursor: pointer;
}

.error{
	border: 2px dashed var(--danger-icon-color) !important;
}