.figure {    
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: crosshair;
    background-size: 0%; /* Fix so transparent images dont have the figure display underneath */
}

.figure div {
    position: absolute;
    background: rgba(120,120,120,0.7);
    color: white;
    padding: 5px;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
}

.figure:hover {
    height: 100%;
    background-size: 150%; /* Fix so transparent images dont have the figure display underneath */
}
  
.figure:hover img {
    opacity: 0;
}
  
.figure img {
    display: block;
    width: 100%;
    pointer-events: none;
    object-fit: contain; /* We want to maintain the aspect ratio...  */
}

@media only screen and (max-width: 768px) {
    .figure img {
        max-height: 200px;
        max-width: 100%;
        width: auto;
        height: auto;
        margin: 0 auto;
    }

    /* Hover doesn't work/exist on mobile so remove div */
    .figure div {
        display: none;
    }
}