.subtitle{
	margin-bottom: 2.3rem;
}

.subtitle p{
	margin-top: 0.75rem;
	margin-bottom: 0;
	color: var(--ink200);
	white-space: pre-line;
}

.subheading{
    color: var(--ink300);
    font-family: Outfit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.75rem */
    margin-bottom: 24px;
}

.formikFields{
    padding: 0px !important;

    input{
        max-width: 300px;
    }
    
}

.formikFieldCapital{
    padding: 0px !important;

    input{
        max-width: 272.5px;
    }
    
}

.inputMargin{
	margin-bottom: 14px !important;
}

.nextArrow {
    margin: 0 !important;
}

.linkText{
    color: var(--ink200);
    text-decoration-line: underline;
    cursor: pointer;
}


.seperation{
    margin-top: 50px;
}