.dropzone {
    background-color: rgba(10, 10, 10, 0.03);
    border: 2px dashed #DDD;
    padding:30px 14px;
    text-align: center;
    border-radius: 3px;
    margin: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666666;
}

.dropzoneText {
    text-align: left;
    margin-left: 3rem;
    max-width: 600px;
}

.importTableBody tr td{
    vertical-align: middle;
}

.selectListCell {
    min-width: 175px;
}

.amountCell input {
    min-width: 80px;
    max-width: 110px;
    text-align: right;
}

.refCell input, .payeeCell input {
    max-width: 150px;
}

.bulkEditRowTop {
    background-color: #F2F2F2 !important;
    border-top: 1px solid var(--dark-border-color);
}

.bulkEditRowBottom {
    background-color: #F2F2F2 !important;
    border-bottom: 1px solid var(--dark-border-color);
}

.bulkEditRowTop td, .bulkEditRowBottom td {
    border-bottom: none !important;
    border-top: none !important;
}

.noBottomCellBorder td {
    border-bottom: none !important;
}

.reducedInputPadding input {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}

.darkBorderTop th {
    border-top: 1px solid var(--dark-border-color) !important;
}

.aiBanner {
    display: flex;
}

.aiTitle {
    color: #10acf7; /*$ai: #10acf7 ;*/
    margin-right: 0.5rem;
}

.aiLogo {
    width:65px;
    height:60px;
    margin-right: 1.5rem;
}

.aiSelected {
    border: 1px solid #10acf7;
    border-radius: 4px;
}