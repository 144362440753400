.formikField{
    padding: 0px !important;

    input{
        width: 50%;
    }
    span{
        display: block;
    } 
}

.formikNumberField{
    padding: 0px !important;

    input{
        max-width: 250px !important;
    }
    span{
        display: block;
    } 
}

.formikNumberOnlyField{
    padding: 0px !important;

    input{
        max-width: 275px !important;
    }
    span{
        display: block;
    } 
}

.formikFieldsAuto{
    padding: 0px !important;

    input{
        width: auto;
    }
    
}

.sectionHeading{
    margin-bottom: 20px;

}

.sectionBody {
    padding: 10px 0 20px 30px;
    border-left: 7px solid #E5E5E5;
    background-color: none;
}

.child{
    margin-left: 20px;
    
}

.subheading{
    color: var(--ink300);
    font-family: Outfit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.75rem */
    margin-bottom: 24px;
}

.attachment{
    margin: 30px 0px 0px 0px !important;
}

.summaryContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.summaryContainer  .noEntries {
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 75px;
    text-align: center;
    padding-right: 5px;
}

.errorFeedback {
    font-size: 1rem !important;
    display: block !important; 
}

.title{
    overflow-wrap: break-word;
    max-width: 100%;
}


