.bsSelectList {
    max-width: 200px;
}

.formButtons {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 20px;
}

.warningText {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}

.warningMsg {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--reminder-overdue);
    font-style: italic;
    font-size: 0.8rem;
}