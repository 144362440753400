.documentPage {
  display: flex;
}
.documentPage h1{
    margin: 0;
    padding: 0;
    font-size: var(--font-size-title);
    font-weight: var(--font-weight-title);
}
.companyDeatils h1{
    font-size: var(--font-size-title);
    font-weight: bold;
}
.dropZone{
  border: 3px dashed #eee;
  background-color: #f8f8f8;
  color: #ddd;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 1rem 1rem;
  margin: 14px 8px;
  position: relative;
  cursor: pointer;
}

.noCursor{
  cursor: default !important;
}

.noDocuments{
    width: 100%;
    border: 3px dashed #eee;
    background-color: #f8f8f8;
    color: #ddd;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 1rem;
    margin: 14px 8px;
}