.infoBanner {
  border-radius: 3px;
  text-align: left;
  padding: 18px;
  margin: 30px 0;
  width: 100%;
  max-width: 1220px;
}

.thin {
  padding: 10px;
  margin: 0px;
  margin-bottom: 15px;
}

.info {
  border-left: 5px solid var(--info-border-color);
  color: var(--info-text-color);
  background: var(--info-background-color);
}

.warning {
  border-left: 5px solid var(--warning-border-color);
  color: var(--warning-text-color);
  background: var(--warning-background-color);
}

.danger {
  border-left: 5px solid var(--danger);
  color: var(--danger);
  background: #f2dede;
}

.positive {
  border-left: 5px solid var(--alert-success-border);
  color: var(--alert-success-border);
  background: var(--alert-success-bg);
}

.danger a {
  color: var(--danger);
}

.danger a:hover {
  filter: brightness(50%)
}

.admin {
  border-left: 5px solid var(--admin-only-color);
  background-color: var(--admin-only-alert-bg);
  color: var(--admin-only-color)
}

.noTopMargin {
  margin-top: 0;
}

.noBottomMargin {
  margin-bottom: 0;
}

.whiteBg {
  background-color: (#fff);
}