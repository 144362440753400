.largeColumn{
    max-width: 300px;
    min-width: 300px;
}

.mediumColumn{
    max-width: 200px;
    min-width: 200px;
}

.smallColumn{
    max-width: 150px;
    min-width: 150px;
}

.verySmallColumn{
    max-width: 50px;
    min-width: 50px;
}

.showAddButtonColumn{
    display: block;
}

.showAddButtonRow{
    display: none;
}


.tableScroll{
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-bar-thumb) var(--scroll-bar-track);

    // Chrome
    /* width */
    &::-webkit-scrollbar {
        height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: var(--scroll-bar-track); 
    }
    
    /* Thumb */
    &::-webkit-scrollbar-thumb {
        background: var(--scroll-bar-thumb); 
    }

    /* Thumb on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: var(--scroll-bar-thumb-hover);
    }
}

.selectContainer{
   padding-right: 14px;
}

@media screen and (max-width: 1350px) {
    .showAddButtonColumn{
        display: none;
    }
    
    .showAddButtonRow{
        display: block;
    }
}