.errorHeader{
    font-size: 2.5rem;
    font-weight: 700;
    color: #000;
    margin-bottom: 1rem;
}
.errorContentWrapper{
    border: 1px solid #d8d3d3;
    padding: 1rem;
    text-align: start;
    background-color:var(--light-background-color);
    max-width: 600px;
    width: 100%;
}
.errorContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.errorContentWrapper li{
    font-size: 1rem;
    font-weight: 400;
    color: #807c7c;
    margin-bottom: 1rem;
}