$field-width-medium: 338px;
// $field-width-large: 342px;  // Use full modal width

.modalContainer {
    display: flex;
    text-align: left;
    flex-wrap: wrap;
    padding: 0 0 2rem 0;
    transition: 0.3s;
}

.modalContainerV8 {
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}

.modalContainerV8 {
    display: inline-block;
}

.fieldPair > .editorField {
    display: inline-block;
}

.modalContainerV8 .editorField:first-of-type {
    margin-left: 0;
}

.editorRow {
    display: flex;
    text-align: left;
    flex-wrap: wrap;
}

.editorField {
    height: 100%;
    width: $field-width-medium !important;
    /* flex-grow: 1; */
    margin: 25px 18px 5px 20px;
}

.editorFieldBordered {
    min-height: 68px;
    border-bottom: 1px solid var(--entity-border-color);
}

.editorFieldBordered input {
    margin-bottom: 1rem;
}

.editorFieldHalfMargin {
    margin-right: 5px;
}

.editorField label {
    font-weight: 600;
}

.editorField.extended {
    width: calc(#{$field-width-medium} + 1rem) !important;
}

.editorField > div {
    margin: 0;
}

.editorField.doubleWidth {
    width: calc(#{$field-width-medium * 2} + 37px) !important; /*Double width and 2rem to account for margins of other inputs*/
}

.modalFooter {
    border-top: 1px solid #dee2e6; /*Copied from modal css*/
    margin: 0;
    padding-top: 0.75rem;
}

.placeholderText {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8rem 0;
    color: var(--grey-no-content-text-color);
}

.placeholderText p {
    font-size: 1.4rem;
}

.businessExpenseAlert {
    position: relative;
    // margin: 0 1rem -0.5rem 1rem;
    margin-top: 1rem;
}

// @media (min-width: 1300px) {
//     .editorField {
//         width: $field-width-large;
//     }

//     .editorField.extended {
//         width: calc(#{$field-width-large} + 1rem);
//     }

//     .editorField.doubleWidth {
//         width: calc(#{$field-width-large * 2} + 2rem); /*Double width and 2rem to account for margins of other inputs*/
//     }
// }

.newPropInput {
    margin-bottom: 1rem;
    max-width: 500px;
    padding: 0.5714285714rem !important;
}

.progressBtn {
    margin-top: 1rem;
}

.linkText {
    text-decoration: underline;
    text-underline-offset: 0.15rem;
    color: var(--ink300);
    font-weight: 600;
    margin-top: 2.3rem;
    margin-bottom: 0;
    cursor: pointer;
}

.linkText > svg {
    margin-right: 10px;
}