@font-face {
  font-family: 'Inter';
  src: url('../css/fonts/inter/Inter-Regular.ttf') format("TrueType");
}
@font-face {
  font-family: 'Inter';
  src: url('../css/fonts/inter/Inter-Thin.ttf') format("TrueType");
  font-weight: 100;
}
@font-face {
  font-family: 'Inter';
  src: url('../css/fonts/inter/Inter-ExtraLight.ttf') format("TrueType");
  font-weight: 200;
}
@font-face {
  font-family: 'Inter';
  src: url('../css/fonts/inter/Inter-Light.ttf') format("TrueType");
  font-weight: 300;
}
@font-face {
  font-family: 'Inter';
  src: url('../css/fonts/inter/Inter-Medium.ttf') format("TrueType");
  font-weight: 500;
}
@font-face {
  font-family: 'Inter';
  src: url('../css/fonts/inter/Inter-SemiBold.ttf') format("TrueType");
  font-weight: 600;
}
@font-face {
  font-family: 'Inter';
  src: url('../css/fonts/inter/Inter-Bold.ttf') format("TrueType");
  font-weight: bold;
}

@font-face {
  font-family: 'Outfit';
  src: url('../css/fonts/outfit/Outfit-Regular.ttf') format("TrueType");
}
@font-face {
  font-family: 'Outfit';
  src: url('../css/fonts/outfit/Outfit-Thin.ttf') format("TrueType");
  font-weight: 100;
}
@font-face {
  font-family: 'Outfit';
  src: url('../css/fonts/outfit/Outfit-ExtraLight.ttf') format("TrueType");
  font-weight: 200;
}
@font-face {
  font-family: 'Outfit';
  src: url('../css/fonts/outfit/Outfit-Light.ttf') format("TrueType");
  font-weight: 300;
}
@font-face {
  font-family: 'Outfit';
  src: url('../css/fonts/outfit/Outfit-Medium.ttf') format("TrueType");
  font-weight: 500;
}
@font-face {
  font-family: 'Outfit';
  src: url('../css/fonts/outfit/Outfit-SemiBold.ttf') format("TrueType");
  font-weight: 600;
}
@font-face {
  font-family: 'Outfit';
  src: url('../css/fonts/outfit/Outfit-Bold.ttf') format("TrueType");
  font-weight: bold;
}
@font-face {
  font-family: 'Outfit';
  src: url('../css/fonts/outfit/Outfit-ExtraBold.ttf') format("TrueType");
  font-weight: 800;
}
@font-face {
  font-family: 'Outfit';
  src: url('../css/fonts/outfit/Outfit-Black.ttf') format("TrueType");
  font-weight: 900;
}

$inni-blue: #00394b;
$inni-red: #e53342;
$inni-yellow: #ffc400;
$inni-tiber: #164147;
$inni-bermuda-grey: #758e9a;
$inni-light-blue: #c4d2d8;
$inni-white: #FFF;
$inni-alabaster: #f1efe6;
$inni-blue-lagoon: #006581;
$inni-sky-blue: #46cff5;
$inni-rainforest-green: #007463;

$prov-grey: #C1C6C8;

$primary: $inni-blue; //#0b3c4d;// #1A2F44;
$primary-mid: $inni-tiber; //#0E4A5E;
$primary-light: lighten($inni-tiber, 5%); //#136480;// #264563;
$change-color: var(--button-change-color); //#136d89; // $green-bright;

$grey-1: desaturate(lighten($primary-light,66%),40%); //#DCE7EF;
$grey-2: #B5BDC4;
$grey-3: #8D9499;
$grey-4: #676C70;
$grey-5: #3F4244;
$grey-6: #1A1B1C;
$green-bright: #5BB75B;

$dark-border: $prov-grey;
// Cut everything above
//////@at-root



///@at-root
$font-size-root: 14px;
$font-family-sans-serif:  "Inter", "Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;

$primary: $primary-light;
$secondary: $grey-2;
$success: $green-bright;
$warning: #ffae00;
$black: #0a0a0a;
$white: #fefefe;
$body-color: $black;

$danger: $inni-red;
$success: $inni-rainforest-green;


$border-radius: 0;
$card-border-radius: 5px;
$progress-border-radius: 3px;
/* BOOTSTRAP DEFAULTS: 
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) ;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) ; */

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1300px
) ;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1220px
) ;

/*
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 640px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1400px
);*/

/* TODO:
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
); */
/*
$grid-columns:                16;
$grid-gutter-width:           30px;
$grid-row-columns:            8;*/


// Buttons
$input-btn-padding-y:         0.64rem; //9px;
$input-btn-padding-x:         1.5rem; //21px;

/*
$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;

$input-btn-border-width:      $border-width !default;
*/
$input-btn-focus-width: 0;


@import "~bootstrap/scss/bootstrap";

// Boostrap buttons (e.g. dropdowns) match our custom buttons (from Button.module.css)
.btn {
  border-radius: 25px;
  // margin: 0.25rem 0.5rem 0.25rem 0;
  padding: 14px 21px;
  font-size:  0.95rem;
  font-weight: 400;
  letter-spacing: 0.3px;
  border: none;
  line-height: 1rem;
}

.btn-primary, .btn-primary:disabled {
  background-color: var(--button-primary);
}

.btn-primary:disabled {
  opacity: 0.5;
}

.btn-primary:hover, 
.show > .btn-primary.dropdown-toggle, 
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus {
  background-color: var(--button-primary-hover) !important;
  border: none;
}

.sideBarAccountDropdown {
  .btn-primary:hover, 
  .show > .btn-primary.dropdown-toggle, 
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:focus {
    background: none !important;

    span {
      border-radius: 3px;
      background-color: var(--sidebar-selected-background-color) !important;
    }
  }
}

.btn-secondary, .btn-secondary:hover, .btn-secondary.active {
  color: white !important;
  background-color: var(--button-secondary) !important;
}

.btn-outline-secondary   {   
  background-color: transparent; 
  border: 1px solid var(--button-grey-outline);
  color: var(--secondary);

  :hover {
    border: 1px solid var(--button-grey-outline);
  }
}

.btn-group {
  .btn {
    border-radius: 3px;
  }
}

// TODO: default size for all react buttons should match our own, requires lots of visual testing
// For now we have the size=small class to match our buttons
.btn-sm {
  padding: 7.35px 21px;
}

//Allows us to overlap modals and dim the one behind
//https://stackoverflow.com/a/62870548
@for $i from 1 through 6 {
  $zIndexBackdrop:  #{1000 + (5 * $i)};
  $zIndexContent:  #{1000 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

.custom-control.custom-switch {
  z-index: auto; /*custom-control sets z-index to 1, but this causes issues with react-select dropdowns above switches*/
}

// Make the the large pagination component smaller with a more prominent border, custom css for 'new' button
.pagination-lg {
  margin: 0.5rem;
  
  .page-item .page-link {
    padding: 5px 14px;
    border-width: 2px;
    border-left-width: 1px;
    border-right-width: 1px;
    cursor: pointer;
  }
  
  .page-item.active .page-link {
    color: black;
    background-color: $grey-1;
    border-color: $grey-2 !important;
  }

  &[data-dark-border] {
    .page-item .page-link {
      border-color: $dark-border;
    }
  }
  
  li:first-child .page-link {
    border-left-width: 2px;
  }

  li:last-child .page-link {
    border-right-width: 2px;
  }

  .active .page-link {
    border-color: $primary !important;
  }

  li .page-link[data-pagination|=new] { 
    color: white;
    background: var(--button-primary);
    border-color: var(--button-primary-darkened) !important;
    border-left: none;
    border-right: none;

    // &:hover {
    //   background: darken($change-color, 5%);
    // }
  }
}

input[type="text"]::placeholder {
  font-style: italic;
  color: #AAA;
}

input, select, textarea {
  border-radius: var(--input-field-radius) !important;
}

input.inputWithControls {
  border-radius: 0 !important;
}

input.inputWithControlLeft, .input-group-prepend + input {
  border-radius: 0 var(--input-field-radius) var(--input-field-radius) 0 !important;
}

input.inputWithControlRight {
  border-radius: var(--input-field-radius) 0 0 var(--input-field-radius) !important;
}

.input-group .input-group-prepend .input-group-text {
  border-radius: var(--input-field-radius) 0 0 var(--input-field-radius) !important;
}

.input-group .input-group-append .input-group-text {
  border-radius: 0 var(--input-field-radius) var(--input-field-radius) 0 !important;
}

.input-group label+input {
  border-radius: var(--input-field-radius) 0 0 var(--input-field-radius) !important;
}

div.modal-content {
  border-radius: 10px;
}

h1,h2,h3,h4,h5,h6 {
  font-family: "Outfit", "Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
}

.dropdown-item.active, 
.dropdown-item:active {
  background-color: var(--white-hover);
}

.btn-outline-primary:not(:disabled):not(.disabled):active, 
.btn-outline-primary:not(:disabled):not(.disabled).active, 
.show > .btn-outline-primary.dropdown-toggle {
  background-color: var(--button-primary);
  border-color: var(--button-primary);
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--button-primary);
  border-color: var(--button-primary);
}