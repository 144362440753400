.formikFieldsAuto{
    padding: 0px !important;

    input{
        width: auto;
    }
    
}


.formikFields{
    padding: 0px !important;

    input{
        width: 60%;
    }
    
}

.fieldLabel {
    font-weight: 600;
}

