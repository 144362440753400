@-moz-document url-prefix() {
    .simpleFormikField[type='number'] {
        padding-right: 0; /*Firefox number arrows are bugged with padding, so turn off*/
    }
}

input.simpleFormikField {
    display: inline-block;
    padding: .57rem;
    margin-right: 7px;
}

.simpleFormikField[type='number'] {
    text-align: right;
}

.simpleFormikField[type='checkbox'] {
    display: block;
}