.breadcrumb.noContainer {
	background-color: rgba(0,0,0,0);
	padding: 0;
}

.breadcrumb-item {
	margin-bottom: 0.5rem;

	a,
	span {
		background-color: var(--grey200);
		color: var(--ink200);
		text-decoration: none;
		padding: 4px 8px;
		border-radius: 3px;
		font-weight: 400;
	}
}
