.outerLayout{
    display: flex;
    max-width: 1440px;
    justify-content: center;
    margin-bottom: 84px;

}

.innerLayout{
    display: flex;
    max-width: 820px;
    flex-direction: column;
    width: 100%;

}

.searchBar{
    width:356px;
    margin-top: 43px;
}

.banklayout, .banklayout:global(.card){
    display: flex;
    width: 100%;
    padding: 28px 12px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid var(--steel-200, #DEE2E7);
    background: var(--project-colours-white, #FEFEFE);
}

.selected{
    border: 2px solid var(--steel600) !important;
}

.banklayout {
    cursor: pointer;
  }
  
  .banklayout:hover {
    cursor: hand; 
  }

.bodyWrapper, .bodyWrapper:global(.card-body){
    display: flex;
    padding:0px !important;
    flex-direction:column;
    gap:8px; 
    align-items:center;
}

.banksGrid{
    display: grid;
    gap:11px; 
    margin-top:47px;
    margin-bottom: 64px;
    grid-template-columns: repeat(auto-fill, 196.75px);
}

.noResultsGrid {
    grid-column: 1 / -1;
    margin-top: 93px;
    margin-bottom: 76px;
}

.noResultsGrid > h3{
    text-align: center;
    margin:0
}

.footer{
    display: flex;
    flex-direction: column;
    gap:43px;
    /* max-width:237px; */
}

.Bankfooter{
    display: flex;
    gap:11px;
    margin-top: 40px;

}

.imageWrapper {
    display: flex;
    width: 88px;
    height: 88px;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    gap: 26.667px;
    border-radius: 50px;
    border: 1.5px solid var(--grey-350, #E0DFE4);
}

.betaText{
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 17px;
    background: var(--grey-200, #E0E2E4);
    height: 18px;
    width: 47px; 
}

.H2Title{
    color: var(--ink-300, #353F4A);
    font-family: Outfit;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom:15px;
}

.SectionTitle{
    color: var(--ink-300, #353F4A);
    font-family: Outfit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.75rem */
    margin:0px;
    text-align: center;
}

.normal{
    color: var(--ink-200, #585F67);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    margin:0px;
}

.linkText{
    color: var(--ink-200, #585F67);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
}

.consentMessage p{
    color: var(--ink-200, #585F67);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}

