.outerLayout{
    display: flex;
    max-width: 1440px;
    padding: 10px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.allDone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
}

.allDone img {
    width: 80px;
    margin-bottom: 30px;
}

.allDone p {
    font-size: 24px;
}


.innerLayout{
    display: flex;
    width: 820px;
    padding: 71px 0px 80px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 37px;
    flex-shrink: 0;
}

.stagesLayout{
    display: flex;
    padding: 0px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 22px;
    align-self: stretch;
}


.welcomeText{
    color: var(--ink-300, #353F4A);
    text-align: center;
    font-family: "Outfit", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.75rem */
    width: 639px;
}

.stages{
    display: flex;
    padding: 10px 25px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    box-sizing: border-box;
}
.stagesOuter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding-top: 10px;
    padding-bottom: 10px;
}
.stagesInner{
    display: flex;
    padding-right: 40px;
    align-items: flex-start;
    gap: 25px;
    flex: 1 0 0;
}

.stagesNumber{
    display: flex;
    min-width: 47px;
    min-height: 47px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 999999px;
    background: var(--grey-200, #E0E2E4);
    
}
.stagesNumberText{
    color: var(--ink-300, #353F4A);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.4rem */
    margin:0px
}

.stagesnumberActive{
    background: var(--project-colours-b-300, #00828C);
}
.stagesNumberTextActive{
    color: #FFF;
}
.stagesNumberCompleted{
    background: #F4F3F7;
}

.stageDetails{
    display:flex;
    flex-direction: column;
    gap:5px
}

.stageDetailsOuter{
    display:flex;
    flex-direction: column;
    gap:16px
}

.StagesTitle{
    color: var(--ink-300, #353F4A);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; 
    margin:0px
}

.stagesInfo{
    color: var(--ink-200, #585F67);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin:0px
}
.stagesLinkText{
    color: var(--project-colours-b-300, #00828C);
    font-family: Inter;
    font-size:  0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
}

.Active{
    border-radius: 20px;
    background: rgba(0, 130, 140, 0.08);
}

.completed{
    opacity: 0.6;
}

.protipOuter{
    display: flex;
    padding: 0px 60px;
    border-right: 20px;
    border-left: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    box-sizing: border-box;
}
.protipInner{
    display: flex;
    padding: 28px 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    border-radius: 14px;
    border: 1px solid var(--grey-300, #CACED2);
    background: var(--grey-100, #F3F4F5);
    width: 100%;
}
.proTiptitle{
    color: var(--ink-300, #353F4A);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.4rem */
    margin: 0px;
}

.proTipInfo{
    color: var(--ink-200, #585F67);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
}

.endText{
    width: 658px;
    color: var(--ink-300, #353F4A);
    text-align: center;
    font-family: "Outfit", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.75rem */
}

.button{
    /* width: 164px;
    height: 44px; */
    font-size: 0.875rem;
}

