.assetRead  th, .assetRead  tr{
	width: 180px !important;
}

.assetSelect {
    display: inline-block;   
    vertical-align: middle;  
    border-radius: 3px;
    margin: 0.25rem 0.5rem 0.25rem 0;    
	width: 250px;
}

.buttondisabled{
    margin: 0.25rem 0.5rem 0.25rem 0!important;   
}


.inputStyle label{
	margin: 0 2rem 0 0;
	line-height:40px;
	text-align: left;
	width: 100%;
	white-space: nowrap;
	width: 201.125px !important;
}

.inputStyle div{
	max-width: 100%;
	border-radius: 0 ;
	box-shadow: var(--entity-inset-box-shadow);
	border-color: var(--slate-blue-border-color) !important;
	height: calc(1rem + 1.28rem+2px ) !important;
	min-height:40.91px !important;
	cursor: pointer;
	margin: 0;
	width: 201.125px !important;
}

.inputStyle{
	width: 231.13px !important;
}

.cardHeight{
	height: 90px !important;
}

.formikFieldsAuto, .formikFields{
    padding: 0px !important;    
}

.formikSelectField{
    padding: 0px !important;
	max-width: 300px!important;  
}

.formikFields input{
	max-width: 270px;
}

.formikFields span{
	display: block;
}


.formikFieldsAuto input{
	width: auto;
}

.createFormButton{
    margin-top: 40px;
}


.subheading{
    color: var(--ink300);
    font-family: Outfit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.75rem */
    margin-bottom: 24px;
}

.seperation{
    margin-top: 50px;
}

.subtitle{
	margin-bottom: 2.3rem;
}
.subtitle p{
	margin-top: 0.75rem;
	margin-bottom: 0;
	color: var(--ink200);
	white-space: pre-line;
}

.mandatory label:after {
	content:"*";
	color:red;
	margin-left: 0.2rem;
}

.buttonGroup{
	margin-top: 20px;
}

.kpiGroup{
	margin-top: 40px;
	padding: 0 15px 0 0;
}

.assetTitle{
	font-weight: 600;
	font-size: 1.5rem;
	line-height: 2rem;
}

.rowGroup{
	display: flex;
	flex-direction: row;
	margin-bottom: 40px;
	margin-top: 20px;
	flex-wrap: wrap;
	align-items: stretch;
}

.formikTableInputs{
	min-width: 150px;
}

.switchWidth{
	width: 90px;
}

.originalValueWidth{
	width: 180px;
}

