@import "Property.module.scss";

#propertyHeader {
  background-color: white;
  color: var(--ink300);
  box-shadow: none;
  border: none;
  display: flex;
  flex-direction: column;

  h2 {
    margin: 0;
  }
}

#propertyHeader .image img {
  border-radius: 4px !important;
}

.propertyCard {
    border-radius: var(--v8-card-radius) !important;
    background-color: var(--steel50) !important;
    border: 1px solid var(--grey200) !important;
}

.propertyCardImage {
    border-radius: var(--v8-card-radius) var(--v8-card-radius) 0 0 !important;
}

.propertyCertCardImg {
    border-radius: var(--v8-card-radius) var(--v8-card-radius) 0 0 !important;
}

.cardTitle {
  color: var(--black);
  font-weight: 600;
}

.cardTitle h3 {
  padding: 0;
  margin: 0;
}

.cardRow {
  margin-bottom: 10px;
}

.cardRow h5 {
  color: var(--black);
}