.tabIconContainer {
    width: 100%;
    margin-top: 10px;

    svg {
        margin-right: 10px;
        color: var(--grey-icon-color)
    }    
}

.keyIcon {
    color: var(--tab-color);
    width: 20px;
}

.buttonContainer {
    width: 100%;
    text-align: left;
}

.PeopleCards {
    display: flex;
    gap: 10px;
    padding-bottom: 15px;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-bar-thumb) var(--scroll-bar-track);
    
}

.PeopleCards > div{
    flex: 0 0 auto;
}

.formikFieldsAuto{
    padding: 0px !important;

    input{
        max-width: 300px;
    }

    textarea{
        max-width: 300px;
    }

    span{
        display: block;
    }
    
}

.createFormButton{
    margin-top: 40px;
}