#mobileBottomBar {
  background: white;
  border-top: 5px solid var(--sidebar-mobile-background-color);
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  padding-left: 10%;
  padding-right: 10%;
  z-index: 995;
}

#mobileBottomBar > svg, #mobileBottomBar > label {
  color: var(--sidebar-mobile-background-color);
  font-size: 24px;
  cursor: pointer;
  margin: 0;
  line-height: 24px;
}