.editRow {
    display: flex;
    margin-bottom: 1.5rem;
    max-width: 800px;
    flex-wrap: wrap;
}

.editRow :global(.invalid-feedback) {
    display: block;
}

.editRow label {
    margin: 0 2rem 0 0;
    width: 31.25%;
    line-height:40px;
    text-align: left;
}

.valueWrapper {
    width: 62.5%;
}

.valueWrapperFullWidth {
    width: 100%;
}

.editRow input, 
.editRow textarea {
    padding: .5714285714rem;
}

.input-date .inputGroup, 
.input-number .inputGroup {
    width: 40%;
}

.inputGroup.small {
    width: 20%;
}

.inputGroup.flexAlignCenter {
    align-items: center;
}

.input-currency input,
.input-number input {
    text-align: right;
}

@-moz-document url-prefix() {
    .input-currency input,
    .input-number input {
        padding-right: 0; /*Firefox number arrows are bugged with padding, so turn off*/
    }
} 

.input-switch :global(.custom-switch) {
    padding-top:10px;
}

.input-currency .valueWrapper {
    display: flex;
    
}

.input-currency .currencyValue {
    width: 40%;
}

.input-currency .intervalLabel {
    padding: 0 15px;
    line-height:40px;
    text-align: center;
}


.selectList, :global(.selectList__control) {
    border-radius: 0;
    border-color: var(--slate-blue-border-color);
    height: 40px;
    z-index: 50;
 }

h2.subheading {
    font-size: 18px;
    padding-bottom:0.5rem;
    margin-top:3rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--secondary);
    max-width: 800px; 
    font-weight: normal;
}

.LblTopAlignLeft {
    text-align: left;
}

.switchLblTop label {
    width: 100% !important;
}

.labelTopHlpMsg {
    text-align: left;
}

.labelTopHlpMsg label {
    width: 100%;
}

.fullWidth {
    width: 100%;
}

.popoverHelpIcon {
    display: flex;
    align-items: center;
    position: absolute;
    right: -40px;
    height: 100%;
}

.input-currency .inputSingleRow{
    display: inline-block;
    vertical-align: top;
    width: 25% ;
}

.singleRowIntervalLabel{
    display: inline-block;
    vertical-align: top;
}

/* Fill entire row when less than 768px */
@media screen and (max-width: 768px) {
    .input-date .inputGroup, 
    .input-number .inputGroup,
    .input-currency .currencyValue {
        width: 100%;
    }
}

/* Fill width with smaller screen sizes */
@media screen and (max-width: 400px) {
    .editRow label,
    .valueWrapper {
        width: 100%;
    }
}

.buttonAlign{
    text-align: left;
}