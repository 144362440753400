.nextArrow {
    margin: 0 !important;
}

.formikField{
    padding: 0px !important;

    input{
        width: 50%;
    }
    span{
        display: block;
    }
    
}