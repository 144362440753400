#showBtn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 75px;
    width: 75px;
    background: var(--sidebar-background-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        height: 33px;
        width: 33px;
        margin-left: 2px;
        margin-bottom: 2px;

        path {
            fill: white;
        }
    }
}