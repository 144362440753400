/* Based on VerticalTabs.module.css */


.navBarOuter {
    display: flex;
    flex-direction: row;
    height:100%;
}

.navBar {
    flex-grow: 0;
    flex-shrink: 0;
    width: 300px;
    height:100%;
    background-color: var(--light-background-color);
    border-right: 1px solid var(--light-border-color);
}

.navBarPageOuter {
    flex-grow: 1;
    padding: 1em;
}

.navGroup {
    padding: 0.5em;
    border-bottom: 1px solid #DDD;

    font-weight: bold;
}

.navItem {
    padding: 0.5em;
    padding-left: 2em;
    border-bottom: 1px solid #DDD;
    cursor: pointer;
}

.navItem.selected {
    background-color: #fff;
    border-right: 1px solid #fff;
    width: calc(100% + 1px); /*Tried to use margin-right: -1px but to no avail*/
}