#tenancyNotes .clearSearch {
    background-color: white;
    width: 50px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #999;
}

#tenancyNotes .clearSearch:hover {
    color: var(--text-dark-color);
    background-color: var(--secondary);
}

#tenancyNotes .emptyNotes {
    color: var(--grey-no-content-text-color);
    text-align: center;
    margin: 50px;
}

#tenancyNotes .centerFlexContent {
    display: flex;
    justify-content: center;
    width: 100%;
}

#tenancyNotes .noteIcon {
    font-size: 24px;
    color: var(--grey-icon-color);
    margin-right: 10px;
}

#tenancyNotes .noteTitle {
    display: inline-block;
    color: var(--text-dark-color);
    margin-right: 10px;
}

#tenancyNotes .alignItemsBottom {
    display: flex;
    align-items: flex-end;
}

#tenancyNotes .hideIcon {
    display: inline
}

@media (max-width:900px) {
    #tenancyNotes .hideIcon {
        display: none;
    }
 }
