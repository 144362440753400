.clearSearch {
    background-color: white;
    width: 50px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #999;
    right: 50px;
}

.clearSearchControl {
    width: calc(100% - 50px);
}

.searchControlShadow {
    border-color: var(--slate-blue-border-color);
    display: inline;
}