#brandWrapper.inniaccounts {
    --brand-secondary-color: #440099;  // not used for ia yet, needs updating to something appropriate
    --topbar-background-color: #00394b;
    --topbar-color: #c6cfda;

    --sidebar-background-color: #164147;
    --sidebar-selected-background-color: #1c535a;
    --sidebar-color: #fefefe;
    --sidebar-border-color: #18484f;
    --sidebar-collapse-hover-background-color:  #00394b;
    --sidebar-mobile-icon-fill-color: black;
    --sidebar-mobile-background-color: var(--topbar-background-color);

    --button-primary: #136d89;
    --button-change-color: var(--button-primary);
    --button-primary-darkened: #035d79;
    --button-primary-hover: #11647e; //For bootstrap, primary darkened 5%
    --button-positive-color: #164147;

    --referral-box-background: #c4d2d8;
    --inni-red: #e53342;
}