.weekPickerFormGroup {
	display: flex;
	width: 45%;
	min-width: 200px;
	align-items: center;
	/* margin-top: 20px; */
	margin-bottom: 0px !important;
}

.weekPickerFormGroup input {
	height: 40px !important;
}

.weekPickerFormGroup div {
	color: var(--primary);
	display: flex;
	height: 40px;
	background: #fefefe;
	padding: 5px 15px;
	align-items: center;
	cursor: pointer;
}

.pickerLeft {
	border-radius: var(--input-field-radius) 0 0 var(--input-field-radius);
}

.pickerRight {
	border-radius: 0 var(--input-field-radius) var(--input-field-radius) 0;
}

.formerEmployeeBtn {
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 0px;
}

.formerEmployeeBtn * {
	margin: 10px 0;
}

.expensesInputHolder {
	display: flex;
}

.expensesInputHolder:first-child {
	margin-right: 10px;
}

.createImportBtnHolder {
	display: flex;
} 

.formTableRows td {
	border: 0px !important;
}

.controlHolder {
	display: flex;
	margin-bottom: 10px;
}

.alertBox {
	background-color: #e1f3f6;
	color: #246b75;
	border-radius: 2px;
	border-top: 1px solid #d0edf1;
	border-bottom: 1px solid #bde5ea;
	border-right: 1px solid #bde5ea;
	border-left: 5px solid #1c535a;
	margin-bottom: 10px;
	padding: 1rem 1rem;
}

.formTableRows td {
	border: 0px !important;
}

.qeMobile {
	display: flex;
	flex-direction: column;
	padding-bottom: 5px;
}

.qeMobile .filter {
	width: 100%;
	background: var(--topbar-background-color);
	justify-content: flex-end;
	display: flex;
	padding: 10px;
	z-index: 995;
}

.qeMobile .content {
	width: 100%;
	flex-grow: 1;
	height: calc(100vh - 109px - 45px);
	overflow-y: scroll;
	margin-bottom: -44px;
	padding-bottom: 100px;
}

.qeMobileEntry {
	padding: 2px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--entity-border-color);
	cursor: pointer; /*on mobile so probs not need but*/
}

.popup {
	position: absolute;
	bottom: 0px;
	padding-left: 0px;
	transition: margin 0.5s ease-in-out;
	left: 0;
	width: 100%;
	margin-bottom: -120%;
	background-color: white;
}

.popupActive {
	margin-bottom: 53px;
}

.popup li {
	list-style-type: none;
	padding: 10px;
	background-color: white;
	margin-top: 5px;
	width: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--grey-no-content-text-color);
}

.popup li svg {
	margin-right: 10px;
}

.popup li.close svg {
	margin-right: -10px;
	margin-bottom: 10px;
	position: absolute;
}

.overlay {
	transition: opacity 0.3s ease-in-out;
	background: rgba(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	visibility: hidden;
	opacity: 0;
}

.overlayVisible {
	transition: opacity 0.3s ease-in-out;
	visibility: visible;
	opacity: 1;
}

.filters {
	position: absolute;
	bottom: 0px;
	transition: margin 0.5s ease-in-out;
	background: white;
	width: 100%;
	left: 0;
	padding-top: 10px;
	margin-bottom: -120vh;
	max-height: calc(100vh - 120px);
	overflow-y: scroll;
}

.filterActive {
	margin-bottom: 53px;
}

.alert {
	z-index: 999;
	position: fixed;
	left: 5%;
	bottom: 10px;
	width: 90%;
}

.qeLabel {
	margin-top: 20px;
}

.filtersCheckboxLabel {
	display: flex;
	align-items: center;
}

.filtersCheckboxLabel input {
	margin-right: 10px;
	width: 25px;
	height: 25px;
}

.editorButtons {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
}

.mileageEditRow {
	/* have to use !important to override default edit row */
	background: #fff !important;
	border-top: 0px !important;
	border-bottom: 3px solid var(--light-border-color) !important;
}

.jumpToTop {
	position: fixed;
	top: calc(100vh - 100px);
	background: var(--sidebar-mobile-background-color);
	border-radius: 50%;
	right: 10px;
	cursor: pointer;
	margin-right: -200px;
	transition: all 0.5s ease;
}

.jumpToTop.visible {
	margin-right: 0;
}

.jumpToTop svg {
	color: #ffffff;
}

.editorRow {
	display: flex;
	text-align: left;
	margin-bottom: 1rem;
	/* max-width: 600px; */
}

.editorField {
	height: 100%;
	width: 220px;
	/* flex-grow: 1; */
	margin-right: 2rem;
}

.editorField > div {
	margin: 0;
}

.editorRow > .description > div {
	width: calc(440px + 2rem) !important; /*Double width and 2rem to account for margins of other inputs*/
}

.modalFooter {
	border-top: 1px solid #dee2e6; /*Copied from modal css*/
	margin: 0;
	padding-top: 0.75rem;
}

@media (min-width: 1300px) {
	.editorField {
		width: 280px;
	}

	.editorRow.description > div {
		width: calc(560px + 2rem); /*Double width and 2rem to account for margins of other inputs*/
	}
}
.smallText {
	font-size: 0.8rem;
	cursor: pointer;
	text-decoration: underline;
	text-align: start;
}

.separator{
	margin-top: 40px;
}

.PeopleCards {
    display: flex;
    gap: 10px;
    padding-bottom: 15px;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-bar-thumb) var(--scroll-bar-track);
    
}

.PeopleCards > div{
    flex: 0 0 auto;
}

.header{
	margin-bottom: var(--margin-below-title) !important;
}
.formikFieldsAuto, .formikFields{
    padding: 0px !important;    
}

.formikFields input{
	max-width: 270px;
}

.formikFields span{
	display: block;
}

.formikFieldsAuto input{
	width: auto;
}

.subheading{
    color: var(--ink300);
    font-family: Outfit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.75rem */
    margin-bottom: 24px;
}

.addbutton{
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
}