
.formikField{
    padding: 0px !important;

    input{
        width: 50%;
    }
    span{
        display: block;
    } 
}

.formikFieldsAuto{
    padding: 0px !important;

    input{
        width: auto;
    }
    
}

.subheading{
    // color: var(--ink300);
    // font-family: Outfit;
    // font-size: 1.25rem;
    // font-style: normal;
    // font-weight: 600;
    // line-height: 140%; /* 1.75rem */
    font-weight: 500;
}