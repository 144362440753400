html, body, #root, #brandWrapper {
    height: 100%;
    font-size: $font-size-root;
}
 
.centerFlexContent {
    display: flex;
    justify-content: center;
    width: 100%;
}

.centerFlexContentBoth {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.squareFitImage {
    aspect-ratio: 1 / 1;
    object-fit: cover;
}

.positiveGreenText {
    color: var(--feature-green);
    font-weight: 600;
}
    
.dropdownMenuShort {
    max-height: 500px;
    overflow-y: auto;
}

.linkText {
    // font-size: 0.9rem;
    color: var(--link-blue-color);
    cursor: pointer;
}

.linkText:visited {
    color: var(--link-blue-color);
}

.errorText {
	color: red;
	font-size: 0.84rem;
	font-weight: 400;
}

.defocused {
    opacity: 0.2;
    filter: blur(3px);
    pointer-events: none;
}

button > a, button > a:hover {
    color: #fff;
    text-decoration: none;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
}

body.overlay-open {
    overflow: hidden;
}

.v8CheckBoxContainer {
    border: 1px solid var(--steel200);
    padding: 10px 15px;
    background: white;
    border-radius: 5px;
}
.v8CheckBoxContainer > label {
    cursor: pointer;
}

.blankOverlay {
    background: rgba(0, 0, 0, 0.6);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    justify-content: center;
}

.dropdownOutlineButton {
    display: inline-block;

    button {
        background: transparent !important;
        border: 1px solid var(--steel300) !important;
        padding: 12px 16px !important;
        margin: 0px 15px 15px 0px !important;
    }

    :hover button {
        background: var(--steel100) !important;
    }
}

.adminOnlyBorderRight {
    border-right: 4px solid var(--admin-only-color);
}

.addNewDashedBox {
    border: 2px dashed var(--grey400);
    cursor: pointer;
    padding: 14px;
    color: var(--black);
    font-weight: 500;
}

.addNewDashedBox:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.deleteIcon {
    color: var(--light-grey-icon-color);
    cursor: pointer;
}

.deleteIcon:hover {
    color: var(--danger-icon-color);
}

.standardIcon {
    color: var(--light-grey-icon-color);
    cursor: pointer;
}

.standardIcon:hover {
    color: var(--grey-icon-color);
}

.alert-dismissible .close {
    padding: 0.85rem 1.25rem
}