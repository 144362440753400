.openBankingLayout, .openBankingLayout:global(.card){
    display: flex;
    width: 100%;
    padding: 28px 12px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid var(--steel-200, #DEE2E7);
    background: var(--project-colours-white, #FEFEFE);
}


.openBankingBodyWrapper, .openBankingBodyWrapper:global(.card-body){
    display: flex;
    padding:0px !important;
    flex-direction:column;
    gap:8px; 
    align-items:center;
}


.openBankingImageWrapper {
    display: flex;
    width: 88px;
    height: 88px;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    gap: 26.667px;
    border-radius: 50px;
    background-color:  #eee;
}

.recommended{
    background: var(--steel500); 
    margin: 0;
    color:var(--white);
    padding: 10px;
    border-radius: 7px 7px 0px 0px;
}

.newBankImageWrapper{
    width: 5.5em;
    height: 5.5em;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 266664px;
    background-color:  #eee;
    animation: glow 1.5s ease-in-out infinite;
    color: transparent;
}


.loadingBankAccountCard, .loadingBankAccountCard:global(.card) {
    background-color: white;
    width: 100%;
    border-color: #CACED2;

    small {
        margin-bottom: 8px;
        color: #585F67;
        color: black;
    }

    p, ul, b {
        color: #353F4A;
    }
    border-radius: 17px;
}

.LoadingCardContent {
    padding: 28px 17px;
}


.imageWrapper {
    margin-right: 16px;
    border-radius: 50%;
    width: 5.5em;
    height: 5.5em;
    overflow: hidden;
    padding: 10px;
    
}

.loadingCardHeader, .loadingCardHeader:global(.card-header) {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 14px;
    border-bottom: none;
   
    p, h6 {
        margin-bottom: 0;
        color: #000;
    }

}


.loadingCardBody,.loadingCardBody:global(.card-body) {
    background-color: white;
    padding: 14px 0;
    display: flex;
    column-gap: 0.5em;
    border-bottom: 1px solid #CACED2;
    border-top: 1px solid #CACED2;

    div {
        flex: 1;
        flex-basis: max-content;
        padding: 8px 14px;
        display: flex;
        flex-direction: column;
        background-color: #F2F4F6;
        border-radius: 7px;
    }

}

.loadingCardFooter, .loadingCardFooter:global(.card-footer) {
    background-color: white;
    border-top: none;
    display: flex;
    padding: 14px 0 0;
    column-gap: 2.5em;
    ul {
        padding: 8px 14px;
        flex: 1;
        margin-bottom: 0;
        li {
            margin-left: 25px;
        }
    }
}

.loadingRecommended{
    border-radius: 0px 0px 7px 7px !important;

}

.loadingButton{
    width: 144px;
    height: 44px;
    border-radius: 999999px;
    
}

.loading{
    animation: glow 1.5s ease-in-out infinite;
    background: #eee;
    color: transparent;
    cursor: progress;
}

.pSmall{
    color: var(--ink200);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pBold{
    color: var(--ink-300, #353F4A);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    margin:0px
}

.bankRecommended{
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 7px 7px 0px 0px;
    background: var(--steel-500, #8091A4);
    color: var(--project-colours-white, #FEFEFE)!important;
}

.loadingFooter{
    display: flex;
    gap: 10px;
    margin: 20px 0;
}