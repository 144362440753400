table.dataGrid {
    width: 100%;
    background-color: white;
    border: 1px solid var(--entity-border-color);
    border-radius: var(--entity-border-radius);
    box-shadow: var(--entity-box-shadow);
}

table.dataGrid th {
    background-color:  #F2F2F2;
    color:var(--table-header);
    font-weight: bold;
    border-bottom: 1px solid #DDD;
    padding:0.6em 1em !important;
    text-align: left;
}

table.dataGrid td {
    padding: .6em 1em;
    border-bottom: 1px solid #eee;
}

table.dataGrid tr:nth-child(even) {
    background: var(--data-grid-evenrow)
}

table.dataGrid.hover tr td  {
    cursor: pointer;
}

table.dataGrid.hover > tbody tr:hover td {
    background: var(--steel100)
}

table.dataGrid.formEdit {
    margin-bottom: 1.5rem;
    max-width: 800px;
}

table.dataGrid.formEdit td {
    vertical-align: middle;
}

@media only print
{
    table.dataGrid {
        margin-bottom: 300px;
    }
}

table.dataGrid.matchRow {
    box-shadow: none;
    border: none;
    border-bottom: 2px solid #dee2e6;
    border-top: 2px solid #dee2e6;
    margin-bottom: 0px;
}

/* === V8 === */
table.dataGrid.v8 {
    box-shadow: none;
    border-color: var(--steel200);
}

table.dataGrid.v8 th {
    color: var(--black);
    background-color: var(--steel50);
    font-weight: 600;
    border-color: var(--steel200);
}

table.dataGrid.v8 td {
    border-color: var(--steel200);
}