.Modal {
	margin-left: 232px;
	max-width: 50%;
	border: 0px !important;
	overflow: hidden;
}

@media screen and (max-width: 768px) {
	.Modal {
		margin-left: 0%;
		margin-right: 0%;
		max-width: 100%;
		border: 0px;
		overflow: hidden;
	}
}

.ModalHeader {
	background-color: var(--sidebar-selected-background-color) !important;
	color: white !important;
	overflow: hidden !important;
}
.ModalBody {
	padding: 0rem 1rem 0rem !important;
}
.modalContent {
	border: 0 !important;
}
