/* Filter */

table.filter {
    width:100%;
    border-spacing: 0;
    border: 1px solid #ddd;
    box-shadow: 1px 1px 3px rgba(50,50,50,0.1);
    border-radius: 3px;
    border-bottom: none;
    margin-bottom: 0;
}

table.filter tr {
    display: flex;
}


table.filter td {
    user-select: none;
    background-color: #fff;
    text-align: center;
    color: #999;
    font-weight: 400;
    text-transform: uppercase;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    // line-height: 1em;
    height: 3em;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    // &.month {
    //     width: 150px;
    // }
}

table.filter td div {
    line-height: 1em;
}

table.filter td.selected {
    color: var(--filter-selected);
    border-bottom: 2px solid #2a4969;
    padding-top: 1px;
}

table.filter td.empty {
    color: #ddd;
}

table.filter td.warning {
    color: var(--warning);
}


table.filter select {
    margin-bottom: 0;
    text-align: center;
    border: none;
    font-size: 15px;
    font-weight: 400;
    color: var(--filter-selected);
}

table.filter select:focus {
    outline: 0;
}

table.filter .yearDropDown {
    padding:0;
    display: inline-flex;
    margin:0 1em;
    text-transform: none;
}

table.filter .yearDropDownButton {
    background: transparent !important;
    border:none !important;
    padding:0;
    color: var(--filter-selected) !important;
    display: inline;
}

.dropdownItem {
    line-height: 1.5em;
}

.yearSelectorArrow {
    color: var(--filter-selected);
    cursor: pointer;
    // display: inline-block;
    // position: relative;
    // top: 2px;
}

.yearSelectorArrow.empty {
    color: #ddd;
    cursor: auto;
}

// Prevent toggle button background when opened
:global(.btn-primary:not(:disabled):not(.disabled)).yearDropDownButton {
    background: none !important;
}

:global(.btn-primary:not(:disabled):not(.disabled):focus).yearDropDownButton {
    background: none !important;
}

.dropdownItem {
    color: black !important;
}
