#tenancyHeader {
    background-color: var(--entity-header-background-color);
    border-bottom: 1px solid var(--light-border-color);
    display: flex;
    color:white;
    box-shadow: var(--entity-header-box-shadow);
}

#tenancyHeader h1 {
    font-weight: normal;
    font-size: 25px;
    margin: 10px 0 0 0;
    line-height: 1;
}

#tenancyHeader .image {
    margin: 14px;
    flex-grow: 0;
    flex-shrink: 0;
}

#tenancyHeader .image img {
    border: 1px solid var(--entity-border-color);
    border-radius: var(--entity-border-radius);
    box-shadow: var(--entity-header-image-box-shadow);
    width: 100px;
    height: 100px;
}

#tenancyHeader .content {
    padding: 14px 14px 14px 0;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
}

#tenancyHeader .content .buttons {
     margin-top:auto;
}

#tenancyHeader .address a {
    color:white;
    margin-left: 0.5em
}


.subheading{
    color: var(--ink300);
    font-family: Outfit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.75rem */
    margin-bottom: 24px;
}

.seperation{
    margin-top: 50px;
}

.tenancyperiod{
    display: flex;
    gap: 40px;
    div {
        display: inline-block;
        flex-grow: 0;
    }
}

.formikFieldsDate{
    padding: 0px !important;

    input{
        width: 200px;
    }
    
}

.formikFieldsAuto{
    padding: 0px !important;

    input{
        width: auto;
    }
    
}

.formikField{
    padding: 0px !important;

    input{
        width: 50%;
    }
    span{
        display: block;
    }
    
}

.newPropInput {
    margin-bottom: 1rem;
    max-width: 500px;
    padding: 0.57rem !important;
}

.linkText {
    display: block;
    text-decoration: underline;
    text-underline-offset: 0.15rem;
    color: var(--ink300);
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 0;
    cursor: pointer;
}

.flexDirection{
    flex-direction: column;
    label{
        color: #6c757d !important;
    }
}

.labelName{
    color: #6c757d !important;
    display: block;
    width: 100% !important;
}

.rentReview{
    display: flex;
    gap: 52px;
    width: 62.5%;
    max-width: 800px;
    input{
        padding: 0.57rem;
    }
}

.agentDetails{
    display: flex;
    max-width: 800px;
    flex-direction: column;
}

.rowAgentDetails{
    display: flex;
    gap: 20px;
    align-items: center;
}

.selectList{
    width: 57%;
    border-radius: 0;
    border-color: var(--slate-blue-border-color);
    height: 40px;
    z-index: 50;
}

.continueButton{
    margin-top: 20px;
}

.errorFeedback {
    display: block !important; 
}

