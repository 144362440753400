.assetSaleButton > button {
    width: 100%;
    text-align: left;
    background: transparent;
    color: #212529;
    border: 0px;
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    border-radius: 0px;
}

.assetSaleButton > button:hover {
    background: var(--white-hover);
    color: #16181b;
}


.assetSaleButton > button:focus {
    background: #1c535a !important;
    color: #fefefe !important;
}

.fullViewContainer {
    display: flex;
    flex-wrap: wrap;
}

.alert {
    position: fixed !important;
    right: 10%;
    left: 10%;
    bottom: 0px;
    width: 80%;
    /* border-bottom: 2px solid var(--success-green-color) !important; */
}

.dropDownIcon {
    width: 14px !important;
    height: 14px !important;
}

.altText{
    display: block;
    align-content: center;
    height: 100%;
}