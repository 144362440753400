@import "Filter.module.scss";

table.filter td.selected {
    color: var(--provestor-green);
    border-bottom: 4px solid var(--provestor-green);
    padding-top: 1px;
}

table.filter td.warning {
    color: var(--provestor-green);
}

table.filter .yearDropDownButton {
    font-weight: bold;
    color: black !important;
}
