.container {
    display: flex;
    flex-direction: column;
    /* max-width: 1920px;
    margin: 0 auto; */
}

.header, .body {
    display: flex;
    align-items: flex-start;
    padding: 5rem;
    padding-top: 4rem;
}

.body.withCrumbs {
    padding-top: 0;
}

.outerColumn, .centerColumn {
    flex-grow: 1;
    flex-basis: 0;
}

.outerColumn {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    box-sizing: border-box;
}

.simple > .outerColumn:first-of-type {
    justify-content: flex-start;
}

.centerColumn {
    flex-grow: 4;
    flex-shrink: 0;
    max-width: 720px;
    padding-left: 2.5rem;
    box-sizing: border-box;
}

.header {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 3rem;
    background-color: #FEFEFE;
}

.simple {
    background-color: rgba(0,0,0,0);
    padding: 5rem 5rem 2.5rem 5rem;
    margin-bottom: 0px;
}

.headerContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
}

.headerContent > p {
    margin: 0.3rem 0 0 0;
}

.headerCloseBtn {
    align-items: center;
    text-decoration: underline;
    text-underline-offset: 0.2rem;
}

.breadcrumbTrailContainer, .breadcrumbSpacer {
    height: 5rem;
}

.contentTitle {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.3rem;
}

.contentTitleHeader {
    font-size: 2.3rem !important;
    font-weight: 700 !important;
    border-bottom: none !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    color: var(--ink300);
}

.contentTitleHeaderAdmin {
    text-decoration: underline;
    text-decoration-color: var(--admin-only-color);
    text-decoration-thickness: 2px;
}

.contentSubtitleHeader {
    margin-top: 0.75rem;
    margin-bottom: 0;
    color: var(--ink200);
    white-space: pre-line;
}

.cleanBtn {
    display: flex;
    border: none;
    font-size: inherit;
    padding: 0;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    align-items: center;
}

.headerCloseBtn > img {
    margin-left: 0.5rem;
}

.backBtn {
    padding-top: 0.25rem; /* Align with title */
}

.backBtn.withCrumbs {
    padding-top: 5.4rem;
}

.subTitle {
    font-weight: bold;
    font-size: 1.15rem;
}

.date {
    color: var(--bs-ink100);
    line-height: 1.4rem;
}

.brandLogo {
    width: 10rem;
    margin: 0 1.5rem 2rem 1.5rem;
}

@media only screen and (min-width: 992px) {
    .centerColumn {
        max-width: 960px;
    }
}

@media only screen and (min-width: 1200px) {
    .centerColumn {
        max-width: 1140px;
    }
}

@media only screen and (min-width: 1400px) {
    .centerColumn {
        max-width: 1320px;
    }
}