.loadingStageLayout{
    display: flex;
    padding: 10px 25px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    box-sizing: border-box;
}

.loadingStage{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 690px;
}

.loadingStageInner{
    display: flex;
    padding-right: 40px;
    align-items: flex-start;
    gap: 25px;
    flex: 1 0 0;
}

.loadingStageNumber{
    display: flex;
    min-width: 47px;
    min-height: 47px;
    border-radius: 999999px;
}


.loadingButton{
    width: 144px;
    height: 44px;
    border-radius: 999999px;
    
}

.loadingStageDetails{
    display:flex;
    flex-direction: column;
    gap:15px;
    width:427px;
}

.loading{
    animation: glow 1.5s ease-in-out infinite;
    background: #eee;
    color: transparent;
    cursor: progress;
}