.horizontalTab {
    border: 0px;
    color: var(--var-tab-color);
    font-size: 1.1rem;
    padding: 15px;
    display: inline-block;
    cursor: pointer;

    &.selected {
        border-bottom: 2px solid var(--var-tab-color); 
        color: var(--var-tab-color);
    }

    &.disabled {
        color: var(--grey-no-content-text-color);
    
        span {
            color: var(--grey-no-content-text-color) !important; // !important needed to overwrite default (bc text-muted also has !important)
        }
    }

    &.adminOnly {
        padding-bottom: 16px;
        border-bottom: 1px solid var(--admin-only-color);
    
        &.selected {
            border-bottom: 2px solid var(--admin-only-color);
            padding-bottom: 15px;
        }
    }
}

.horizontalTabContainer {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--light-border-color);
}