/* PROPERTY IMPORT */
.propertyImport {
  color: var(--text-dark-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.propertyImportContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 650px;
}

.propertyImportUnlink {
  font-size: 16px;
  bottom: 5px;
  position: absolute;
  cursor: pointer;
}

/* PROPERTY ENTRIES */
.propertyImportEntry {
  margin: 5px;
  border-radius: 5px;
  border: 2px solid var(--light-border-color);
  padding: 0px;
  color: var(--text-dark-color);
  transition: 0.3s;
  width: 100%;
}


.selPropertyImportEntry {
  background: white;
  box-shadow: var(--entity-box-shadow-elevated);
}

.propertyImportEntry img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.propertyImportEntry .flexIcon {
  flex-basis: 50px;
}

.propertyImportEntry div.selectableRow {
  margin: 0;
  padding: 10px;
  cursor: pointer;
}

.propertyImportEntry div.linkPropertyRow {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 2px solid var(--light-sub-border-color);
  margin: 5px;
  align-items: center;
}

.propertyImportEntry .linkProperty {
  width: 100%;
  max-width: 465px; /* aligns with the divider above in fullscreen */
  margin: 10px;
}

.propertyImportEntry span.divider {
  border-right: 3px solid var(--light-sub-border-color);
}

/* Font awesome */
.propertyImportEntry .faIcon {
  color: var(--disabled-placeholder-color);
  font-size: 46px;
}

.propertyImportEntry .checkIcon {
  margin-left: 5px;
  transition: 0.3s;
}

.propertyImportEntry .checkIconSelected {
  color: var(--success-green-color);
}

.propertyImportEntry .linkIcon {
  color: white;
  background: #F2D16F;  /* Yellow works here but needs global consideration */
  font-size: 20px;
  margin-left: 10px;
  padding: 3px;
  border-radius: 5px;
}
