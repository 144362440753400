@import "Tenancy.module.scss";

#tenancyHeader {
  background-color: white;
  color: var(--black);
  box-shadow: none;
  border: none;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--margin-below-title);
}

#tenancyHeader .image img {
  border-radius: 4px !important;
  box-shadow: none;
}

#tenancyHeader .content{
  padding: 32px;
}

#tenancyHeader .address a {
  color:black;
  margin-left: 0.5em
}

#tenancyHeader .address{
  color: var(--ink300);
  font-family: Outfit;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


