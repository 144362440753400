.dropzone {
    background-color: var(--grey100);
    border: 2px dashed #DDD;
    padding:30px 14px;
    text-align: center;
    border-radius: 3px;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
}

.dropzoneV75 {
    border-radius: var(--v8-entity-border-radius) !important;
}

.dropzone svg {
    color: var(--light-grey-icon-color);
}

.dropzoneText {
    text-align: left;
    margin-left: 3rem;
    max-width: 600px;
}

.noPreview {
    display: flex;
    flex-direction: column;
}

.noPreview img {
    max-height: 20vh;
}


.subtitle{
	margin-bottom: 2.3rem;
}
.subtitle p{
	margin-top: 0.75rem;
	margin-bottom: 0;
	color: var(--ink200);
	white-space: pre-line;
}


.formikFieldsDate{
    padding: 0px !important;

    input{
        width: 200px;
    }
    
}


.formikNumberField{
    padding: 0px !important;

    input{
        width: 200px;
    }
    span{
        display: block;
    }
    
}

.formikTextField{
    padding: 0px !important;

    input{
        width: 50%;
    }
    span{
        display: block;
    }
    
}

.dateRange{
    display: flex;
    gap: 40px;
    div {
        display: inline-block;
        flex-grow: 0;
    }
}

.CategorylinkText {
    text-decoration: underline;
    text-underline-offset: 0.15rem;
    color: var(--ink300);
    font-weight: 600;
    margin-top: 2.3rem;
    margin-bottom: 0;
    cursor: pointer;
}

.CategorylinkText > svg {
    margin-right: 10px;
}

.refundCheckBox{
    display: inline-flex;
    gap: 10px;
    padding: 0px 10px;
    align-items: center;
    background-color: white;
}

.refundCheckBox input{
    display: inline-block;
    width: auto;
}
.refundCheckBox p{
    display: inline-block;
    margin: 0px;
}

.newInput {
    margin-bottom: 1rem;
    max-width: 500px;
    padding: 0.5714285714rem !important;
}