#dropdown .button {
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}

#dropdown .adminOnly::after {
  content: "";
  background-color: var(--admin-only-color);
  border-radius: 50%;
  border: none;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 8px;
}

/* small is default, i.e. overriding the regular button size */
#dropdown .small {
  padding: 5px 8px;
}

#dropdown .hamburger {
  font-size: 18px !important;
  background: none !important;
  color: var(--light-grey-icon-color);
}

#dropdown .hamburger:hover {
  color: black;
}

#dropdown .hamburger::after {
  display: none;
}
