.fileIcon span {
    position: absolute;
    top: 2px;
    left: 10px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 1px 1px 0 rgba(0,0,0,.5);
}

.fileIcon {
    display: block;
    float: left;
    position: relative;
    /* top: 10px; */
    /* left: 10px; */
    margin-bottom: 20px;
}