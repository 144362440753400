.displayCard {
    box-shadow: 0 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius:3px !important;
    height: 120px;
    padding:1px 6px;
    width:100%;
}
.cardTitle {
    border-bottom: 1.3px solid #c5bcbc;
    padding: 0px;
    margin: 0 0px;
    font-size: 0.9rem;
}
.cardText {
    text-align: right;
    display: block;
    line-height: 1;
    font-weight: 200;
    color:var(--display-card-text);
}
.cardText span {
    font-size: 36px;
    text-align: right;
    display: block;
    line-height: 1;
    font-weight: 200;
    color:var(--display-card-text);
}
.cardBanner{
    text-align: right;
    color:var(--displaycard-child-text);
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 12px;
    float: right;
    margin-right:4px;
    font-size: 12px;
}

@media screen and (max-width: 768px) {
    .displayCard {
        width: 32.6%;
    }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .displayCard {
        max-width: 100%;
        padding:0;
    }
}