@import "DefaultLayout.module.scss";
$page-margin: 2.4rem;

#header {
    border-bottom: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: var(--container-width);
    color: var(--ink300);
    padding: 0;

    h1 {
        font-weight: 500;
        height: var(--layout-title-height);
        margin: 0 0 var(--margin-below-title) 0;
    }

    h2 {
        font-weight: 500;
        height: var(--layout-title-height);
        font-size: 1.6rem;
        margin: 0 0 var(--margin-below-sub-title) 0;
    }
}

#header.fullScreen {
    max-width: none;
}

#containerWrapper.greyBackground  {
    background-color: white;
}

#defaultLayout {
    margin: $page-margin;
    padding: 0;
}

#container.noSpacing {
    padding: 0;
    margin: 0;
}

#toolbar {
    padding: 0;
}

#container {
    padding: 0;
}

#emptyLayout {
    margin: $page-margin;
    padding: 0;
}
