#brandWrapper.provestor {
    --brand-secondary-color: #440099;
    --topbar-background-color: #071D49;
    --topbar-color: #c6cfda;

    --sidebar-background-color: #00828c;
    --sidebar-selected-background-color: #009aa6;
    --sidebar-color: #fefefe;
    --sidebar-border-color: #008b96;
    --sidebar-collapse-hover-background-color:  #071D49;
    --sidebar-mobile-icon-fill-color: #4CA8A2;
    --sidebar-mobile-background-color: #5CB8B2;

    --button-primary: #00828C;
    --button-change-color: var(--button-primary);
    --button-primary-darkened: #00727C;
    --button-primary-hover: #00767f; //For bootstrap, primary darkened 5%
    --button-positive-color: #00828c;

    /* e.g property header */
    --entity-header-background-color: #595478;
    --entity-header-box-shadow: inset 0 -3px 3px 3px rgba(50, 50, 50, 0.2);
    --entity-header-image-box-shadow: 0 3px 3px 3px rgba(50, 50, 50, 0.2);

    --referral-box-background: #c1c6c8;
}