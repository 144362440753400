.plainText {
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding: 10px 0;

    .iconHolder {
        margin-right: 20px;
        svg {
            font-size: 2.5em;
        }
    }

    h4 {
        text-align: left;
        vertical-align: top;
        // width: 90%;
        display: inline-block;
        font-size: 1.45rem;
        line-height: 1.9rem;

        .highlighted {
            color: var(--text-feature-color-blue);
            font-weight: 600;

            &.selectedEntry:not(.redStruckThrough) {
                font-weight: 700;
                &:last-of-type {
                    margin-right: 15px;
                }
            }
        }

        .refund {
            color: #389F42;
            font-weight: 700;
        }

        .redStruckThrough {
            text-decoration: line-through;
            text-decoration-color: red;
            text-decoration-thickness: 2px;
            font-weight: 500;
            color: black;
        }

        svg {
            margin-left: 20px;
        }
    }

    h6 {
        display: inline-block;
        margin: 0;
        position: relative;
        top: -2px;
    }
        
    .reducedSizeText {
        display: none;
    }

    @media (max-width: 991px) {
        .fullSizeText {
            display: none;
        }

        .reducedSizeText {
            display: flex;
        }
    }

    @media (max-width: 1300px) {
        .showTags {
            display: none;
        }
    }
}

.plainText.centered {
    justify-content: center;
}

.inputGroup {
    max-width: 80%;
    margin: 0 auto;
}

.inputPrefix, .inputSuffix, .autocomplete {
    border: 2px solid var(--entity-border-color-darker) !important;
    background-color: white !important;

    // &.focused {
    //     border: 2px solid var(--entity-border-color-darker) !important;
    // }
}

.inputPrefix {    
    border-right: none !important;
    border-radius: 5px 0px 0px 0px !important;
    cursor: text;
    padding: 10px !important;
}

.inputSuffix {
    border-left: 2px solid var(--entity-border-color-darker) !important; 
    border-radius: 0px 5px 0px 0px !important;
    cursor: pointer;

    &:hover {
        background-color: #F2F2F2 !important;
    }

    svg {
        transition: transform 0.3s ease;
    }
}

.autocomplete {
    flex: 1 1 auto;
    padding: 0px !important;
    font-family: Consolas, 'Liberation Mono', Courier, monospace;
    border-left: 0px !important;
    border-right: 0px !important;
    margin-left: -8px;
    z-index: 999;
    color: #9c9898 !important;
    cursor: text;
    user-select: none;
}

.formControl {
    border: 2px solid var(--entity-border-color-darker) !important;
    border-left: 0px !important;
    border-right: 0px !important;
    outline: 0px;
    transition: 0s !important;
    padding-left: 3px !important;
    flex: 0 0 auto !important;
    width: auto !important;
    padding-right: 0px !important;
    font-family: Consolas, 'Liberation Mono', Courier, monospace;
}

@media (max-width: 991px) {
    input.formControl {
        max-width: 75%;
        text-overflow: ellipsis;
    }
}

.formControl::placeholder {
    font-style: italic;
    color: #C0C0C0 !important;
}

.enterPrompt {
    border: 1px solid var(--entity-border-color-darker);
    padding: 2px;
    border-radius: 5px;
    font-size: 0.8em;
    margin-left: 10px;

    svg {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.categorySelectorContainerOuter {
    background: var(--dark-background-color);
    height: 138px;
    position: relative;
    border-bottom: 2px solid var(--entity-border-color);
}

.categorySelectorContainerOuter.noSearchOuter {
    display: flex;
    align-items: center;
    height: 100px;
}

.categorySelectorContainerInner {
    position: absolute;
    z-index: 100;
    bottom: 10px;
    width: 100%;
}

.categorySelectorContainerInner.noSearchInner {
    position: static;
}

.categorySelectorContainerInner.noSearchInner h4 {
    margin-bottom: 0;
}

.categorySelector {
    position: absolute;
    background-color: white;
    left: calc(10%);
    right: calc(10%);
    overflow: hidden;
    
    border: 2px solid var(--entity-border-color-darker);
    border-top: 0;
    border-radius: 0px 0px 5px 5px;

    max-height: 0px;
    opacity: 0;
    transition: max-height 0.4s ease-out, opacity 1s linear;

    &.show {
        max-height: 1000px;
        border: 2px solid var(--entity-border-color-darker);
        border-top: 0px;
        box-shadow: var(--entity-box-shadow-focused);
        opacity: 1;
        transition: max-height 0.4s ease-in-out, opacity 0.2s linear;
    }    

    .top {
        display: flex;
        
        @media (max-height: 1010px) {
            max-height: 230px;
        }

        > div {
            width: 25%;
            padding: 5px;

            &:not(:last-of-type) {
                border-right: 2px solid var(--entity-border-color-darker);
            }

            // We grow the last one to take up the remaining space...
            &:last-of-type {
                flex-grow: 1;
            }

            h5 {
                text-align: left;
                color: var(--text-feature-color-blue);
                font-size: 1.1em;
                padding: 4px 0 0 10px;
                font-weight: 600;
            }

            .categorySelectorItem {
                text-align: left;
                font-size: 1em;
                line-height: 1em;

                padding: 10px 10px;
                border-radius: 2px;

                margin-bottom: 2px;

                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;

                transition: all 0.25s ease;

                .arrow svg {
                    height: 1em;
                    display: none;
                }

                &:hover {
                    background-color: var(--select-hover);
                }

                // Selected is more important to highlight correct
                // &.highlightCorrect {
                //     background-color: #d4eed1;
                    
                //     &:hover {
                //         background-color: darken(#d4eed1, 5%);
                //     }
                // }

                &.highlightFound {
                    background-color: #F5F5F5;
                    
                    &:hover {
                        background-color: var(--select-hover);
                    }
                }

                &.selected {
                    background-color: var(--select-hover);

                    &:hover {
                        background-color: var(--select-hover);
                    }
                }

                .taxFlag {
                    color: red;
                    margin-right: 7px;
                }

                .searchStar {
                    color: var(--select-selected);
                    margin-right: 7px;
                }

                span {
                    width: 90%;
                    display: inline-block;
                }

                @media (max-width: 991px) {
                    span {
                        white-space: nowrap; 
                        overflow: hidden;
                        text-overflow: ellipsis; 
                    }
                }
            }
        }

        @media (max-width: 991px) {
            .hideSmall {
                display: none;
            }
        }
    }
}

.bottom {
    display: flex;
    flex-wrap: nowrap;
    border-top: 2px solid var(--entity-border-color-darker);
    padding: 10px;
    
    .helpText { 
        padding: 10px;
        flex-grow: 1;

        display: flex;
        align-items: flex-start;
        align-self: flex-start;
        text-align: left;

        background-color: var(--grey100);
        border-radius: 5px;
        line-height: 1.2em;

        min-height: 68px;

        svg {
            margin-right: 10px;
            color: var(--text-feature-color-blue);
            align-self: flex-start;
        }
    }

    .helpTextBrowser {
        @extend .helpText;
        border-radius: 10px;
        height: 120px;
        overflow: auto;
    }

    .controls {
        padding-left: 10px;
        display: flex;
        flex-direction: column;

        label {
            width: 250px;
            display: flex;
            align-items: center;
            cursor: pointer;

            input {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }

        }
    }

    @media (max-width: 991px) {
        .controls {
            margin-top: 10px;
        }

        flex-wrap: wrap;
    }
}

.categorySelectorBrowser {
    position: relative;
    left: 0;
    border-radius: 10px;
    border: 2px solid var(--entity-border-color-darker) !important;
    box-shadow: none !important;
}

.blockButton {
    margin-right: 0px;
    width: 210px;
}

.searchCount {
    margin: 0 5px;
    padding: 3px;
    color: white;
    background: var(--select-selected);
    width: 24px;
    text-align: center;
    border-radius: 3px;
    font-size: 10px;
}

.businessExpenseCheckContainer {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
}

.businessExpenseCheck {
    display: flex;
    margin-bottom: 0.1rem;
}

.businessExpenseCheckLabel {
    cursor: default !important;
    display: inline !important;
    margin-left: 0.25rem;
    margin-bottom: 0 !important;
    font-weight: 500;
    width: auto !important;
}

.searchInput {
    border-radius: 0 !important;
}

//V8 selector styling:

.v8SearchInput > .inputPrefix, 
.v8SearchInput > .inputSuffix, 
.v8SearchInput > .autocomplete,
.v8SearchInput > .formControl {
    border-color: var(--grey300) !important;
    border-width: 1px !important;
    border-radius: 0 !important;
}

.v8SearchInput {
    margin: 0;
}

.catSearchResults {
    left: 0;
    right: 20%;
}

.catSearchResults, .show.catSearchResults {
    border-color: var(--grey300);
    border-width: 1px;
    box-shadow: none;
    border-radius: 0;
    overflow-y: auto;
    z-index: 99;
}

.show.catSearchResults {
    max-height: 750px;
}

.catSearchResultsTitle {
    color: var(--ink200);
    margin: 1rem 0 0 1rem;
}

.catSearchResult {
    display: flex;
    padding-bottom: 1rem;
    margin: 1rem 1rem 0 1rem;
    border-bottom: 1px solid var(--grey200);
    align-items: center;
    justify-content: space-between;
}

.catSearchResult:last-of-type {
    border-bottom: none;
}

.catSearchResult > div:first-of-type {
    flex-grow: 1;
    margin-right: 2rem;
    max-width: 850px;
}

.catSearchResult > button {
    margin: 0;
    white-space: nowrap;
}

.catSearchResult > div > p {
    font-weight: 600;
    font-size: 1.15rem;
}

.catSearchResult > div > small {
    font-size: 1rem;
    display: block;
}

.catSearchResult > div > span {
    color: var(--ink100);
    font-size: 1rem;
}

.catSearchResult p:last-of-type {
    margin-bottom: 0;
}

.highlightSelect {
    border: 2px solid #999 !important
}

.existingTransaction {
    background-color: var(--v8-unmatched-row-color);
    padding-top: 1px;
}

.categoryTypes{
    display: flex;
    //gap: 20px;
    padding: 0px 20px;
    overflow: auto;

    b{
        text-transform: capitalize;
        text-align: start !important;
    }
}

.categoryItems{
    display: flex;
    flex-direction: column;
    max-height: 400px;
    flex-wrap: wrap;
    column-gap: 15px;
    text-align: start;
    margin-top: 10px;
    margin-right: 30px;
}

.searchStar {
	color: var(--as-amber-bg);
	margin-right: 6px;
}

.categoryTypeHeader{
    display: flex;
    padding: 10px 0px;
    border-bottom:1px solid var(--grey200);

}

.startCategoryborderBottom{
    border-bottom:1px solid var(--grey200);
    margin-bottom: 10px;
}

.backgroundCursor{    
    cursor: pointer;
    padding: 5px;
    margin: 0px
}

.backgroundCursor:hover{    
    background-color: #F2F2F2 !important; ;
}