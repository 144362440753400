.arthurLink {
    text-decoration: none !important;
    background: white;
    cursor: pointer;
    width: 100%;
    max-width: 390px;
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 40px auto;
    box-shadow: var(--entity-box-shadow-elevated)
}

.arthurLink img {
    height: 40px;
    width: 40px;
    margin-right: 25px;
}

.arthurLink span {
    font-weight: 600;
    color: var(--link-blue-color);
    border-bottom: 2px dotted #0082D055;
    text-decoration: none;
}

.arthurLink:hover span {
    border-bottom: 2px solid #0082D099;
}

@media (max-width: 450px) {
    .arthurLink span {
        border-bottom: none;
    }

    .arthurLink:hover span {
        border-bottom: none;
    }
 }