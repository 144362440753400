$contact-card-width: 300px;
$card-margin: 10px;

.contactCardContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#help .contactCard {
  min-width: 200px;
  width: 100%;
  max-width: $contact-card-width;
  margin: $card-margin;
  box-shadow: var(--entity-box-shadow);
}

#help .contactDetails {
  width: 100%;
  max-width: (2 * $contact-card-width) + (2 * $card-margin);
  text-align: center;
  box-shadow: var(--entity-box-shadow);
  margin: $card-margin;
}

#help .icon {
  color: var(--grey-icon-color);
  font-size: 32px;
  margin-right: 20px;
}

#help span {
  font-size: 22px;
  vertical-align: bottom;
}

#help .infoCircle {
  color: var(--link-blue-color);
  margin-left: 10px;
  font-size: 20px;
  vertical-align: bottom;
}

#help .title {
  font-size: 24px;
  margin: 0;
}


// Media queries
#help .hideSmall {
  display: inline-block;
}

#help .showSmall {
  display: none;
}

@media (max-width: 625px) {
  #help .hideSmall {
    display: none;
  }
  
  #help .showSmall {
    display: block;
  }

  #help .title {
    font-size: 20px;
  }

  #help .contactCard {
    max-width: none;
    margin: 0;
    margin-bottom: 20px;
  }
}