$invoice-created: #00828C;
$invoice-payment-due:#ef4747;
$invoice-paid-full:#5BB75B;
$invoice-paid-part: #E67E22;
$invoice-received-full: #5BB75B;
$invoice-received-part:#E67E22;
$invoice-vaton-return:#00828C;
$invoice-rti-complete: #5BB75B;
$invoice-rti-failed: #DD0000;
$invoice-pending: #E67E22;
$invoice-paye-on-return: #00828C;

// .timeline {
//   font-family: "Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif ;
// }

.timelineLine{
	border-bottom: 1px solid #eee;
	height: 1px;
	width:100%
}
ul.timeline {
	list-style-type: none;
	position: relative;
}
.timeline li{
	border-bottom: 1px solid #eee;
}

ul.timeline:before {
	content: " ";
	background: #d4d9df;
	display: inline-block;
	position: absolute;
	left: -10px;
	width: 1.2px;
	height: 100%;
	z-index: 400;
}
.timeline p.title {
	font-size: 14px;
	margin: 0 2px;
	color: black !important;
}

.timeline p {
	font-size: 13px;
	margin: 0 8px;
	color:var(--timeline-description-color)!important;
	margin-bottom: 1em;
}

ul.timeline > li {
	padding-left: 0px;
    color: black !important;
}

ul.timeline {
	padding-inline-start: 8px;
}

ul.timeline > li:before {
	content: " ";
	background: $invoice-payment-due;
	display: inline-block;
	position: absolute;
	border-radius: 50%;

	left: -19px;
	width: 20px;
	height: 20px;
	z-index: 400;
}

ul > li.paid-full:before,
ul > li.details-completed:before{
	background: $invoice-paid-full
}

ul > li.created:before {
	background: $invoice-created
}

ul > li.payment-due:before,
ul > li.emailed:before,
ul > li.included-on-vat-return:before {
	background: $invoice-payment-due
}

ul > li.paid-part:before,
ul > li.file-uploaded:before {
	background: $invoice-paid-part
}

ul > li.received-full:before {
	background: $invoice-received-full
}

ul > li.received-part:before {
	background: $invoice-received-part
}

ul > li.vat-on-return:before,
ul > li.paye-on-return:before {
	background: $invoice-vaton-return
}

ul > li.rti-complete:before {
	background: $invoice-rti-complete
}

ul > li.rti-failed:before {
	background: $invoice-rti-failed
}

ul > li.pending:before {
	background: $invoice-pending
}
