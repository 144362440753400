/* V6 source: https://innidev.visualstudio.com/inni/_git/inni?path=%2FWebsite%2FassetsV6%2Fsrc%2Fstylesheets%2F_buttons.scss&version=GBmaster&_a=contents 
    Hence all the explicit values
*/

.button {
    display: inline-block;
    text-align: center;
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: none;
    transition: all 0.25s ease-out;
    transition: visibility 0s;
    vertical-align: middle;
    border-radius: 25px;
    padding: 14px 21px;
    margin: 0.25rem 0.5rem 0.25rem 0;
    font-size:  0.95rem;
    font-weight: 600;
    letter-spacing: 0.3px;
    color:white;
    border: none;
}

.jumbo {
    height: 50px;
    border-radius: 50px;
    font-size: 1.15rem;
}

.marginTop{
    margin-top: 30px;
}

.textBtn {
    border: none;
    background-color: rgba(0,0,0,0) !important;
    text-decoration: underline;
    color: var(--link-blue-color);
    padding: 0;
}

.textBtn:hover {
    cursor: pointer;
}

.btnSpan {
    margin-right: 0.5rem;
}

span > .button {
    margin: 0px;
}

.button.mobile {
    width: 100%;
    margin: 0.25rem;
}

.button.small {
    padding: 0.5rem 0.6rem;
    white-space: nowrap;
}

.button.thin {
    padding: 9.5px 13px 7px 20px;
}

.button.iconOnly {
    white-space: nowrap;
    min-width: 2.6rem;
    max-height: 36.31px;
    padding: 9.333px;
    margin: 0;
}
.button.small.iconOnly {
    padding: 6.333px;
    min-height: 31.66px;
    min-width: 2.262rem;
}

.button.pointerEventsNone {
    pointer-events: none;
}
.button.small.rowWithErrorBtn { /*This to to help us align the buttons to inputs that have an error*/
    margin-top: 0.25rem;
}

/* fa-layers the class used to create overlapping icons */
.button svg, .button :global(.fa-layers) {
    margin-right: 0.5em;
}

/* Fallback value is bad but modal portals don't have access to our custom vars */
/* Needs further investigation - DD */
/* Not ideal, but this can by surrounding the modal content w/ a brand wrapper - JW  */
/*!important isn't great but these styles get overidden in dropdown buttons if the variant is specified*/
.button.change { background-color: var(--button-primary) !important; }
.button.positive { background-color: var(--button-primary) !important; }
.button.primary { background-color: var(--button-primary) !important; }
.button.success { background-color: var(--button-primary) !important; }
.button.recordPayment { background-color: var(--button-primary) !important; }

.button.change:hover,
.button.positive:hover,
.button.primary:hover,
.button.success:hover,
.button.recordPayment:hover { background-color: var(--button-primary-hover) !important; }

.button.secondary { background-color: var(--button-secondary) !important; color: #fff }
.button.warning { background-color: var(--button-secondary) !important; color: #fff }
.button.info  { background-color: var(--button-secondary) !important }
.button.dark  { background-color: var(--button-secondary) !important }

.button.secondary:hover,
.button.warning:hover,
.button.info:hover,
.button.dark:hover { background-color: var(--button-secondary-hover) !important }

.button.danger  {   background-color: var(--button-danger) !important;}
.button.danger:hover { background-color: var(--button-danger-hover) !important }

.button.light { background-color: var(--button-other) !important; color: black; border: 1px solid var(--grey300) }
.button.light:hover { border: 1px solid var(--grey400) }

.button.paymentInactive { 
    background-color: transparent !important; 
    border: 1px solid var(--button-grey-outline);
    color: var(--black) !important;
}
.button.change.outline   {   
    background-color: transparent !important; 
    border: 1px solid var(--button-grey-outline);
    color: var(--button-primary);
}
.button.positive.outline   {   
    background-color: transparent !important; 
    border: 1px solid var(--button-grey-outline);
    color: var(--button-positive-color);
}
.button.primary.outline   {   
    background-color: transparent !important; 
    border: 1px solid var(--button-grey-outline);
    color: var(--primary);
}
.button.secondary.outline   {   
    background-color: transparent !important; 
    border: 1px solid var(--button-grey-outline);
    color: var(--secondary);
}
.button.success.outline   {   
    background-color: transparent !important; 
    border: 1px solid var(--button-grey-outline);
    color: var(--success);
}
.button.danger.outline   {   
    background-color: transparent !important; 
    border: 1px solid var(--button-grey-outline);
    color: var(--danger);
}
.button.warning.outline   {   
    background-color: transparent !important; 
    border: 1px solid var(--button-grey-outline);
    color: var(--warning);
}
.button.info.outline   {   
    background-color: transparent !important; 
    border: 1px solid var(--button-grey-outline);
    color: var(--info);
}
.button.dark.outline   {   
    background-color: transparent !important; 
    border: 1px solid var(--button-dark-grey-outline);
    color: var(--dark);
}
.button.light.outline   {
    background-color: transparent !important; 
    border: 1px solid var(--button-grey-outline);
    color: var(--light);
}

.button.paymentInactive:hover,
.button.change.outline:hover,
.button.positive.outline:hover,
.button.primary.outline:hover,
.button.secondary.outline:hover,
.button.success.outline:hover,
.button.danger.outline:hover,
.button.warning.outline:hover,
.button.info.outline:hover,
.button.dark.outline:hover,
.button.light.outline:hover {
    border: 1px solid var(--button-grey-outline-hover);
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.disabled {
    opacity: 0.5;
    cursor: default;
}

/* .adminOnly, .adminOnly:hover {
    border-right: 4px solid var(--admin-only-color) !important;
} */
.adminOnly::after {
  content: "";
  background-color: var(--admin-only-color);
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

/* Standard admin color does not accompany the danger (red) button well.
    Making it darker looks like an extension of the border.
    Orange is a good trade off because it's strong enough to border a white background */

/* .adminOnlyDanger, .adminOnlyDanger:hover {
    border-right: 4px solid var(--admin-only-danger-color);
} */
.adminOnlyDanger::after {
  content: "";
  background-color: var(--admin-only-danger-color);
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

.tableBtn {
    font-size:  0.85rem;
    padding: 5px 10px;
    min-width: 50px;
}

.tableBtnMd {
    padding: 7px 10px;
}

.headerBtn {
    margin: 0 0 0 0.5rem;
    position: relative;
    top: -5px;
}

.headerMessageBtn {
    margin-left: 0.5rem;
}