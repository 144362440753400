.overlay {
    display: block;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--steel100);
    overflow-x: hidden;
    overflow-y: auto;
}