.optionSelector {
	ul {
        padding: 0;
		list-style-type: none;
		li {
			padding: 8px 12px;
			border-radius: 4px;
			border: 1px solid var(--primary);
			margin-bottom: 16px;
            display: flex;
            color: var(--ink300);
            max-width: max-content;
            cursor: pointer;
			span {
				text-transform: uppercase;
				color: var(--primary);
				background-color: var(--white);
				border: 1px solid var(--primary);
				border-radius: 4px;
				width: 26px;
				height: 24px;
				font-weight: 700;
				margin-right: 10px;
				margin-bottom: 0;
				display: flex;
                justify-content: center;
                align-items: center;
			}

            p {
                margin-bottom: 0;
            }

            .active {
                color: #fff;
                background-color: var(--primary);
                border: 1px solid var(--primary);
            }
		}
	}
}


.or {
    font-size: 0.75em;
    font-weight: 600;
    color: var(--ink200);
    border-radius: 2px;
    border: 1px solid var(--grey200);
    padding: 4px 8px;
    margin-bottom: 16px;
    display: inline-block;
    margin-left: 12px;
}

.disabled {
    opacity: 0.5;
    cursor: default !important;
}