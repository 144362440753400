.shim {
    position: fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    backdrop-filter: blur(2px);
    z-index: 1000;
    background: rgba(10, 10, 10, 0.2);
}

.outer {
    margin:100px auto 0 auto;
    width: 500px;
    box-shadow: 0 14px 30px rgba(50, 50, 50, 0.2);
    color:var(--primary);
}

.dropzone {
    background-color: rgba(10, 10, 10, 0.03);
    border: 2px dashed #DDD;
    padding:30px 14px;
    text-align: center;
    border-radius: 3px;
    margin-bottom: 24px;
}

.meta {
    color:#aaa;
    font-size: 13px;
    margin-bottom: 1em;
}

.uploadIcon {
    font-size: 30px;
    color: var(--success);
    margin-bottom: 10px;
}

.outer .cancelButton {
    margin: 0 auto;
    width: 50%;
    display: block;
    border-radius: 3px;
}

.progressOuter {
    margin: 14px auto 0 auto;
    width: 50%;
    display: block;
    border-radius: 3px;
}