.invoiceTimeLine {
	border: 1px solid var(--entity-border-color) ;
	box-shadow: 1px 1px 3px rgb(50 50 50 / 10%);
	border-radius: var(--entity-border-radius) !important;
	background-color: var(--timeline-background-color) !important;
}
.invoiceTable {
	padding: 15px !important;
}
.invoiceTableV8 {
	margin-top: 50px;
	background-color: white;
	border-radius: var(--v8-white-box-radius);
	padding: 20px;
	border: 1px solid var(--entity-border-color);
}
.invoiceTable table {
	border-collapse: collapse;
	margin: 0;
	padding: 0;
	width: 100%;
	table-layout: auto;
	font-size: medium;
}
@media screen and (max-width: 1800px) {
	.invoiceTable table {
		display: block;
		width: 100%;
	    overflow-x: auto;
		table-layout: fixed;
		font-size:larger !important;
	}
}
.invoiceTable tbody tr:last-child {
	border-bottom: 0.1rem solid var(--entity-border-color);
}
.invoiceTable tbody tr {
	border-bottom: 0.5px solid var(--entity-border-color);
}
.invoiceTable tbody tr:first-child {
	border-top: 0.1rem solid var(--entity-border-color);
}
.invoiceTable tfoot tr {
	border: none;
}
.invoiceTable tfoot td {
	white-space: nowrap;
}
.invoiceTable table th,
.invoiceTable table td {
	padding-top:0.5rem;
	padding-bottom:0.5rem;
	padding-right:0.5rem;
	padding-left:0.5rem;
	text-align: center;
}
.invoiceTable table th {
	font-size: 15px;
	font-weight: 400!important;
	text-transform: uppercase;
	white-space: nowrap;
}

.invoiceTable.lettingStatement table th {
	text-transform: none;
}

.inputStyle input, .inputStyle textarea , .inputStyle span{
	padding: .57rem;
	height: calc(1rem + 1.28rem + 2px);
}
.invoiceTable tr:nth-child(even) td {
	background-color: rgb(248, 248, 248);
}
.invoiceTable > table > th,
.invoiceTable > table > td {
	vertical-align: middle !important;
}
/* .font {
	font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif !important;
} */
.invoiceHeaderFont {
	font-size: 34px;
	text-transform: uppercase;
	color:var(--card-title-color);
}

.invoiceSubHeaderFont {
	font-size: 22px;
	color:var(--card-title-color);
	margin-bottom: 1rem;
	max-width: 500px;
	font-weight: 300;
}
.invoiceSubHeaderFont.lettingStatement {
	max-width: none;
}

.invoiceHeaderFont.lettingStatement {
	text-transform: none;
}

.Emailpdf .modal-body {
	padding: 1rem;
}
.Emailpdf span,
.Emailpdf input {
	border: none !important;
	border-bottom: 1px solid var(--entity-border-color) !important;
}
.Emailpdf .formgroup {
	padding: 0 !important;
	margin: 0 !important;
}
.Emailpf form {
	border: 1px solid var(--entity-border-color) !important;
}
.Emailpdf textarea {
	border-top: none !important;
}

.invoiceLayout {
	border-radius: var(--entity-border-radius) !important;
	max-width: 1200px;
}

.optionalText {
	font-size: var(--font-small);
	margin-left: 5px;
	font-style: italic;
}

@media (min-width: 1024px) {
	.invoiceLayout {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.invoiceLayout {
		margin-bottom: 0.5rem !important;
		margin-top: 0.5rem !important;
		border-radius: none;
	}
}

.lettingStatementLayout {
	box-shadow: var(--entity-box-shadow);
	border-radius: var(--entity-border-radius) !important;
	padding: 10px;
	max-width: 1600px;
}

.lettingStatementLayoutV75 {
	border-radius: var(--v8-entity-border-radius) !important;
	padding: 10px;
	max-width: 1600px;
  background-color: var(--steel50) !important;
}

@media (min-width: 1024px) {
	.lettingStatementLayout {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.lettingStatementLayout {
		margin-bottom: 0.5rem !important;
		margin-top: 0.5rem !important;
		border-radius: none;
	}
}

@media (min-width: 1300px) {
	.invoiceList > .container,
	.invoiceList > .container-sm,
	.invoiceList > .container-md,
	.invoiceList > .container-lg,
	.invoiceList > .container-xl {
		max-width: 100%;
	}
}
.invoiceReadWrapper {
	max-width: 1220px;
}

.SelectFilter {
	margin-right: 5px;
	outline-color: none;
	width: 180px;
}

.SelectFilter :global(.SelectFilter__control) {
	border-color: var(--slate-blue-border-color);
	padding: 1.5px;
	cursor: pointer;
}

.SelectFilter :global(.SelectFilter__menu) {
	max-width: 220px;
	border: 1px solid var(--entity-border-color) !important;
}
@media screen and (max-width: 768px) {
	.SelectFilter :global(.SelectFilter__menu) {
		max-width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.SelectFilter {
		width: 100%;
		margin: 2px 0;
	}
	@media screen and (min-width: 768px) and (max-width: 900px) {
		.SelectFilter {
			min-width: 140px;
			padding-right: 4px !important;
			margin: 0px;
		}
	}
	.SelectFilter :global(.SelectFilter__control) {
		border-color: var(--slate-blue-border-color);
		padding: 1.5px;
	}
}
.SelectFilter :global(.SelectFilter__control--is-focused) {
	border-color: var(--sidebar-selected-background-color) !important;
}
.InvoicePopup {
	border-top: 1px solid var(--entity-border-color) !important;
}
.invoiceRead {
	display: grid;
	grid-auto-flow: column;
	grid-template-rows: repeat(2, auto);
	grid-auto-columns: minmax(10%, auto);
	justify-content: space-between;
	margin-bottom: 1rem;
}
.invoiceRead small{
	font-size: medium !important;
}
.invoiceRead label{
	font-weight: 400 !important;
	color:var(--text-light-color);
}
.quoteRead small{
	font-size: medium !important;
}
.quoteRead {
	display: grid; 
	grid-auto-flow: column;
	grid-template-rows: repeat(2, auto);
	grid-auto-columns: minmax(10%, auto);
	justify-content: left;
	grid-gap: 15%;
	margin-bottom: 3rem;
}
.invoiceEdit {
	display: grid;
	grid-auto-flow: column;
	grid-template-rows: repeat(2, auto);
	grid-auto-columns: minmax(10%, auto);
	justify-content: left;
	grid-gap: 0.5rem;
}
.invoiceEdit label{
	font-weight: 400 !important;
	color:var(--text-light-color);
}
.invoiceEdit div, .invoiceEdit input,.invoiceEdit > input, .invoiceEdit textarea{
	font-weight: 400 !important;
	font-size: medium!important;
}
@media screen and (max-width: 768px) {
	.invoiceEdit {
		display: flex;
		flex-direction: column;
	}
}
@media screen and (max-width: 768px) {
	.invoiceRead {
		display: grid;
		flex-direction: column;
		grid-template-columns: repeat(2, auto);
	}
}
@media screen and (max-width: 520px) {
	.invoiceRead {
		display: flex;
		flex-direction: column;
	}
}
/* uses negative margins and padding to extend the background in both directions. */
.displayCardWrapper {
	overflow-x:hidden;
	background-color: var(--light);
	margin: 0 -9999rem;
	/* add back negative margin value */
	padding: 1rem 9999rem;
}
.invoiceTableHeader{
	width: 100%;
}
.alert {
    position: fixed !important;
    right: 10%;
    left: 10%;
    bottom: 0px;
    width: 80%;
    /* border-bottom: 2px solid var(--success-green-color) !important; */
}
.agentStatementRead small{
	font-size: medium !important;
}
.agentStatementRead {
        display: grid;
        grid-auto-flow: column;       
        grid-auto-columns: minmax(10%, auto);
		justify-content: space-between;
        margin-bottom: 1rem;
}
.agentStatementRead label{
    font-weight: 400 !important;
    color:var(--text-light-color);
}
@media screen and (max-width: 520px) {
	.agentStatementRead {
		display: flex;
		flex-direction: column;
	}
}

.helpIcon svg {
	color: var(--grey-icon-color);
}

.searchStar {
	color: var(--as-amber-bg);
	margin-right: 6px;
}
