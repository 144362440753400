#expandedView h2 {
    height: var(--layout-title-height);
    color: var(--ink300);
}

#expandedView h5 span {
    cursor: pointer;
}

#expandedView .backArrow {
    color: var(--button-primary);
    margin-right: 10px;
}

#expandedView .toolBar {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    max-width: var(--container-width);
    height: var(--layout-title-height);
    margin: 0 0 var(--margin-below-title) 0;
}

#expandedView .badge {
    color: var(--light-grey-icon-color);
    border: 1px solid var(--light-grey-icon-color);
    margin: auto 0 auto 14px;
}
