#slate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 80px;
  margin: 0;
  background: var(--light-background-color);
}

#slate img {
  width: 125px;
  margin: 30px;
}

#slate .text {
  text-align: left;
  max-width: 600px;
  color: #888;
  height: 125px;
}

.largeMargin {
  margin-top: 50px !important;
  width: 100%;
}

#slate h5 {
  color: #666;
}

@media (max-width: 800px) {
  #slate {
    flex-direction: column;
    padding: 40px;
  }

  #slate img {
    width: 100px;
    margin: 15px;
  }

  #slate .text {
    height: auto;
  }
}

.v8Styling h5 {
  color: var(--ink300) !important;
  font-size: 1.4em !important;
}

.v8Styling .text {
  color: var(--ink300) !important;
}